import { AccionModel } from "./accion.model";

export class AuditoriaModel {
    Id: number;
    Usuario: string;
    AccionId: number;
    Fecha: Date;
    Accion: AccionModel;
    ApartadoMenu: ApartadoModel;
    Info: string;
    ApartadoId: number; // Este campo lo rellena automáticamente el servicio


    //Agregados
    AccionNombre: string;
    UsuarioNombre: string;
    Hora: Date;

    constructor(usuario: string, accionId: number) {
        this.Usuario = usuario;
        this.AccionId = accionId;
    }
}

export class ApartadoModel {
    Id: number;
    AccionId: number;
    TextoId: string;
}
