import { CerraduraService } from 'src/app/services/cerraduras/cerradura.service';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { jqxDateTimeInputComponent } from 'jqwidgets-ng/jqxdatetimeinput';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { AppComponent } from 'src/app/app.component';
import { MainComponent } from 'src/app/components/main/main.component';
import { AlarmaCerraduraModel } from 'src/app/services/cerraduras/models/alarmaCerradura';
import { ElementoModel } from 'src/app/services/elements/models/elem.model';
import { HistoricoEnvioIdentiiicadorModel } from 'src/app/services/historicoEnviosIdentificador/models/historicoEnviosIdentificador.model';
import { DateUtils } from 'src/app/utils/date-utils';
import { JqWidgets } from 'src/app/utils/jqWidgets';
import { NumberUtils } from 'src/app/utils/number-utils';
import { Utils } from 'src/app/utils/utils';
import { environment } from 'src/environments/environment';
import { PeriodoSelectComponent } from 'src/app/components/periodo-select/periodo-select.component';
import { DateIdentificacionModel } from 'src/app/services/cerraduras/models/dateIdentificacion';
import { CerraduraModel } from 'src/app/services/cerraduras/models/cerradura.model';
import { IdentificacionCerraduraModel } from 'src/app/services/cerraduras/models/identificacionCerradura';
import { ListadoCerraduraComponent } from '../listado-cerradura.component';
import { HeaderComponent } from 'src/app/components/header/header.component';
import * as xlsx from 'xlsx';

@Component({
  selector: 'app-jqx-sub-tabs',
  templateUrl: './jqx-sub-tabs.component.html',
  styleUrls: ['./jqx-sub-tabs.component.css']
})
export class JqxSubTabsComponent implements OnInit {
  private componentRef = null;
  public theme = environment.tema;
  public langGrid = JqWidgets.getLocalization('es');

  @ViewChild('grid') grid: jqxGridComponent;
  @ViewChild('gridAlarmas') myGrid: jqxGridComponent;
  @ViewChild('myGridEnvios') myGridEnvios: jqxGridComponent;
  @ViewChild('header') header: HeaderComponent;
  public cerradura: CerraduraModel = new CerraduraModel();
  columns = [];


  dataAdapter: any;


  alarmas: AlarmaCerraduraModel[] = [];
  historicosEnviosIdentificador: HistoricoEnvioIdentiiicadorModel[] = [];

  identificacion: IdentificacionCerraduraModel = new IdentificacionCerraduraModel;
  identificaciones: IdentificacionCerraduraModel[] = [];
  elementos: ElementoModel[] = [];
  sourceAlarmas: any;
  dataAdapteAlarmas: any;
  columnsAlarmas = [];
  columnsEnvios = [];
  sourceEnvios: any;
  dataAdapterEnvios: any;
  public static _this: JqxSubTabsComponent;
  constructor(private cerraduraService: CerraduraService) {
    JqxSubTabsComponent._this = this;
  }

  ngOnInit(): void {
    this.initIdentificaciones();
    this.getAlarmasByCerraduras();
    this.initEnvios();
  }

  loadDataTabs() {
    this.aceptarIdentificaciones();
    this.aceptarAlarmas();
    this.aceptarEnvios();
  }

  renderRow(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any
  ): string {
    if (value) {
      let date = new Date(value);
      return '<div class="jqx-grid-cell-left-align" style="margin-top: 4px; text-align: left">' + DateUtils.formatDateTimeShort(date, true) + '</div>';
    }
  }

  renderPermiso(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any
  ): string {
    if (value == 0) {
      return `<div style="margin-left: 4px; margin-top: 4px;  text-align: left;"" onmouseover="this.style.backgroundColor='gray'; this.style.color='white'; this.style.position='fixed';"onmouseout="this.style.backgroundColor=''; this.style.color=''; this.style.position='';">  ${AppComponent.translate('Tarjeta_nfc')}</div>`;
    } else if (value == 1) {

      return `<div style="margin-left: 4px; margin-top: 4px;  text-align: left;"" onmouseover="this.style.backgroundColor='gray'; this.style.color='white'; this.style.position='fixed';"onmouseout="this.style.backgroundColor=''; this.style.color=''; this.style.position='';"> Bluetooth</div>`;
    }
  }

  renderTarjeta(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any
  ): string {
    if (value) {
      return `<div style="margin-top: 4px;  text-align: center"><span onmouseover="this.style.backgroundColor='gray'; this.style.color='white';" onmouseout="this.style.backgroundColor=''; this.style.color='';">  ${AppComponent.translate('Master')}</span></div>`;
    }
    else {
      return `<div style="margin-top: 4px;  text-align: center"><span onmouseover="this.style.backgroundColor='gray'; this.style.color='white';" onmouseout="this.style.backgroundColor=''; this.style.color='';">  ${AppComponent.translate('Blanca')}</span></div>`;
    }
  }

  rendexTextGeneric(row: number, columnfield: string, value: any,
    defaulthtml: string, columnproperties: any, rowdata: any): string {

    if (typeof value === 'string') {
      return `<div style="margin-left: 4px; margin-top: 5px;  text-align: left;"" onmouseover="this.style.backgroundColor='gray'; this.style.color='white'; this.style.position='fixed';"onmouseout="this.style.backgroundColor=''; this.style.color=''; this.style.position='';">${value}</div>`;

    } else if (typeof value === 'number') {
      // para los campos altura libre, ancho y largo los muestro con dos decimales
      if (columnfield === AppComponent.translate('Temperatura_grados')) {
        value = value / 10;
        if (value === 0 || value) {
          value = NumberUtils.format(value, 2)
        }
      } else if (columnfield === AppComponent.translate('Voltaje_vatios')) {
        if (value === 0 || value) {
          value = value / 100;
          value = NumberUtils.format(value, 2)
        } else {
          value = NumberUtils.format(value, 2);
        }
      }
      return `<div style="margin-right: 4px; margin-top: 4px;  text-align: right;"><span onmouseover="this.style.backgroundColor='gray'; this.style.color='white';" onmouseout="this.style.backgroundColor=''; this.style.color='';"> ${value}</span></div>`;
    }
  }


  renderCheck(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any
  ): string {
    if (value) {
      return (
        `
        <div style="position: absolute; top: 50%; left: 50%; margin-top: -9px; margin-left: -12px; overflow: visible; cursor: auto;"
        id="jqxWidgete18472e433a4" tabindex="0" class="jqx-widget jqx-widget-material jqx-checkbox jqx-checkbox-material" checked="true">
          <div class="jqx-checkbox-default jqx-checkbox-default-material jqx-fill-state-normal jqx-fill-state-normal-material jqx-rc-all jqx-rc-all-material ripple" style="filter: grayscale(1)">
            <div style="width: 16px; height: 16px;">
              <span style="width: 16px; height: 16px;" class="jqx-checkbox-check-checked jqx-checkbox-check-checked-material"></span>
            </div>
            <span style="height: 18px; width: 18px; top: -1px; left: -1px;"></span>
          </div>
          <div style="clear: both;"></div>
        </div>
      `)
    } else {
      return (
        `
        <div style="position: absolute; top: 50%; left: 50%; margin-top: -9px; margin-left: -12px; overflow: visible; cursor: auto;"
        id="jqxWidgetd0791853769a" tabindex="0" class="jqx-widget jqx-widget-material jqx-checkbox jqx-checkbox-material">
          <div class="jqx-checkbox-default jqx-checkbox-default-material jqx-fill-state-normal jqx-fill-state-normal-material jqx-rc-all jqx-rc-all-material ripple" style="filter: grayscale(1)">
            <div style="width: 16px; height: 16px;">
              <span style="width: 16px; height: 16px;"></span>
            </div>
            <span style="height: 18px; width: 18px; top: -1px; left: -1px;"></span>
          </div>
          <div style="clear: both;"></div>
        </div>
      `)
    }
  }

  numberrenderer(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any
  ): string {
    if (columnfield == 'bateriaPorcentaje') {
      return '<div style="margin-right: 4px; margin-top: 5px; text-align: right">' +
        NumberUtils.format(value, 0) +
        '%</div>';
    } else if (columnfield === 'temperatura') {
      return (
        '<div style="margin-right: 4px; margin-top: 5px; text-align: right">' +
        NumberUtils.format(value, 0) +
        'º</div>'
      );
    } else {
      return '<div style="margin-right: 4px; margin-top: 5px; text-align: right">' + NumberUtils.format(value, 0) + '</div>';
    }
  }

  numberrendererDecimales(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any
  ): string {
    return (
      '<div style="margin-right: 4px; margin-top: 5px; text-align: right;">' +
      NumberUtils.format(value, 2) +
      '</div>'
    );
  }

  renderText(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any
  ): string {
    if (rowdata.idElemento == 0 || rowdata.idElemento == null) {
      return '';
    } else if (rowdata.nombreElemento == null || rowdata.nsCerradura == null) {
      return '';
    } else {
      return '<div style="margin-right: 4px; margin-top: 5px;">' + rowdata.nombreElemento + '</div>';
    }
  }

  renderIdentificacion(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any
  ): string {
    if (value == 0) {
      return '<div  style="margin-left: 4px; margin-top: 4px">NFC</div>';
    } else if (value == 1) {
      return '<div  style="margin-left: 4px; margin-top: 4px">Bluetooth</div>';
    }
  }

  renderCiudadano(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any
  ): string {
    if (rowdata.nombreCiudadano == null) {
      return '';
    } else {
      return '<div style="margin-left: 4px; margin-top: 4px;">' + rowdata.nombreCiudadano + '</div>';
    }
  }

  renderNsTarjeta(row: number, columnfield: string, value: any, defaulthtml: string, columnproperties: any, rowdata: any): string {
    if (value) {
      return `<div style="margin-right: 4px; margin-top: 4px; text-align: right;"><span onmouseover="this.style.backgroundColor='gray'; this.style.color='white';" onmouseout="this.style.backgroundColor=''; this.style.color='';"> ${NumberUtils.format(value, 0)}</span></div>`;
    }
  }

  cellClass = (row: number, columnfield: any, value: any): string => {
    if (value) {
      return 'cellTooltip';
    }
    return '';
  }

  inputDate: jqxDateTimeInputComponent;
  inputTime: jqxDateTimeInputComponent;
  public columnmenuopeningAlarmas(menu?: any, datafield?: any, height?: number | string): boolean | void {
    if (menu.length === 1) {
      const divElement: HTMLElement = menu[0];
      const dateTimeInputs = divElement.querySelectorAll('.jqx-datetimeinput');

      if (dateTimeInputs && dateTimeInputs.length > 0) {
        dateTimeInputs.forEach((input: HTMLElement, index: number) => {
          const elementRef = new ElementRef(input);
          const jqxDateTimeInput = new jqxDateTimeInputComponent(elementRef);

          if (index === 0) {
            // Instancia para la hora
            this.inputDate = jqwidgets.createInstance('#' + jqxDateTimeInput.elementRef.nativeElement.id, 'jqxDateTimeInput', { width: '100%', height: 25, formatString: 'dd/MM/yyyy HH:mm:ss' });
            this.inputDate.setOptions({ showCalendarButton: true, showTimeButton: true });
          }

          if (index === 1) {
            // Instancia para la hora
            this.inputTime = jqwidgets.createInstance('#' + jqxDateTimeInput.elementRef.nativeElement.id, 'jqxDateTimeInput', { width: '100%', height: 25, formatString: 'dd/MM/yyyy HH:mm:ss' });
            this.inputTime.setOptions({ showCalendarButton: true, showTimeButton: true });
          }

        });
      }
    }
  }

  groupsrenderer(text?: string, group?: any, expanded?: boolean, data?: any): string {
    // El cierre del div se hace en el return del texto a mostrar
    let showText = `
      <div style="top: 50%; margin-top: -8px; position: relative; margin-left: 4px">
        <b>`+ data?.groupcolumn?.datafield + `: </b>
      `;
    if (
      data?.groupcolumn?.datafield === AppComponent.translate('Aporte_residuo') ||
      data?.groupcolumn?.datafield === AppComponent.translate('Apertura_tapa') ||
      data?.groupcolumn?.datafield === AppComponent.translate('No_cerro_tapa')
    ) {
      if (group === true) {
        showText += AppComponent.translate('SI');
        if (data?.subGroups?.length == 0) {
          showText += '(' + data?.subItems?.length + ')';
        } else {
          showText += '(' + data?.subGroups?.length + ')';
        }
      } else if (group === false) {
        showText += AppComponent.translate('NO');
        if (data?.subGroups?.length == 0) {
          showText += '(' + data?.subItems?.length + ')';
        } else {
          showText += '(' + data?.subGroups?.length + ')';
        }
      }
      return showText + "</div>";
    } else if (data?.groupcolumn?.datafield === AppComponent.translate('Tipo_identificacion')) {
      if (group === 1) {
        showText += 'Bluetooth';
        if (data?.subGroups?.length == 0) {
          showText += '(' + data?.subItems?.length + ')';
        } else {
          showText += '(' + data?.subGroups?.length + ')';
        }
      } else if (group === 0) {
        showText += AppComponent.translate('Tarjeta_nfc');
        if (data?.subGroups?.length == 0) {
          showText += '(' + data?.subItems?.length + ')';
        } else {
          showText += '(' + data?.subGroups?.length + ')';
        }
      }
      return showText + "</div>";
    } else if (data?.groupcolumn?.datafield === AppComponent.translate('Tipo_permiso')) {
      if (group) {
        showText += AppComponent.translate('Master');
        if (data?.subGroups?.length == 0) {
          showText += '(' + data?.subItems?.length + ')';
        } else {
          showText += '(' + data?.subGroups?.length + ')';
        }
      } else {
        showText += AppComponent.translate('Blanca');
        if (data?.subGroups?.length == 0) {
          showText += '(' + data?.subItems?.length + ')';
        } else {
          showText += '(' + data?.subGroups?.length + ')';
        }
      }
      return showText + "</div>";
    }
  }

  renderDate(row: number, columnfield: string, value: any,
    defaulthtml: string, columnproperties: any, rowdata: any): string {
    return `<div style=" margin-top: 4px;  text-align: center;"><span onmouseover="this.style.backgroundColor='gray'; this.style.color='white';" onmouseout="this.style.backgroundColor=''; this.style.color='';"> ${value}</span></div>`;
  }

  updatefilterconditions = (type: string, defaultconditions: any): string[] => {
    return Utils.updatefilterconditions(type, defaultconditions);
  };

  public filter(cellValue?: any, rowData?: any, dataField?: string, filterGroup?: any, defaultFilterResult?: boolean): any {
    let filterColumns = [
      this.translate('Ns_movisat'),
      this.translate('Ciudadano'),
      this.translate('Tipo_identificacion'),
      this.translate('Tipo_permiso'),
      this.translate('Nombre_elemento'),
      this.translate('Marca'),
      this.translate('Modelo'),
      this.translate('Matricula'),
      this.translate('Direccion'),
      this.translate('Municipio'),
      this.translate('Poblacion')
    ]

    return Utils.filterRow(cellValue, dataField, filterGroup, defaultFilterResult, filterColumns);
  }

  public filterAlarmas(cellValue?: any, rowData?: any, dataField?: string, filterGroup?: any, defaultFilterResult?: boolean): any {
    let filterColumns = [
      AppComponent.translate('Ns_movisat'),
      AppComponent.translate('Alarma'),
      AppComponent.translate('Nombre_elemento'),
      AppComponent.translate('Marca'),
      AppComponent.translate('Modelo'),
      AppComponent.translate('Matricula'),
      AppComponent.translate('Observaciones'),
      AppComponent.translate('Direccion'),
      AppComponent.translate('Municipio'),
      AppComponent.translate('Poblacion')
    ]

    return Utils.filterRow(cellValue, dataField, filterGroup, defaultFilterResult, filterColumns);
  }

  onfilter(event) {
    // Itero a través de todos los filtros aplicados
    for (let filterObj of event.args.filters) {
      let filter1;
      let column = filterObj.datafield;
      let filter = filterObj.filter.getfilters();

      if (column === this.translate('Tipo_identificacion')) {   // 0 BT, 1 nfc,
        let filter1;
        filter.forEach(element => {
          let filtergroup = new jqx.filter();
          let filter_operator = element.operator;
          let filtercondition = element.condition;
          let filtervalue;

          if (element.value === this.translate('Tarjeta_nfc') && filtercondition !== 'NOT_EQUAL') {
            filtervalue = 0;
          } else if (element.value === 'Bluetooth' && filtercondition !== 'NOT_EQUAL') {
            filtervalue = 1;
          }

          if (filtervalue != undefined) {
            filter1 = filtergroup.createfilter('numericfilter', filtervalue, filtercondition);
            filtergroup.addfilter(filter_operator, filter1);
            this.grid.addfilter(column, filtergroup);
            this.grid.applyfilters();

            filter1 = filtergroup.createfilter('stringfilter', element.value, filtercondition);
            filtergroup.addfilter(filter_operator, filter1);
            this.grid.addfilter(column, filtergroup);
          } else {

          }
        });
      } else if (column === this.translate('Tipo_permiso')) {
        filter.forEach(element => {
          let filtergroup = new jqx.filter();
          let filter_operator = element.operator;
          let filtercondition = element.condition;
          let filtervalue;

          if (element.value === this.translate('Master') && filtercondition !== 'NOT_EQUAL') {
            filtervalue = true;
          } else if (element.value === this.translate('Blanca') && filtercondition !== 'NOT_EQUAL') {
            filtervalue = false;
          }

          if (filtervalue != undefined) {
            filter1 = filtergroup.createfilter('booleanfilter', filtervalue, filtercondition);
            filtergroup.addfilter(filter_operator, filter1);
            this.grid.addfilter(column, filtergroup);
            this.grid.applyfilters();

            filter1 = filtergroup.createfilter('stringfilter', element.value, filtercondition);
            filtergroup.addfilter(filter_operator, filter1);
            this.grid.addfilter(column, filtergroup);
          }
        });
      } else if (
        column === AppComponent.translate('Aporte_residuo') ||
        column === AppComponent.translate('Apertura_tapa') ||
        column === AppComponent.translate('No_cerro_tapa')
      ) {
        filter.forEach(element => {
          let filtergroup = new jqx.filter();
          let filter_operator = element.operator;
          let filtercondition = element.condition;
          let filtervalue;

          if (element.value === this.translate('SI') && filtercondition !== 'NOT_EQUAL') {
            filtervalue = true;
          } else if (element.value === this.translate('NO') && filtercondition !== 'NOT_EQUAL') {
            filtervalue = false;
          }

          if (filtervalue != undefined) {
            filter1 = filtergroup.createfilter('booleanfilter', filtervalue, filtercondition);
            filtergroup.addfilter(filter_operator, filter1);
            this.grid.addfilter(column, filtergroup);
            this.grid.applyfilters();

            filter1 = filtergroup.createfilter('stringfilter', element.value, filtercondition);
            filtergroup.addfilter(filter_operator, filter1);
            this.grid.addfilter(column, filtergroup);
          }
        });
      }
    }
  }

  resizeColumnsAlarmas() {
    if (this.alarmas.length != 0) {
      // Asumiendo que this.myGrid.attrColumns está listo para ser usado inmediatamente.
      this.myGrid.attrColumns.forEach((column: any) => {
        try {
          if (column.datafield !== this.translate('Temperatura_grados') &&
            column.datafield !== this.translate('Voltaje_vatios') &&
            column.datafield !== this.translate('Bateria_porcentaje')
          ) {
            this.myGrid.autoresizecolumn(column.datafield, column.columntype);
          }
        } catch (e) { }
      });

      // Lógica adicional, si es necesario, después de redimensionar las columnas.
      Utils.renderSizeGrid(
        this.myGrid,
        500,
        [this.translate('Temperatura_grados'), this.translate('Voltaje_vatios'), this.translate('Bateria_porcentaje')]
      );
    }
  }

  // identificaciones grid
  dataAdapterIdentificaciones: any;
  sourceIdentificaciones: any;

  public columnsIdentificacines = [
    { text: 'Id', columntype: 'textbox', filtertype: 'textbox', datafield: 'idCerradura', hidden: true },
    {
      text: this.translate('Fecha'),
      columntype: 'datetimeinput',
      filtertype: 'textbox',
      datafield: 'fecha',
      width: 150,
      cellsrenderer: this.renderRow,
      aggregates: [{
        'Total': function (aggregatedValue, currentValue: number) {
          return aggregatedValue + 1;
        }
      }],
      aggregatesrenderer: function (aggregates) {
        let renderstring = '';
        if (aggregates['Total'] !== undefined) {
          renderstring = '<div style="text-align: left; margin-left: 4px;">' + AppComponent.translate('Total') + ': ' +
            NumberUtils.format(aggregates['Total'], 0) + '</div>';
        }
        return renderstring;
      }
    },
    {
      text: this.translate('Ns_tarjeta'),
      columntype: 'textbox',
      filtertype: 'textbox',
      datafield: 'nsTarjeta',
      width: 100,
      cellsrenderer: this.renderNsTarjeta,
    },
    // { text: this.translate('Ns_movisat'), columntype: 'textbox', filtertype: 'textbox', width: 150, datafield: 'nsMovisat', cellsrenderer: this.renderRow, },
    {
      text: this.translate('Ciudadano'),
      columntype: 'textbox',
      filtertype: 'textbox',
      width: 100,
      datafield: 'nombreCiudadano',
      cellsrenderer: this.renderCiudadano
    },
    { text: this.translate('Aporte_residuo'), columntype: 'checkbox', filtertype: 'bool', width: 110, datafield: 'aporteResiduo' },
    { text: this.translate('Apertura_tapa'), columntype: 'checkbox', filtertype: 'bool', width: 100, datafield: 'aperturaTapa' },
    { text: this.translate('No_cerro_tapa'), columntype: 'checkbox', filtertype: 'bool', width: 90, datafield: 'noCerroTapa' },
    {
      text: this.translate('Tipo_identificacion'),
      columntype: 'textbox',
      width: 130,
      filtertype: 'textbox',
      datafield: 'medio',
      cellsrenderer: this.renderIdentificacion
    },
    {
      text: this.translate('Tipo_permiso'),
      columntype: 'textbox',
      width: 100,
      filtertype: 'textbox',
      datafield: 'tipoPermiso',
      cellsrenderer: this.renderRow,
    },
  ];


  initIdentificaciones() {
    this.columns = [
      { text: 'Selec', columntype: 'textbox', filtertype: 'textbox', datafield: 'selec', hidden: true },
      {
        text: this.translate('Fecha'), columntype: 'datetimeinput', filtertype: 'date', datafield: this.translate('Fecha'), width: 150, cellclassname: this.cellClass,
        cellsrenderer: this.renderRow, aggregates: [{
          'Total': function (aggregatedValue, currentValue: number) {
            return aggregatedValue + 1;
          }
        }],
        aggregatesrenderer: function (aggregates) {
          let renderstring = '';
          if (aggregates["Total"] !== undefined) {
            renderstring = '<div style="text-align: left; margin-right: 4px;">' +
              AppComponent.translate('Total') + ': ' +
              NumberUtils.format(aggregates["Total"], 0) + '</div>';
          }
          return renderstring;
        }
      },
      { text: this.translate('Ns_movisat'), columntype: 'textbox', filtertype: 'textbox', width: 100, datafield: this.translate('Ns_movisat'), cellclassname: this.cellClass },
      { text: this.translate('Ns_tarjeta'), columntype: 'textbox', filtertype: 'textbox', datafield: this.translate('Ns_tarjeta'), width: 100, cellsrenderer: this.renderNsTarjeta, },

      { text: this.translate('Ciudadano'), columntype: 'textbox', filtertype: 'textbox', datafield: this.translate('Ciudadano'), width: 90, cellclassname: this.cellClass },

      {
        text: this.translate('Aporte_residuo'), columntype: 'checkbox', datafield: this.translate('Aporte_residuo'), cellsrenderer: this.renderCheck,
        width: 110,
        filtertype: 'checkedlist',
        filteritems: [
          this.translate('SI'),
          this.translate('NO'),
        ],
      },
      {
        text: this.translate('Apertura_tapa'), columntype: 'checkbox', datafield: this.translate('Apertura_tapa'), cellsrenderer: this.renderCheck,
        width: 100,
        filtertype: 'checkedlist',
        filteritems: [
          this.translate('SI'),
          this.translate('NO'),
        ],
      },
      {
        text: this.translate('No_cerro_tapa'), columntype: 'checkbox', datafield: this.translate('No_cerro_tapa'), cellsrenderer: this.renderCheck,
        width: 85,
        filtertype: 'checkedlist',
        filteritems: [
          this.translate('SI'),
          this.translate('NO'),
        ],
      },

      {
        text: this.translate('Tipo_identificacion'), columntype: 'textbox', datafield: this.translate('Tipo_identificacion'), cellsrenderer: this.renderPermiso, sortable: false, cellclassname: this.cellClass,
        filtertype: 'checkedlist', width: 110,
        filteritems: [
          this.translate('Tarjeta_nfc'),
          'Bluetooth',
        ],
      },
      {
        text: this.translate('Tipo_permiso'), columntype: 'textbox', datafield: this.translate('Tipo_permiso'), cellsrenderer: this.renderTarjeta,
        filtertype: 'checkedlist', width: 100,
        filteritems: [
          this.translate('Master'),
          this.translate('Blanca')
        ],
        cellclassname: this.cellClass
      },

      { text: this.translate('Nombre_elemento'), columntype: 'textbox', filtertype: 'textbox', datafield: this.translate('Nombre_elemento'), width: 120, cellclassname: this.cellClass },
      { text: this.translate('Marca'), columntype: 'textbox', filtertype: 'textbox', datafield: this.translate('Marca'), width: 110, cellclassname: this.cellClass },
      { text: this.translate('Modelo'), columntype: 'textbox', filtertype: 'textbox', datafield: this.translate('Modelo'), width: 110, cellclassname: this.cellClass },
      { text: this.translate('Matricula'), columntype: 'textbox', filtertype: 'textbox', datafield: this.translate('Matricula'), width: 110, cellclassname: this.cellClass },
      { text: this.translate('Direccion'), columntype: 'textbox', filtertype: 'textbox', datafield: this.translate('Direccion'), width: 100, cellclassname: this.cellClass },
      { text: this.translate('Municipio'), columntype: 'textbox', filtertype: 'textbox', datafield: this.translate('Municipio',), width: 100, cellclassname: this.cellClass },
      { text: this.translate('Poblacion'), columntype: 'textbox', filtertype: 'textbox', datafield: this.translate('Poblacion'), width: 110, cellclassname: this.cellClass },
    ];

    this.columns.forEach(column => {
      column.rendered = (element) => { Utils.tooltiprenderer(element) };
    });

    this.sourceIdentificaciones = {
      datatype: 'json',
      dataFields: [
        { name: this.translate('Fecha'), type: 'date', map: 'fecha' },
        { name: this.translate('Ns_movisat'), type: 'string', map: 'nsCerradura' },
        { name: this.translate('Ciudadano'), type: 'string', map: 'nombreCiudadano' },

        { name: this.translate('Aporte_residuo'), type: 'bool', map: 'aporte' },
        { name: this.translate('Apertura_tapa'), type: 'bool', map: 'aperturaTapa' },
        { name: this.translate('No_cerro_tapa'), type: 'bool', map: 'noCerroTapa' },

        { name: this.translate('Tipo_identificacion'), type: 'string', map: 'medio' },
        { name: this.translate('Tipo_permiso'), type: 'string', map: 'tarjetaMaster' },

        { name: this.translate('Nombre_elemento'), type: 'string', map: 'nombreElemento' },
        { name: this.translate('Marca'), type: 'string', map: 'marcaElemento' },
        { name: this.translate('Modelo'), type: 'string', map: 'modeloElemento' },
        { name: this.translate('Matricula'), type: 'string', map: 'matriculaElemento' },
        { name: this.translate('Observaciones'), type: 'string', map: 'observacionesElemento' },

        { name: this.translate('Direccion'), type: 'string', map: 'direccionElemento' },
        { name: this.translate('Municipio'), type: 'string', map: 'municipioElemento' },
        { name: this.translate('Poblacion'), type: 'string', map: 'poblacionElemento' },
        { name: 'selec', map: 'selec' }
      ],
      localdata: [],
    };

    this.dataAdapterIdentificaciones = new jqx.dataAdapter(this.sourceIdentificaciones);
  }

  //ALARMAS
  getAlarmasByCerraduras() {
    this.columnsAlarmas = [
      {
        text: this.translate('Fecha'), columntype: 'datetimeinput', filtertype: 'date', datafield: this.translate('Fecha'), width: 140, cellsrenderer: this.renderDate,
        aggregates: [{
          'Total': function (aggregatedValue, currentValue: number) {
            return aggregatedValue + 1;
          }
        }],
        aggregatesrenderer: function (aggregates) {
          let renderstring = '';
          if (aggregates["Total"] !== undefined) {
            renderstring = '<div style="text-align: left; margin-left: 4px;">' + AppComponent.translate('Total') + ': ' +
              NumberUtils.format(aggregates["Total"], 0) + '</div>';
          }
          return renderstring;
        }
      },
      { text: this.translate('Ns_movisat'), columntype: 'textbox', filtertype: 'textbox', datafield: this.translate('Ns_movisat'), width: 110, cellsrenderer: this.rendexTextGeneric },
      { text: this.translate('Alarma'), columntype: 'textbox', filtertype: 'checkedlist', datafield: this.translate('Alarma'), width: 90, cellsrenderer: this.rendexTextGeneric },
      { text: this.translate('Bateria_porcentaje'), columntype: 'textbox', filtertype: 'textbox', datafield: this.translate('Bateria_porcentaje'), width: 90, cellsrenderer: this.rendexTextGeneric, },
      { text: this.translate('Temperatura_grados'), columntype: 'textbox', filtertype: 'textbox', datafield: this.translate('Temperatura_grados'), width: 100, cellsrenderer: this.rendexTextGeneric, },
      { text: this.translate('Voltaje_vatios'), columntype: 'textbox', filtertype: 'textbox', datafield: this.translate('Voltaje_vatios'), width: 70, cellsrenderer: this.rendexTextGeneric, },
      { text: this.translate('Nombre_elemento'), columntype: 'textbox', filtertype: 'textbox', datafield: this.translate('Nombre_elemento'), width: 120, cellsrenderer: this.rendexTextGeneric },
      { text: this.translate('Marca'), columntype: 'textbox', filtertype: 'checkedlist', datafield: this.translate('Marca'), width: 70, cellsrenderer: this.rendexTextGeneric },
      { text: this.translate('Modelo'), columntype: 'textbox', filtertype: 'checkedlist', datafield: this.translate('Modelo'), width: 70, cellsrenderer: this.rendexTextGeneric },
      { text: this.translate('Matricula'), columntype: 'textbox', filtertype: 'textbox', datafield: this.translate('Matricula'), width: 90, cellsrenderer: this.rendexTextGeneric },
      { text: this.translate('Observaciones'), columntype: 'textbox', filtertype: 'textbox', datafield: this.translate('Observaciones'), sortable: false, filterable: false, width: 100, cellsrenderer: this.rendexTextGeneric },
      { text: this.translate('Direccion'), columntype: 'textbox', filtertype: 'textbox', datafield: this.translate('Direccion'), width: 100, cellsrenderer: this.rendexTextGeneric },
      { text: this.translate('Municipio'), columntype: 'textbox', filtertype: 'textbox', datafield: this.translate('Municipio'), width: 90, cellsrenderer: this.rendexTextGeneric },
      { text: this.translate('Poblacion'), columntype: 'textbox', filtertype: 'textbox', datafield: this.translate('Poblacion'), width: 90, cellsrenderer: this.rendexTextGeneric },
      { text: 'Selec', columntype: 'textbox', filtertype: 'textbox', datafield: 'selec', hidden: true }
    ];


    this.sourceAlarmas = {
      datatype: 'json',
      dataFields: [
        { name: this.translate('Fecha'), type: 'string', map: 'fecha' },
        { name: this.translate('Ns_movisat'), type: 'string', map: 'nsCerradura' },
        { name: this.translate('Alarma'), type: 'string', map: 'alarma' },

        { name: this.translate('Bateria_porcentaje'), type: 'number', map: 'bateriaPorcentaje' },
        { name: this.translate('Temperatura_grados'), type: 'number', map: 'temperatura' },
        { name: this.translate('Voltaje_vatios'), type: 'number', map: 'voltaje' },

        { name: this.translate('Nombre_elemento'), type: 'string', map: 'nombreElemento' },
        { name: this.translate('Marca'), type: 'string', map: 'marcaElemento' },
        { name: this.translate('Modelo'), type: 'string', map: 'modeloElemento' },
        { name: this.translate('Matricula'), type: 'string', map: 'matriculaElemento' },
        { name: this.translate('Observaciones'), type: 'string', map: 'observacionesElemento' },

        { name: this.translate('Direccion'), type: 'string', map: 'direccionElemento' },
        { name: this.translate('Municipio'), type: 'string', map: 'municipioElemento' },
        { name: this.translate('Poblacion'), type: 'string', map: 'poblacionElemento' },
        { name: 'selec', map: 'selec' }
      ],
      localdata: this.alarmas
    };
    this.dataAdapteAlarmas = new jqx.dataAdapter(this.sourceAlarmas);


    this.columns.forEach(column => {
      column.rendered = (element) => { Utils.tooltiprenderer(element) };
    });

    setTimeout(() => {
      this.myGrid.setOptions({
        rowsheight: 20,
        columnsheight: 20
      })
    }, 200);
  }

  initEnvios() {

    this.columnsEnvios = [
      { text: 'Selec', columntype: 'textbox', filtertype: 'textbox', datafield: 'selec', hidden: true },
      { text: 'Id', columntype: 'textbox', filtertype: 'textbox', datafield: 'id', hidden: true },
      {
        text: this.translate('Fecha_envio'),
        columntype: 'datetimeinput',
        filtertype: 'date',
        width: 150,
        datafield: 'fechaEnvio',
        cellsrenderer: this.renderRow,
        aggregates: [{
          'Total': function (aggregatedValue, currentValue: number) {
            return aggregatedValue + 1;
          }
        }],
        aggregatesrenderer: function (aggregates) {
          let renderstring = '';
          if (aggregates['Total'] !== undefined) {
            renderstring = '<div style="text-align: left; margin-left: 4px;">' + AppComponent.translate('Total') + ': ' +
              NumberUtils.format(aggregates['Total'], 0) + '</div>';
          }
          return renderstring;
        }
      },
      {
        text: this.translate('Ns_movisat'),
        width: 160,
        columntype: 'textbox',
        filtertype: 'textbox',
        datafield: 'nsMovisat',
        cellclassname: this.cellClass
      },
      {
        text: this.translate('Num_identificaciones'),
        columntype: 'textbox',
        filtertype: 'textbox',
        datafield: 'cantidad',
        width: 100,
        cellsrenderer: this.numberrenderer,
        cellclassname: this.cellClass
      },
      {
        text: this.translate('Bateria'),
        columntype: 'textbox',
        filtertype: 'textbox',
        width: 60,
        datafield: 'bateriaPorcentaje',
        cellsrenderer: this.numberrenderer,
        cellclassname: this.cellClass
      },
      {
        text: this.translate('Temperatura'),
        columntype: 'textbox',
        filtertype: 'textbox',
        datafield: 'temperatura',
        width: 80,
        cellsrenderer: this.numberrenderer,
        cellclassname: this.cellClass
      },
      {
        text: this.translate('Direccion'),
        columntype: 'textbox',
        filtertype: 'textbox',
        datafield: 'calle',
        width: 100,
        cellclassname: this.cellClass
      },
      {
        text: this.translate('Municipio'),
        columntype: 'textbox',
        filtertype: 'textbox',
        datafield: 'municipio',
        width: 70,
        cellclassname: this.cellClass
      },
      {
        text: this.translate('Poblacion'),
        columntype: 'textbox',
        filtertype: 'textbox',
        datafield: 'poblacion',
        width: 70,
        cellclassname: this.cellClass
      },
      {
        text: this.translate('Provincia'),
        columntype: 'textbox',
        filtertype: 'textbox',
        datafield: 'provincia',
        width: 70,
        cellclassname: this.cellClass
      },
      {
        text: this.translate('Num_calle'),
        columntype: 'textbox',
        filtertype: 'textbox',
        datafield: 'numero',
        cellalign: 'right',
        width: 60,
        cellclassname: this.cellClass,
      },
      {
        text: this.translate('Metros'),
        columntype: 'textbox',
        filtertype: 'textbox',
        datafield: 'metros',
        width: 60,
        cellsrenderer: this.numberrendererDecimales,
        cellclassname: this.cellClass
      },
      {
        text: this.translate('Nombre_elemento'),
        columntype: 'textbox',
        filtertype: 'textbox',
        datafield: 'nombreElemento',
        width: 110,
        cellclassname: this.cellClass
      },
      {
        text: this.translate('Marca'),
        columntype: 'textbox',
        filtertype: 'checkedlist',
        datafield: 'marcaElemento',
        width: 100,
        cellclassname: this.cellClass
      },
      {
        text: this.translate('Modelo'),
        columntype: 'textbox',
        filtertype: 'checkedlist',
        datafield: 'modeloElemento',
        width: 100,
        cellclassname: this.cellClass
      },
      {
        text: this.translate('Matricula'),
        columntype: 'textbox',
        filtertype: 'textbox',
        datafield: 'matriculaElemento',
        width: 75,
        cellclassname: this.cellClass
      },
      {
        text: this.translate('Direccion'),
        columntype: 'textbox',
        filtertype: 'textbox',
        datafield: 'direccionElemento',
        width: 100,
        cellclassname: this.cellClass
      },
      {
        text: this.translate('Municipio'),
        columntype: 'textbox',
        filtertype: 'textbox',
        datafield: 'municipioElemento',
        width: 70,
        cellclassname: this.cellClass
      },
      {
        text: this.translate('Poblacion'),
        columntype: 'textbox',
        filtertype: 'textbox',
        datafield: 'poblacionElemento',
        width: 70,
        cellclassname: this.cellClass
      },
      {
        text: this.translate('Observaciones'),
        columntype: 'textbox',
        filtertype: 'textbox',
        datafield: 'observacionesElemento',
        menu: false,
        sortable: false,
        width: 100,
        cellclassname: this.cellClass
      },
    ];


    this.columns.forEach(column => {
      column.rendered = (element) => {
        Utils.tooltiprenderer(element);
      };
    });

    this.elementos = MainComponent.getInstance().elementsList;

    this.sourceEnvios = {
      datatype: 'json',
      sort: this.customsortfunc,
      datafields: [
        { name: 'id', type: 'number', map: 'id' },
        { name: 'fechaEnvio', type: 'date', map: 'fechaEnvio' },
        { name: 'nsMovisat', type: 'string', map: 'nsMovisat' },
        { name: 'cantidad', type: 'number', map: 'cantidad' },
        { name: 'bateriaPorcentaje', type: 'number', map: 'bateriaPorcentaje' },
        { name: 'temperatura', type: 'number', map: 'temperatura' },
        { name: 'latElemento', type: 'number', map: 'latElemento' },
        { name: 'lngElemento', type: 'number', map: 'lngElemento' },
        { name: 'idElemento', type: 'number', map: 'idElemento' },
        { name: 'metros', type: 'number', map: 'metros' },
        { name: 'provincia', type: 'string', map: 'provincia' },
        { name: 'municipio', type: 'string', map: 'municipio' },
        { name: 'poblacion', type: 'string', map: 'poblacion' },
        { name: 'calle', type: 'string', map: 'calle' },
        { name: 'numero', type: 'string', map: 'numero' },
        { name: 'codigoPostal', type: 'string', map: 'codigoPostal' },
        { name: 'nombreElemento', type: 'string', map: 'nombreElemento' },
        { name: 'marcaElemento', type: 'string', map: 'marcaElemento' },
        { name: 'modeloElemento', type: 'string', map: 'modeloElemento' },
        { name: 'matriculaElemento', type: 'string', map: 'matriculaElemento' },
        { name: 'direccionElemento', type: 'string', map: 'direccionElemento' },
        { name: 'municipioElemento', type: 'string', map: 'municipioElemento' },
        { name: 'poblacionElemento', type: 'string', map: 'poblacionElemento' },
        { name: 'observacionesElemento', type: 'string', map: 'observacionesElemento' },
        { name: 'selec', map: 'selec' }
      ],
      localdata: this.historicosEnviosIdentificador,
    };
    this.dataAdapterEnvios = new jqx.dataAdapter(this.sourceEnvios);
  }

  sortedColumn: any;
  customsortfunc = (column: any, direction: string | boolean): void => {
    let sortdata = new Array();
    if (direction == 'ascending') direction = true;
    if (direction == 'descending') direction = false;
    if (direction != null) {
      for (let i = 0; i < this.historicosEnviosIdentificador.length; i++) {
        sortdata.push(this.historicosEnviosIdentificador[i]);
      }
    } else {
      sortdata = this.historicosEnviosIdentificador;
    }
    this.sortedColumn = column;

    let tmpToString = Object.prototype.toString;
    Object.prototype.toString = (typeof column == 'function') ? column : () => { return this[column] };
    if (direction != null) {
      sortdata.sort(this.compare);
      if (!direction) {
        sortdata.reverse();
      }
    }
    this.sourceEnvios.localdata = sortdata;
    this.myGrid.updatebounddata('sort');
    Object.prototype.toString = tmpToString;
  }

  compare = (value1: any, value2: any): any => {
    if (this.sortedColumn === 'fechaEnvio') {
      value1 = new Date(value1[this.sortedColumn]).getTime();
      value2 = new Date(value2[this.sortedColumn]).getTime();
    } else {
      value1 = value1[this.sortedColumn];
      value2 = value2[this.sortedColumn];
    }

    try {
      let tmpvalue1 = parseFloat(value1);
      if (isNaN(tmpvalue1)) {
        if (value1 < value2) { return -1; }
        if (value1 > value2) { return 1; }
      }
      else {
        let tmpvalue2 = parseFloat(value2);
        if (tmpvalue1 < tmpvalue2) { return -1; }
        if (tmpvalue1 > tmpvalue2) { return 1; }
      }
    } catch (error) {
    }
    return 0;
  };

  envioIdentificador: HistoricoEnvioIdentiiicadorModel = new HistoricoEnvioIdentiiicadorModel();
  gridIdentificaciones: jqxGridComponent;
  initRowDetails = (
    index: any,
    parentElement: any,
    gridElement: any,
    datarecord: any
  ): void => {
    if (parentElement && datarecord) {
      let nestedGridContainer = parentElement.children[0];

      let i = this.historicosEnviosIdentificador.findIndex(
        (envio) => envio.id === datarecord.id
      );

      if (this.envioIdentificador) {
        this.envioIdentificador = new HistoricoEnvioIdentiiicadorModel();
      }
      this.envioIdentificador = this.historicosEnviosIdentificador[i];
      if (this.envioIdentificador) {
        let sourceDetalle = {
          datatype: 'array',
          datafields: [
            { name: 'idCerradura', type: 'number', map: 'idCerradura' },
            { name: 'fecha', type: 'date', map: 'fecha' },
            { name: 'nsTarjeta', type: 'number', map: 'nsTarjeta' },
            { name: 'nsMovisat', type: 'string', map: 'nsCerradura' },
            { name: 'nombreCiudadano', type: 'string', map: 'nombreCiudadano' },
            { name: 'aporteResiduo', type: 'boolean', map: 'aporte' },
            { name: 'aperturaTapa', type: 'boolean', map: 'aperturaTapa' },
            { name: 'noCerroTapa', type: 'boolean', map: 'noCerroTapa' },
            //{ name: 'nsCerradura', type: 'string', map: 'nsCerradura' },
            { name: 'observaciones', type: 'string', map: 'observacionesElemento' },
            { name: 'matricula', type: 'string', map: 'matriculaElemento' },
            { name: 'medio', type: 'string', map: 'medio' },
            { name: 'tipoPermiso', type: 'string', map: 'tipoPermiso' },
            { name: 'marca', type: 'string', map: 'marcaElemento' },
            { name: 'modelo', type: 'string', map: 'modeloElemento' },
            { name: 'nombreElemento', type: 'string', map: 'nombreElemento' },
            { name: 'direccion', type: 'string', map: 'direccionElemento' },
            { name: 'municipio', type: 'string', map: 'municipioElemento' },
            { name: 'poblacion', type: 'string', map: 'poblacionElemento' },
          ],
          localdata: this.envioIdentificador.Detalle,
        };

        let dataDetalleEnvios = new jqx.dataAdapter(sourceDetalle);

        if (nestedGridContainer != null) {
          let setting = {
            width: '97%',
            height: '90%',
            source: dataDetalleEnvios,
            theme: this.theme,
            columns: this.columnsIdentificacines,
            localization: this.langGrid,
            editable: false,
            columnsresize: true
            // me subcribo al evento de doble click en la fila
          };
          this.gridIdentificaciones = jqwidgets.createInstance(
            `#${nestedGridContainer.id}`,
            'jqxGrid',
            setting
          );
        }

        setTimeout(() => {
          this.resizeGridDetails(this.gridIdentificaciones);
        }, 200);
      }
    } else {
      return;
    }
  };

  showRowDetails: any[] = [];
  rowCollapse(event: any) {
    let id = this.myGrid.getrowdata(event.args.rowindex).id;
    this.showRowDetails.splice(this.showRowDetails.indexOf(id), 1);
  }

  rowdetailstemplate = (index: any): any => {
    var details = {
      rowdetails: '<div id="nestedGrid" style="margin: 10px;"></div>',
      rowdetailsheight: 180,
      rowdetailshidden: true,
    };
    this.editrow = index;
    //Saca el usuario y adapta el row height al numero de accesos que tenga
    let rowId = this.myGrid.getcellvalue(index, 'id');
    let envio = this.historicosEnviosIdentificador.find(x => x.id == rowId);

    if (envio) {
      if (!envio.Detalle) {
        return details;
      }
    }
    return details;
  };

  onSort(event: any) {
    this.showRowDetails.forEach(row => {
      this.myGrid.getrows().forEach(elem => {
        if (row === elem.id) {
          this.myGrid.showrowdetails(elem.boundindex);
        }
      });
    });
  }

  rowExpand(event: any) {
    let rowId = this.myGrid.getcellvalue(event.args.rowindex, 'id');
    let envio = this.historicosEnviosIdentificador.find(x => x.id == rowId);

    if (!envio || envio.cantidad == 0) {
      this.myGrid.hiderowdetails(event.args.rowindex);
    } else {
      let id = this.myGrid.getrowdata(event.args.rowindex).id;
      if (!(this.showRowDetails.includes(id))) {
        this.showRowDetails.push(id);
      }
    }
  }

  editrow: number = -1;
  resizeGridDetails(grid: jqxGridComponent) {
    if (grid) {
      grid.setOptions({
        columnsheight: 20,
        rowsheight: 20,
        statusbarheight: 20,
        columnsresize: true,
      });
      grid.refresh();
      grid.showrowdetails(this.editrow);
    }
  }
  private fechaIni: Date;
  private fechaFin: Date

  async aceptarEnvios() {
    this.fechaIni = this.header.periodoSelect.getFechaIni();
    this.fechaFin = this.header.periodoSelect.getFechaFin();

    let date = new DateIdentificacionModel()
    date.Desde = this.fechaIni;
    date.Hasta = this.fechaFin;

    this.cerradura = ListadoCerraduraComponent.instance.cerradura;

    this.historicosEnviosIdentificador = await this.cerraduraService.getHistoricoCerradura(this.cerradura.id, date);

    this.sourceEnvios.localdata = this.historicosEnviosIdentificador;
    this.myGridEnvios.updatebounddata();
  }

  async aceptarAlarmas() {
    this.fechaIni = this.header.periodoSelect.getFechaIni();
    this.fechaFin = this.header.periodoSelect.getFechaFin();

    let date = new DateIdentificacionModel()
    date.Desde = this.fechaIni;
    date.Hasta = this.fechaFin;

    this.cerradura = ListadoCerraduraComponent.instance.cerradura;

    this.alarmas = await this.cerraduraService.getAlarmasCerradura(this.cerradura.id, date);

    this.sourceAlarmas.localdata = this.alarmas;
    this.myGrid.updatebounddata();

  }

  async aceptarIdentificaciones() {
    this.fechaIni = this.header.periodoSelect.getFechaIni();
    this.fechaFin = this.header.periodoSelect.getFechaFin();

    let date = new DateIdentificacionModel()
    date.Desde = this.fechaIni;
    date.Hasta = this.fechaFin;

    this.cerradura = ListadoCerraduraComponent.instance.cerradura;

    this.identificaciones = await this.cerraduraService.getIdentificacionesDate(this.cerradura.id, date);

    this.sourceIdentificaciones.localdata = this.historicosEnviosIdentificador;
    this.grid.updatebounddata();
  }


  onExportar(arg0: string, $event: any) {
    switch (arg0) {
      case 'alarmas':
        this.onExportarAlarmas();
        break;
      case 'identificacion':
        this.onExportarIdentificaciones();
        break;
      case 'envios':
        this.onExportarEnvios();
        break;
      default:
        return;
    }
  }

  // Función común para eliminar campos innecesarios
  removeUnwantedFields(element: any, fields: string[]): any {
    fields.forEach(field => delete element[field]);
    return element;
  }

  // Función común para formatear fecha y hora
  formatDateAndTime(element: any, dateField: string): any {
    if (element[dateField]) {
      const fechaCompleta = new Date(element[dateField]);
      const [fecha, hora] = element[dateField].split(' ');
      element[dateField] = fecha;
      element['Hora'] = hora;
    }
    return element;
  }

  onExportarAlarmas() {
    const json = JSON.parse(JSON.stringify(this.myGrid.getrows()));

    json.forEach(element => {
      // Eliminar campos innecesarios
      this.removeUnwantedFields(element, ['uid', 'uniqueid', 'visibleindex', 'boundindex', 'selec', 'dataindex']);

      // Formatear fecha y hora
      element = this.formatDateAndTime(element, this.translate('Fecha'));

      // Formatear otros campos numéricos
      element[this.translate('Temperatura_grados')] = this.formatNumericField(element[this.translate('Temperatura_grados')], 10, 2);
      element[this.translate('Voltaje_vatios')] = this.formatNumericField(element[this.translate('Voltaje_vatios')], 100, 2);
      element[this.translate('Bateria_porcentaje')] = this.formatNumericField(element[this.translate('Bateria_porcentaje')], 1, 2);
    });

    this.exportToExcel(json, 'Historico_alarmas_IoT');
  }

  // Función reutilizable para formatear campos numéricos
  formatNumericField(value: any, divisor: number, decimalPlaces: number): string {
    const result = (value / divisor).toFixed(decimalPlaces);
    return isNaN(Number(result)) ? '' : result;
  }

  onExportarIdentificaciones() {
    const json = JSON.parse(JSON.stringify(this.grid.getdisplayrows()));

    json.forEach(element => {
      // Eliminar campos innecesarios
      this.removeUnwantedFields(element, ['uid', 'uniqueid', 'visibleindex', 'boundindex']);

      // Formatear fecha
      if (element[this.translate('Fecha')]) {
        element[this.translate('Fecha')] = DateUtils.formatDateTimeShort(new Date(element[this.translate('Fecha')]), true);
      }

      // Asignar traducciones a valores booleanos
      element[this.translate('Aporte_residuo')] = element[this.translate('Aporte_residuo')] ? this.translate('SI') : this.translate('NO');
      element[this.translate('Apertura_tapa')] = element[this.translate('Apertura_tapa')] ? this.translate('SI') : this.translate('NO');
      element[this.translate('No_cerro_tapa')] = element[this.translate('No_cerro_tapa')] ? this.translate('SI') : this.translate('NO');

      // Formatear tipo de identificación y permisos
      element[this.translate('Tipo_identificacion')] = element[this.translate('Tipo_identificacion')] == 0 ? this.translate('Tarjeta_nfc') : 'Bluetooth';
      element[this.translate('Tipo_permiso')] = element[this.translate('Tipo_permiso')] ? this.translate('Master') : this.translate('Blanca');
    });

    this.exportToExcel(json, 'Historicos_identificaciones');
  }

  onExportarEnvios() {
    if (this.myGridEnvios.getrows().length === 0) {
      return MainComponent.getInstance().showWarning('ATENCION', this.translate('No_existen_datos'), 2000);
    } else {
      const json = JSON.parse(this.myGridEnvios.exportdata('json'));

      json.forEach((element, index) => {
        element[this.translate('Bateria')] = this.formatNumericField(element[this.translate('Bateria')], 1, 0);
        element[this.translate('Temperatura')] = this.formatNumericField(element[this.translate('Temperatura')], 1, 0);
        element[this.translate('Num_identificaciones')] = this.formatNumericField(element[this.translate('Num_identificaciones')], 1, 0);
        element[this.translate('Fecha_envio')] = DateUtils.formatDateTime(this.myGrid.getrows()[index].fechaEnvio, false).replace('T', ' ');
      });

      this.exportToExcel(json, 'Historico_envios_dispositivo_identificador');
    }
  }

  // Función común para exportar a Excel
  exportToExcel(data: any[], fileName: string) {
    const ws: xlsx.WorkSheet = xlsx.utils.json_to_sheet(data);
    this.generateAutofilterHeader(ws);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Hoja1');
    xlsx.writeFile(wb, DateUtils.formatDateAMDhms(new Date()) + '_' + this.translate(fileName) + '.xlsx');
  }

  generateAutofilterHeader(sheet: any) {
    sheet['!autofilter'] = { ref: sheet['!ref'] };
  }



  onPrint(arg0: string, $event: any) {
    switch (arg0) {
      case 'alarmas':
        this.onPrintGrid(this.myGrid);
        break;
      case 'identificacion':
        this.onPrintGrid(this.grid);
        break;
      case 'envios':
        this.onPrintGrid(this.myGridEnvios);
        break;
      default:
        return;
    }
  }

  // Función reutilizable para manejar la impresión de cualquier grid
  onPrintGrid(grid: any) {
    if (grid.getrows().length === 0) {
      return MainComponent.getInstance().showWarning('ATENCION', this.translate('No_existen_datos'), 2000);
    } else {
      const gridContent = grid.exportdata('html');
      const newWindow = window.open('', '', 'width=800, height=500');
      const document = newWindow.document.open();
      const pageContent =
        '<!DOCTYPE html>\n' +
        '<html>\n' +
        '<head>\n' +
        '<meta charset="utf-8" />\n' +
        '<title>jQWidgets Grid</title>\n' +
        '</head>\n' +
        '<body>\n' +
        gridContent +
        '\n</body>\n</html>';

      document.write(pageContent);
      document.close();

      newWindow.onafterprint = function () {
        newWindow.close();
      };

      newWindow.print();
    }
  }


  onBuscar(arg0: string, $event: any) {
    // Obtén el valor del input
    let value = this.header.searchInput['nativeElement'].value;

    if (value.length >= 3) {

      // Crea el filtro usando jqx.filter
      let filtergroup = new jqx.filter();
      let filter_or_operator = 1;
      let filtervalue = value;
      let filtercondition = 'contains'; // Usamos 'contains' para buscar coincidencias
      let filter = filtergroup.createfilter('stringfilter', filtervalue, filtercondition);
      filtergroup.addfilter(filter_or_operator, filter);

      // Función para aplicar el filtro a todas las columnas
      const applyFilterToAllColumns = (grid: jqxGridComponent, dataAdapter: any) => {
        // Limpiar filtros previos
        grid.clearfilters();

        // Obtener todas las columnas del grid
        let columns = dataAdapter.map((column: any) => column.datafield);

        // Iterar sobre cada columna y agregar el filtro
        columns.forEach((column: any) => {
          grid.addfilter(column.datafield, filtergroup); // Aplica el filtro a cada columna
        });

        // Aplicar los filtros
        grid.applyfilters();
      };

      // Aplica el filtro al grid correspondiente según el valor de arg0
      switch (arg0) {
        case 'alarmas':
          applyFilterToAllColumns(this.myGrid, this.columnsAlarmas);
          break;

        case 'identificacion':
          applyFilterToAllColumns(this.grid, this.columns);
          break;

        case 'envios':
          applyFilterToAllColumns(this.myGridEnvios, this.columnsEnvios);
          break;

        default:
          return;
      }

    } else {
      return;
    }

  }

  eventFilter(arg0: string, $event: any) {

    switch (arg0) {
      case 'alarmas':
        this.aceptarAlarmas();
        break;
      case 'identificaciones':
        this.aceptarIdentificaciones();
        break;
      case 'envios':
        this.aceptarEnvios();
        break;
    }
  }

  eventResetFilter(arg0: string, $event: any) {

    this.header.periodoSelect.desde = new Date();
    this.header.periodoSelect.desde.setHours(0, 0, 0);
    this.header.periodoSelect.hasta = new Date();
    this.header.periodoSelect.hasta.setHours(23, 59, 59);
    PeriodoSelectComponent._this.dateForm.get('desde').setValue(new Date());
    PeriodoSelectComponent._this.dateForm.get('hasta').setValue(new Date());
    this.header.periodoSelect.cbPeriodo.selectedIndex(0);

    this.header.searchInput['nativeElement'].value = '';

    switch (arg0) {
      case 'alarmas':
        this.aceptarAlarmas();
        break;
      case 'identificaciones':
        this.aceptarIdentificaciones();
        break;
      case 'envios':
        this.aceptarEnvios();
        break;
    }

  }



  // Para traducir los textos
  public translate(text: string): string {
    return AppComponent.translate(text);
  }

}
