import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { TokenModel } from './models/token.model';
import { TicketModel } from './models/ticket.model';
import { UsuarioModel } from './models/usuario.model';
import { ProvCartoModel } from './models/prov-carto.model';
import { ProvCartoEmpresaModel } from './models/prov-carto-empresa.model';
import { RolModel } from './models/rol.model';
import { Observable } from 'rxjs';
import { AplicacionModel } from './models/aplicacion.model';

@Injectable({
  providedIn: 'root'
})
export class SsoService {
  private token: TokenModel = null;
  private ticket: TicketModel;
  private timer = null;
  private empresaId: any;

  constructor(private http: HttpClient) {
    // AO: 30/10/2023 Esto ya no es necesario
    // const t = setTimeout(async () => {
    //   clearTimeout(t);
    //   await this.getToken();
    //   this.empresaId = this.getTicket().Empresa.Id;
    // }, 0);
  }

  public async getToken(): Promise<TokenModel> {
    try {
      // Preparo la cabecera
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      };
      const body = new URLSearchParams();
      body.set('grant_type', environment.grantTypeSSO);
      body.set('client_id', environment.clientIdSSO);
      body.set('client_secret', environment.clientSecretSSO);
      body.set('username', environment.userNameSSO);
      body.set('password', environment.passwordSSO);
      // Pido el token y lo guardo
      this.token = await this.http.post<TokenModel>(environment.apiSSO + '/sso/token', body.toString(), httpOptions).toPromise();
      sessionStorage.setItem('tokenSSO', this.token.access_token);
      // Activo un temporizador para pedir un nuevo token un minuto antes que caduque
      if (this.token.expires_in >= 120) {
        this.startTimer(this.token.expires_in - 60);
      } else {
        this.startTimer(Math.round(this.token.expires_in / 2));
      }
    } catch {
    }
    return this.token;
  }

  // Inicia el temporizador para refrescar el token de la api
  private startTimer(interval: number) {
    this.stopTimer();
    this.timer = setTimeout(() => {
      this.getToken();
    }, interval * 1000);
  }

  // Finaliza el temporizador de refresco de tocken de la api
  private stopTimer() {
    if (this.timer !== null) {
      clearTimeout(this.timer);
    }
    this.timer = null;
  }

  // Devuelve el ticket almacenado actualmente
  public getTicket(): TicketModel {
    // Primero busco el que tengo en la sesión
    let ticket: TicketModel = <TicketModel>JSON.parse(sessionStorage.getItem('ticketSSO'));
    if (!ticket) {
      // Si no hay en la sesión puede ser porque he iniciado la app escribiendo
      // directamente la URL en el navegador, entonces busco en el localstorage
      // por si tengo un ticket válido almacenado de otro inicio
      ticket = <TicketModel>JSON.parse(localStorage.getItem('ticketSSO'));
      if (ticket) {
        sessionStorage.setItem('ticketSSO', JSON.stringify(ticket));
      }
    }
    if (ticket) {
      this.empresaId = ticket.Empresa.Id;
    }
    return ticket;
  }

  // Valida un ticket en el SSO y devuelve la información
  private async validaTicket(ticketId: string): Promise<TicketModel> {
    try {
      return await this.http.get<TicketModel>(environment.apiSSO + '/sso/ticket/valida/' + ticketId).toPromise();
    } catch (e) {
      console.log(e);
    }
    return null;
  }

  // Recupera la información de inicio de sesión del SSO
  public async initSession(ticketId: string): Promise<boolean> {
    try {
      this.ticket = null;
      if (ticketId !== undefined) {
        this.ticket = await this.validaTicket(ticketId);
        if (this.ticket !== null) {
          localStorage.setItem('ticketSSO', JSON.stringify(this.ticket));
          sessionStorage.setItem('ticketSSO', JSON.stringify(this.ticket));
          return true;
        }
      }
    } catch {
    }
    sessionStorage.removeItem('ticketSSO');
    return false;
  }

  // Permite saber si un usuario está validado en el SSO
  public async isUsuarioValidado(userId: number): Promise<boolean> {
    try {
      return await this.http.get<boolean>(environment.apiSSO + '/sso/usuario/validado/' + userId).toPromise();
    } catch (e) {
    }
    return false;
  }

  // Borra el ticket almacenado
  public endAppSession(): void {
    sessionStorage.removeItem('ticketSSO');
    window.location.replace(environment.webSSO);
  }

  // Devuelve un usuario a partir de id
  public async getUsuarioById(userId: number): Promise<UsuarioModel> {
    try {
      return await this.http.get<any>(environment.apiSSO + '/sso/usuario/' + userId).toPromise();
    } catch (e) {
      return null;
    }
  }

  // Devuelve una lista de usuarios a partir del id de una empresa
  public async getUsuariosByEmpresaId(): Promise<UsuarioModel[]> {
    try {
      return await this.http.get<UsuarioModel[]>(environment.apiSSO + '/sso/empresa/usuarios/' + this.empresaId).toPromise();
    } catch (e) {
      return null;
    }
  }

  // Devuelve una lista de proveedores de cartografía
  public async getProvCarto(): Promise<ProvCartoModel[]> {
    try {
      return await this.http.get<ProvCartoModel[]>(environment.apiSSO + '/sso/provcarto').toPromise();
    } catch (e) {
      return null;
    }
  }

  // Modifica el proveedor de cartografía de la empresa
  async saveProvCarto(carto: ProvCartoEmpresaModel): Promise<ProvCartoEmpresaModel> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    try {
      return await this.http.post<ProvCartoEmpresaModel>(environment.apiSSO + '/sso/provcarto',
        JSON.stringify(carto), httpOptions).toPromise();
    } catch (e) {
      console.log(e);
    }
  }

  async getRoles(): Promise<RolModel[]> {
    let result: RolModel[] = null;

    try {
      result = await this.http
        .get<RolModel[]>(`${environment.apiSSO}/sso/roles`)
        .toPromise();
    } catch (e) {
      console.log(e);
      window.alert(e.error);
    }
    return result;
  }


  getAppsBusiness(): Promise<any[]> {
    try {
      return this.http.get<any[]>(`${environment.apiSSO}/sso/aplicaciones/empresa/` + this.getTicket().Empresa.Id).toPromise();
    } catch (error) {
      console.log(error);
      window.alert(error);
    }
  }

  async getAppsByProyects(): Promise<any[]> {
    let result = null;
    try {
      result = await this.http.get<any[]>(`${environment.apiSSO}/sso/empresa/aplicaciones/` + this.getTicket().Empresa.Id).toPromise();
      return result;
    } catch (error) {
      console.log(error);
      window.alert(error);
    }
  }

  async getApps(): Promise<AplicacionModel[]> {
    return await this.http.get<AplicacionModel[]>(`${environment.apiSSO}/sso/aplicaciones`).toPromise();
  }

  async getRolesApp(): Promise<any> {
    let result: AplicacionModel = null;

    try {
      result = await this.http
        .get<AplicacionModel>(`${environment.apiSSO}/sso/roles/aplicaciones/`)
        .toPromise();
    } catch (e) {
      console.log(e);
      window.alert(e.error);
    }
    return result;
  }

  getRolesAppByEmpresa(app: number): Promise<AplicacionModel[]> {
    try {
      return this.http.get<AplicacionModel[]>(`${environment.apiSSO}/sso/aplicacion/roles/${app}`).toPromise();
    } catch (error) {
      console.log(error);
      window.alert(error);
    }
  }

}
