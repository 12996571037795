<div class="loader-container" *ngIf="showLoader">
  <div class="loader"></div>
</div>
<jqxWindow #winFormEdificios [jqxWindowAutoResize] [position]="getFormPos(winFormEdificios, 0)" [width]="mapWidth"
  [isModal]="false" [height]="mapHeight" [zIndex]="900" [autoOpen]="true" [showCloseButton]="true" [showCollapseButton]="true"
  [resizable]="true" [title]="translate('Edicion_zonas_cartograficas') + ' ' + '<b>' + this.edificio.Nombre + '</b>'"
  (onClose)="onClose()" (onExpand)="onExpand($event)">
  <div class="formHeader">
  </div>
  <div class="form" style="float:left; overflow-x: hidden;">
    <jqxTabs #tabEdificiosEdit [width]="'100%'" [height]="'100%'" [position]="'top'" [theme]="environment.tema"
      (onTabclick)="onTabclick($event)">
      <ul style="height: 15px;">
        <li style="height: 15px;">{{ translate('Detalles') }}</li>
        <li style="height: 15px;">{{ translate('Entradas_salidas') }}</li>
      </ul>
      <div>
        <div style="display: flex; flex-direction: column; justify-content: space-between; height: 100%">
          <div style="height: 100%">
            <div style="display: flex; height: 100%">
              <div style="margin-right: 2px;">
                <div style="margin-top: 5px; float:left; clear:both;">
                  {{ translate('Nombre') }}
                  <jqxInput type="text" class="input-search" #nombre [(ngModel)]="edificio.Nombre" [width]="310"
                    [height]="25" (keyup)="onKey($event)"></jqxInput>
                </div>

                <div style="margin-top: 5px; clear: both;">
                  {{ translate('Tipo_zona') }}
                  <jqxDropDownList #cbTiposMaster [width]="310" [height]="25" [autoDropDownHeight]="true">
                  </jqxDropDownList>
                </div>
                <div style="margin-top: 5px; clear: both;">
                  {{ translate('Edificios_instalaciones') }}
                  <jqxDropDownList #cbTiposZonas [width]="310" [height]="25" [selectedIndex]="0"
                    (onSelect)="onChangeTipoZona($event)">
                  </jqxDropDownList>
                </div>
                <div style="margin-top: 5px; clear: both;">
                  {{ translate('Geometria') }}
                  <jqxDropDownList #cbTiposGeo [width]="310" [height]="25" [selectedIndex]="0"
                    [dropDownHeight]="60" (onSelect)="onChangeTipoGeo($event)">
                  </jqxDropDownList>
                </div>
                <div style="margin-top: 5px; float: left; clear: both;">
                  {{ translate('Observaciones') }}
                  <jqxInput #observaciones [(ngModel)]="edificio.Observaciones" [width]="310" [height]="25">
                  </jqxInput>
                </div>

                <div [hidden]="!edificio.Id" style="margin-top: 5px; float: left; clear: both; display: flex;">
                  {{ translate('Texto') }}
                  <jqxCheckBox [theme]="environment.tema" [checked]="edificio.TextoVisible"
                    (onChange)="onCheckTextoChange($event)">
                  </jqxCheckBox>
                </div>

                <div *ngIf="edificio.TipoGeo==0" [hidden]="hiddenRadius">
                  <div style="margin-top: 5px; float:left; clear:both;">
                    {{ translate('Radio_accion_metros') }}
                  </div>
                  <div style="float:left; clear: both;">
                    <jqxNumberInput [(ngModel)]="edificio.Radio" [width]="80" [height]="25" [spinButtons]="false"
                      [min]="0" [max]=5000000 [inputMode]="'simple'" [decimalDigits]="0" [disabled]="!canEdit"
                      (onChange)="onChangeRadius()">
                    </jqxNumberInput>
                  </div>
                </div>
                <div *ngIf="edificio.TipoGeo==1">
                  <div style="margin-top: 5px; float:left; clear:both;">
                    {{ translate('Superficie') }}
                    {{ superficie }} m<sup>2</sup>.
                  </div>
                </div>
              </div>
              <div style="width: 100%; height: 100%">
                <movisat-map [provider]="mapProvider" (mapReady)="onMapReady($event)" [cartoType]="cartoType"
                  [lang]="lang" [searchCountry]="searchCountry" [searchBounds]="searchBounds"
                  (boundsChange)="onBoundsChange($event)" [noSearch]="true" [zoom]="zoom">
                </movisat-map>
              </div>
            </div>
          </div>
          <div>
            <jqxButton style="float:left" [width]='100' [height]='26' [textImageRelation]='"imageBeforeText"'
              [textPosition]='"left"' [imgPosition]="'left'" [imgSrc]='"/assets/images/save.png"'
              [value]="translate('Guardar')" (onClick)="onGuardarZona()">
            </jqxButton>
            <jqxButton style="float: left; margin-left: 3px" [width]="100" [height]="26"
              [textImageRelation]="'imageBeforeText'" [textPosition]="'left'" [imgSrc]="'/assets/images/cancel.png'"
              [imgPosition]="'left'" [value]="translate('CANCELAR')" (onClick)="onCancelar()">
            </jqxButton>
            <jqxButton style="float: left; margin-left: 3px" [width]="100" [height]="26"
              [textImageRelation]="'imageBeforeText'" [textPosition]="'left'" [imgSrc]='"/assets/images/zonas.png"'
              [imgPosition]="'left'" [value]="translate('Dibujar')" (onClick)="onDibujarZona($event)">
            </jqxButton>
          </div>
        </div>
      </div>
      <div>
        <app-edificios-instalaciones-entradas-salidas-list [edificio]="edificio"></app-edificios-instalaciones-entradas-salidas-list>
      </div>
    </jqxTabs>
  </div>
</jqxWindow>
