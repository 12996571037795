import { CatastroModel } from "./catastro.model";

export class DireccionModel {
  id: number = 0;
  idCiudadano: number = 0;
  direccionPrincipal: boolean = false;
  numeroAbonado: string = '';

  barrio: string = '';
  direccion: string = '';
  codigoPostal: string = '';
  poblacion: string = '';
  municipio: string = '';
  provincia: string = '';
  comunidadAutonoma: string = '';
  distrito: string = '';

  numero: string = '';
  planta: string = '';
  puerta: string = '';

  referenciaCatastral: string = '';
  catastro: CatastroModel = new CatastroModel();

  numeroTarjetas: number = 0;

  fechaCreacion: Date;
  fechaModificacion: Date;
  fechaBaja: Date;
}
