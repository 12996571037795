<!-- Loader -->
<div class="loader-container" *ngIf="showLoader">
  <div class="loader"></div>
</div>
<jqxWindow #form [jqxWindowAutoResize] [theme]="environment.tema" [position]="getFormPos(form, 24)" [zIndex]="900"
  [width]="mapWidth" [height]="mapHeight - 24" [autoOpen]="true" [resizable]="true" [showCloseButton]="true"
  [showCollapseButton]="true" (onClose)="onClose()">
  <div class="formHeader" style="overflow-x: hidden;">
    {{translate('Zonas_cartograficas')}}
  </div>
  <div class="form" style="float:left; overflow-x: hidden; ">
    <app-header #header [exportar]="true" [imprimir]="true" [buscador]="true" [filtro]="true" [resetFiltro]="true"
      (eventImprimir)="onPrint($event)" (eventExportar)="onExportar()" (eventBuscador)="onBuscar()"
      (eventFilter)="eventFilter()" (eventResetFilter)="eventResetFilter()">
    </app-header>
    <div style="float:left; width: 100%; height: 100%;">
      <jqxGrid #gridZonas [theme]="environment.tema" [width]="'100%'" [height]="'calc(100% - 80px)'" [appCellClick]
        [source]="dataAdapter" [localization]="langGrid" [filterable]="true" [columns]="columns" [showrowlines]="false"
        [columnsresize]="true" [showcolumnlines]="true" [pageable]="false" [enablebrowserselection]="true"
        [sortable]="true" [altrows]="true" [showstatusbar]="true" [statusbarheight]="20" [showaggregates]="true"
        [rowsheight]="25" [columnsheight]="20" [editable]="true" [groupable]="true" [selectionmode]="'checkbox'"
        (onRowselect)="onChecked($event)" (onRowclick)="onrowClick($event)" (onRowunselect)="onUnselect($event)"
        (onBindingcomplete)="onBindingComplete()" (onRowdoubleclick)="onEditarZona()">
      </jqxGrid>
      <div style="float: left; margin-top: 10px">
        <jqxButton style="float:left" [width]='100' [height]='26' [textImageRelation]='"imageBeforeText"'
          [textPosition]='"left"' [imgSrc]='"/assets/images/ver.png"' [value]="translate('Ver')" (onClick)="onVer()">
        </jqxButton>

        <jqxButton style="float:left;margin-left: 5px" [width]='100' [height]='26'
          [textImageRelation]='"imageBeforeText"' [textPosition]='"left"' [imgSrc]='"/assets/images/zona-fija.png"'
          [value]="translate('Fijar_mapa')" (onClick)="onFijarZonas()">
        </jqxButton>

        <jqxButton style="float: left; margin-left: 5px" [width]="100" [height]="26"
          [textImageRelation]="'imageBeforeText'" [textPosition]="'left'" (onClick)="closeWindow()"
          [value]="translate('Salir')">
        </jqxButton>
      </div>
    </div>
  </div>
</jqxWindow>

<div *ngIf="mostrarEditZona">
  <jqxWindow #formEditZona [jqxWindowAutoResize] [position]="getFormPos(formEditZona, 0)" [width]="mapWidth"
    [isModal]="false" [height]="mapHeight" [zIndex]="900" [autoOpen]="true" [showCloseButton]="true"
    [showCollapseButton]="true" [resizable]="true" (onClose)="onCloseEditZona()" (onOpen)="onOpenEditZona()"
    (onExpand)="onExpandEditZonas($event)">
    <div class="formHeader" style="overflow-x: hidden;">
      {{ translate('Edicion_zonas_cartograficas') }}
    </div>
    <div class="form" style="float:left; overflow-x: hidden;">
      <jqxTabs #tabZonasEdit [width]="'100%'" [height]="'100%'" [position]="'top'" [theme]="theme"
        (onTabclick)="onTabclick($event)">
        <ul>
          <li>I</li>
          <li>II</li>
        </ul>
        <div>
          <div style="display: flex; flex-direction: column; justify-content: space-between; height: 100%">
            <div style="height: 100%">
              <div style="display: flex; height: 100%">
                <div style="margin-right: 2px;">
                  <div style="margin-top: 5px; float:left; clear:both;">
                    {{ translate('Nombre') }}
                  </div>
                  <div style="float:left; clear:both;margin-bottom: 2px">
                    <jqxInput type="text" class="input-search" #nombre [(ngModel)]="zonaEdit.Nombre" [width]="310"
                      [height]="25" [disabled]="!canEdit" (keyup)="onKey($event)"></jqxInput>
                  </div>

                  <div style="margin-top: 5px; clear: both;" [hidden]="hiddenTipoMaster">
                    {{ translate('Tipo_zona') }}
                    <jqxDropDownList #cbTiposMaster [width]="310" [height]="25" [autoDropDownHeight]="true"
                      (onSelect)="onChangeTipoMaster($event)">
                    </jqxDropDownList>
                  </div>
                  <div style="margin-top: 5px; clear: both;" [hidden]="hiddenTipoZonas">
                    {{ translate('Edificios_instalaciones') }}
                    <jqxDropDownList #cbTiposZonas [width]="310" [height]="25" [selectedIndex]="0"
                      (onSelect)="onChangeTipoZona($event)">
                    </jqxDropDownList>
                  </div>
                  <div style="margin-top: 5px; clear: both;" [hidden]="hiddenGeometria">
                    {{ translate('Geometria') }}
                    <jqxDropDownList #cbTiposGeo [width]="310" [height]="25" [selectedIndex]="0"
                      (onSelect)="onChangeTipoGeo($event)">
                    </jqxDropDownList>
                  </div>
                  <div *ngIf="!hiddenInputSearch" class="input-buscar" style="margin-top: 5px; clear: both;">
                    <div>
                      {{ translate('Buscar') }}
                    </div>
                    <div class="autocomplete-container">
                      <form class="form">
                        <mat-form-field style="width: 310px !important;" appearance="fill">
                          <input class="input-search" type="text" matInput [formControl]="myControl"
                            [matAutocomplete]="auto" (input)="onChangeInput($event)">
                          <mat-autocomplete #auto="matAutocomplete" (optionActivated)="onOptionActivated($event)"
                            (optionSelected)="seleccionarArea($event)" [displayWith]=" displayFn">
                            <mat-option *ngFor="let area of filtrarAreas(area)" [value]="area">
                              {{ area }}
                            </mat-option>
                          </mat-autocomplete>
                        </mat-form-field>
                      </form>
                    </div>
                  </div>
                  <div style="margin-top: 5px; float:left; clear:both;">
                    {{ translate('Observaciones') }}
                  </div>
                  <div style="float:left; clear: both;">
                    <jqxInput #observaciones [(ngModel)]="zonaEdit.Observaciones" [width]="310" [height]="25"
                      [disabled]="!canEdit">
                    </jqxInput>
                  </div>

                  <div [hidden]="!zonaEdit.Id">
                    <div style="margin-top: 5px; float:left; clear:both;">
                      {{ translate('Texto') }}
                    </div>
                    <div style="float:left; clear: both;">
                      <jqxCheckBox [disabled]="!canEdit" [theme]="theme" [checked]="false"
                        (onChange)="onCheckTextoChange($event)">
                      </jqxCheckBox>
                    </div>
                  </div>

                  <div style="margin-top: 5px; float:left; clear:both;width: 100%;" [hidden]="hiddenELementContainer">
                    <div>
                      {{ translate('Elemento_seleccionado') }}
                    </div>
                    <div *ngIf="!infoElement" class="element-container">
                      {{ translate('Selecciona_elemento') }}
                    </div>
                    <div class="element-container">
                      <div>
                        <img [src]="infoMarker">
                      </div>
                      <div class="column">
                        <div>
                          {{ infoElement }}
                        </div>
                        <div>
                          {{ infoNombre }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="zonaEdit.TipoGeo==0" [hidden]="hiddenRadius">
                    <div style="margin-top: 5px; float:left; clear:both;">
                      {{ translate('Radio_accion_metros') }}
                    </div>
                    <div style="float:left; clear: both;">
                      <jqxNumberInput [(ngModel)]="zonaEdit.Radio" [width]="80" [height]="25" [spinButtons]="false"
                        [min]="0" [max]=5000000 [inputMode]="'simple'" [decimalDigits]="0" [disabled]="!canEdit"
                        (onChange)="onChangeRadius()">
                      </jqxNumberInput>
                    </div>
                  </div>
                  <div *ngIf="zonaEdit.TipoGeo==1">
                    <div style="margin-top: 5px; float:left; clear:both;">
                      {{ translate('Superficie') }}
                    </div>
                    <div style="float:left; clear: both;">
                      {{ superficie }} m<sup>2</sup>.
                    </div>
                  </div>
                </div>
                <div style="width: 100%; height: 100%">
                  <movisat-map [provider]="mapProvider" (mapReady)="onMapReady($event)" [cartoType]="cartoType"
                    [lang]="lang" [searchCountry]="searchCountry" [searchBounds]="searchBounds"
                    (boundsChange)="onBoundsChange($event)" [noSearch]="true" [zoom]="zoom">
                  </movisat-map>
                </div>
              </div>
            </div>
            <div>
              <jqxButton style="float:left" [width]='100' [height]='26' [textImageRelation]='"imageBeforeText"'
                [textPosition]='"left"' [imgPosition]="'left'" [imgSrc]='"/assets/images/save.png"'
                [value]="translate('Guardar')" (onClick)="onGuardarZona()" [disabled]="!canEdit">
              </jqxButton>
              <jqxButton style="float: left; margin-left: 3px" [width]="100" [height]="26"
                [textImageRelation]="'imageBeforeText'" [textPosition]="'left'" [imgSrc]="'/assets/images/cancel.png'"
                [imgPosition]="'left'" [value]="translate('CANCELAR')" (onClick)="onCancelar()">
              </jqxButton>
              <jqxButton style="float: left; margin-left: 3px" [width]="100" [height]="26"
                [textImageRelation]="'imageBeforeText'" [textPosition]="'left'" [imgSrc]='"/assets/images/zonas.png"'
                [imgPosition]="'left'" [value]="translate('Dibujar')" (onClick)="onDibujarZona($event)">
              </jqxButton>
            </div>
          </div>
        </div>
        <div>
          <app-entrada-salida [editable]="editEntradas"></app-entrada-salida>
        </div>
      </jqxTabs>
    </div>
  </jqxWindow>
</div>

<nz-modal [(nzVisible)]="isVisible" [nzWidth]="380" [nzOkLoading]="isOkLoading" [nzAutofocus]="'ok'" [nzZIndex]="1000"
  nzTitle="Info" (nzOnCancel)="handleCancel()" (nzOnOk)="handleOk()">
  <ng-container *nzModalContent>
    <p>{{ translate('Quiere_borrar_elemento') + ': ' + rowSelectEntradaSalida?.Nombre + '?' }}</p>
  </ng-container>
</nz-modal>
