<div class="loader-container" *ngIf="showLoader">
  <div class="loader"></div>
</div>

<jqxWindow #formWindow [jqxWindowAutoResize] [theme]="environment.tema" [position]="getFormPos(formWindow, 24)" [width]="mapWidth"
  [height]="mapHeight - 24" [zIndex]="900" [autoOpen]="true" [showCloseButton]="true" [showCollapseButton]="true"
  [resizable]="true" [title]="translate('Rango')" (onClose)="onClose()">
  <div class="formHeader" style="overflow-x: hidden;">
  </div>

  <div class="form" style="float: left; overflow-x: hidden;">
    <div class="row" style="height: calc(100% - 20px); overflow-y: auto; overflow-x: hidden;">
      <span style="width: 100%;">
        <div style="height: 100%; width: 100%;">
          <fieldset class="row">
            <div style="display: flex; justify-content: flex-start; width: 50%;">
              <div style="display: flex; flex-direction: column; width: 100%;">
                <span style="width: 100%; clear: both;">
                  <div style="margin-top: 5px">
                    {{ translate('Descripcion') }}
                  </div>
                  <jqxInput [(ngModel)]="rango.nombre" [width]="'100%'">
                  </jqxInput>
                </span>

                <div class="row">
                  <span>
                    <div style="margin-top: 5px">
                      {{ translate('Tarjeta_inicial') }} (ID)
                    </div>
                    <jqxInput [value]="rango.idTarjetaInicial" [width]="'50%'" [disabled]="true" title="{{rango.idTarjetaInicial}}">
                    </jqxInput>
                  </span>

                  <span style="width: 100%;">
                    <div style="display: flex; margin-top: 5px; justify-content: center;">
                      {{ translate('Cantidad') }}
                    </div>
                    <jqxSlider #sliderRango style="margin-top: -17.22px;" [min]="0" [max]="1" [tooltip]="true" [tickLabelFormatFunction]='tickLabelFormatFunction'
                      [showMinorTicks]="false" [minorTicksFrequency]="1" [height]="60" [showTickLabels]="false" [width]="'100%'" [rangeSlider]="true"
                      [mode]="'fixed'" (onChange)="changeSliderRango($event)">
                    </jqxSlider>
                  </span>

                  <span>
                    <div style="margin-top: 5px">
                      {{ translate('Tarjeta_final') }} (ID)
                    </div>
                    <jqxInput [value]="rango.idTarjetaFinal" [width]="'50%'" [disabled]="true" title="{{rango.idTarjetaFinal}}">
                    </jqxInput>
                  </span>
                </div>

                <div class="row">
                  <span style="width: 100%; clear: both;">
                    <div style="margin-top: 5px">
                      {{ translate('Listado') }}
                    </div>

                    <div class="rowC">
                      <span>
                        <jqxRadioButton (onChange)="setLista($event, 'blanca')"
                        [width]="250" [height]="25" [checked]="rango.listaBlanca">
                        <span>{{ translate('Blanca') }}</span>
                        </jqxRadioButton>
                      </span>

                      <span>
                        <jqxRadioButton (onChange)="setLista($event, 'master')"
                        [width]="250" [height]="25" [checked]="rango.listaMaster">
                        <span>{{ translate('Master') }}</span>
                        </jqxRadioButton>
                      </span>

                      <span>
                        <jqxRadioButton (onChange)="setLista($event, 'negra')"
                        [width]="250" [height]="25" [checked]="rango.listaNegra">
                        <span>{{ translate('Negra') }}</span>
                        </jqxRadioButton>
                      </span>
                    </div>
                  </span>
                </div>
              </div>
            </div>

            <div id="asignacionZonas">
              <div style="margin-top: 5px">
                {{ translate('Zonas') }}
              </div>

              <div class="row">
                <div style="width: 100%; height: 100%;">
                  <jqxGrid #gridZonas [theme]="environment.tema" [width]="'100%'" [height]="'100%'" [filterrowheight]='30'
                    [source]="adapterZona" [columnsresize]="false" [localization]="langGrid" [filterable]="true" [showfilterrow]="true"
                    [columns]="columnsZonas" [showrowlines]="false" [showcolumnlines]="true" [pageable]="false" [sortable]="true"
                    [altrows]="true" [showstatusbar]="false" [statusbarheight]="0" [enablebrowserselection]="true" [showaggregates]="false"
                    [rowsheight]="15" [columnsheight]="20" [enabletooltips]="false" [editable]="false" [groupable]="false" [selectionmode]="'singlerow'"
                    (onRowdoubleclick)="associateZonaTarjeta()">
                  </jqxGrid>
                </div>

                <div id="botonesAsignacion" style="width: 10%;">
                  <jqxButton class="button" style="cursor: pointer;" [width]="25" [height]="25" [imgHeight]="15"
                    [imgWidth]="15" [imgSrc]='"/assets/images/derecha.png"' [imgPosition]="'center'"
                    (onClick)="associateZonaTarjeta()">
                  </jqxButton>
                  <jqxButton class="button" style="cursor: pointer;" [width]="25" [height]="25" [imgHeight]="15"
                    [imgWidth]="15" [imgSrc]='"/assets/images/izquierda.png"' [imgPosition]="'center'"
                    (onClick)="disassociateZonaTarjeta()">
                  </jqxButton>
                </div>

                <div style="width: 100%;">
                  <jqxGrid #gridZonasToAsign [theme]="environment.tema" [width]="'100%'" [height]="'100%'" [filterrowheight]='30'
                    [source]="adapterZonaToAsign" [columnsresize]="false" [localization]="langGrid" [filterable]="true" [showfilterrow]="true"
                    [columns]="columnsZonas" [showrowlines]="false" [showcolumnlines]="true" [pageable]="false" [sortable]="true"
                    [altrows]="true" [showstatusbar]="false" [statusbarheight]="0" [enablebrowserselection]="true" [showaggregates]="false"
                    [rowsheight]="15" [columnsheight]="20" [enabletooltips]="false" [editable]="false" [groupable]="false" [selectionmode]="'singlerow'"
                    (onRowdoubleclick)="disassociateZonaTarjeta()">
                  </jqxGrid>
                </div>
              </div>
            </div>
          </fieldset>
        </div>
      </span>
    </div>

    <div style="position: absolute; bottom: 5px; left: 0px; margin-top: 5px;
      clear: both;">
      <jqxButton style="float: left; margin-left: 5px" [width]="100" [height]="26"
        [textImageRelation]="'imageBeforeText'" [textPosition]="'left'" [imgSrc]="'/assets/images/save.png'"
        (onClick)="onGuardar()" [value]="translate('Guardar')">
      </jqxButton>

      <jqxButton style="float: left; margin-left: 5px" [width]="100" [height]="26"
        [textImageRelation]="'imageBeforeText'" [textPosition]="'left'"
        (onClick)="closeWindow()" [value]="translate('CANCELAR')">
      </jqxButton>
    </div>
  </div>
</jqxWindow>
