import { jqxGridComponent } from "jqwidgets-ng/jqxgrid";
import { environment } from "src/environments/environment";

export class Utils {
  static clone<T>(a: T): T {
    return JSON.parse(JSON.stringify(a));
  }

  static sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  // ajusta el tamaño de las columnas del grid
  static renderSizeGrid(grid: jqxGridComponent, timeout = 100, exclude = []) {
    const t = setTimeout(() => {
      if (grid == null) return;
      grid.attrColumns.forEach((column: any) => {
        try {
          if (!exclude.includes(column.datafield)) {
            grid.autoresizecolumn(column.datafield, column.columntype);
          }
        } catch (e) {
        }
      })
    }, timeout);
  }

  // quita los elementos duplicados del array y lo devuelve sin undefined
  static onChangeArray(list: any[]): any[] {
    list = list.filter((elem, index, self) =>
      index == self.findIndex((t) => t?.Id == elem?.Id) && elem !== undefined && elem !== null
    );
    return list;
  }

  static tooltiprenderer(element, tooltipMessage?) {
    if (element && element.context) { // Check to ensure element and element.context are defined
      const jqElement = element.context.parentElement;
      const options = {
        content: tooltipMessage ? tooltipMessage : element.text(), // Corrected the conditional statement
        theme: environment.tema,
        width: 'auto',
        height: 'auto',
        position: 'mouse',
        autoHide: true,
      };
      jqwidgets.createInstance(jqElement, 'jqxTooltip', options);
    }
  }

  static updatefilterconditions(type: string, defaultconditions: any): string[]{
    let stringcomparisonoperators = ['EMPTY', 'NOT_EMPTY', 'CONTAINS',
      'DOES_NOT_CONTAIN', 'STARTS_WITH', 'ENDS_WITH', 'EQUAL'];
    switch (type) {
      case 'stringfilter':
        return stringcomparisonoperators;
      case 'numericfilter':
        return defaultconditions;
      case 'datefilter':
        return defaultconditions;
      case 'booleanfilter':
        return defaultconditions;
    }
  }

  static filterRow(cellValue: any, dataField: string, filterGroup: any, defaultFilterResult: boolean, filterColumns: string[]) {
    if (filterColumns.includes(dataField)) {
      let filters = filterGroup.getfilters();
      if (cellValue != "" && cellValue != null) {
        let exists = true;
        for (let i = 0; i < filters.length; i++) {
          let filter = filters[i];
          let filterValue = filter.value;

          let filterCondition = filter.condition;
          let filterOperator = filter.operator;

          if(i === 0 || filterOperator === 1 || exists){
            switch(filterCondition) {
              case 'EMPTY':
                if(cellValue === null || cellValue === '') {
                  exists = true;
                }else {
                  if(filterOperator === 0) {
                    exists = false;
                  }
                }
                break;
              case 'NOT_EMPTY':
                if(cellValue !== null || cellValue !== '') {
                  exists = true;
                }else {
                  if(filterOperator === 0) {
                    exists = false;
                  }
                }
                break;
              case 'CONTAINS':
                if (cellValue.compareWith(filterValue)) {
                  exists = true;
                }else {
                  if(filterOperator === 0) {
                    exists = false;
                  }
                }
                break;
              case 'DOES_NOT_CONTAIN':
                if (!(cellValue.compareWith(filterValue))) {
                  exists = true;
                }else {
                  if(filterOperator === 0) {
                    exists = false;
                  }
                }
                break;
              case 'STARTS_WITH':
                if (cellValue.normaliceAccents().toUpperCase().startsWith(filterValue.normaliceAccents().toUpperCase())) {
                  exists = true;
                }else {
                  if(filterOperator === 0) {
                    exists = false;
                  }
                }
                break;
              case 'ENDS_WITH':
                if (cellValue.normaliceAccents().toUpperCase().endsWith(filterValue.normaliceAccents().toUpperCase())) {
                  exists = true;
                }else {
                  if(filterOperator === 0) {
                    exists = false;
                  }
                }
                break;
              case 'EQUAL':
                if (cellValue.equalsTo(filterValue)) {
                  exists = true;
                }else {
                  if(filterOperator === 0) {
                    exists = false;
                  }
                }
                break;
              default:
                break;
            }
          }
        }
        return exists;
      }else {
        return defaultFilterResult;
      }
    }
  }

  static generateAutofilterHeader(sheet) {
    // Añade filtro a todas las casillas.
    sheet['!autofilter'] = { ref: sheet['!ref'] };
  }
}
