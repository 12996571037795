import {
  OnInit,
  Component,
  ViewChild,
  AfterViewInit,
  ViewContainerRef,
} from '@angular/core';

import {
  MapBounds,
  MapCircle,
  MapLatLng,
  MapPolygon,
  MapComponent,
} from 'movisat-maps';

import * as xlsx from 'xlsx';
import { Utils } from 'src/app/utils/utils';
import { GeoUtils } from 'src/app/utils/geo-utils';
import { NzModalService } from 'ng-zorro-antd/modal';
import { DateUtils } from 'src/app/utils/date-utils';
import { CustomForms } from '../../forms/custom-forms';
import { NumberUtils } from 'src/app/utils/number-utils';
import { environment } from 'src/environments/environment';

import { AppComponent } from 'src/app/app.component';
import { MainComponent } from '../../main/main.component';
import { HeaderComponent } from '../../header/header.component';

import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';

import { SsoService } from 'src/app/services/sso/sso.service';
import { ZonesService } from 'src/app/services/zones/zones.service';
import { ConfigService } from 'src/app/services/config/config.service';

import { ZonaModel } from 'src/app/services/zones/models/zona.model';

import { EdificiosInstalacionesEditComponent } from '../edificios-instalaciones-edit/edificios-instalaciones-edit.component';

@Component({
  selector: 'app-edificios-instalaciones-list',
  templateUrl: './edificios-instalaciones-list.component.html',
  styleUrls: ['./edificios-instalaciones-list.component.css']
})
export class EdificiosInstalacionesListComponent extends CustomForms implements OnInit, AfterViewInit {
  @ViewChild('windowEdificios') form: jqxWindowComponent;
  @ViewChild('header') header: HeaderComponent;
  @ViewChild('gridEdificios') gridEdificios: jqxGridComponent;
  @ViewChild('windowEdificios') windowEdificios: jqxWindowComponent;
  @ViewChild('formEdificiosInstalaciones', { read: ViewContainerRef }) editEdificiosComponent;

  formEdificiosInstalaciones: any;
  zonasFijas: any = [];

  public static _this: EdificiosInstalacionesListComponent;

  superficie: any;

  polygon: MapPolygon = null;
  public circle: MapCircle = null;

  circlesMap: MapCircle[] = [];
  circlesMapEdit: MapCircle[] = [];

  polygonsMap: any[] = [];
  polygonsMapEdit: any[] = [];

  polygonGeoJson = null;
  polygonGeoJsonMapEdit = null;

  protected showLoader = false;
  protected mapWidth: number = 1000;
  protected mapHeight: number = 450;

  private map: MapComponent;
  private componentRef = null;
  protected environment = environment;
  protected langGrid = JqWidgets.getLocalization('es');

  protected sourceGridEdificios;
  protected columnsGridEdificios: any = [];
  protected sourceGridEdificiosAdapter;

  protected edificios: ZonaModel[] = [];
  private checkSelect: ZonaModel[] = [];

  constructor(
    private modal: NzModalService,
    private ssoService: SsoService,
    private zonesService: ZonesService,
    private configService: ConfigService,
  ) {
    super();
    EdificiosInstalacionesListComponent._this = this;
  }

  protected translate(text: string): string {
    return AppComponent.translate(text);
  }

  async ngOnInit(): Promise<void> {
    this.map = MainComponent.getInstance().getActiveMap();

    this.mapWidth = document.getElementById('map-container').offsetWidth;
    this.mapHeight = document.getElementById('map-container').offsetHeight;

    this.langGrid = JqWidgets.getLocalization(this.ssoService.getTicket().Usuario.Idioma.Codigo.substring(0, 2));

    this.zonasFijas = JSON.parse(await this.configService.getUsuEmpApp('zones-fixed', null));
    if (!this.zonasFijas) {
      this.zonasFijas = [];
    }
    if (this.zonasFijas && Array.isArray(this.zonasFijas)) {
      this.zonasFijas = this.zonasFijas.filter((zona, index, self) =>
        index === self.findIndex((t) => (
          t.id === zona.id
        ))
      );
    }
  }

  ngAfterViewInit(): void {
    this.form.setTitle(AppComponent.translate('Edificios_e_instalaciones'));
    this.addCustomForm(this.windowEdificios);
  }

  onClose() {
    this.deleteZonesMap();

    if (this.componentRef) {
      this.componentRef.destroy();
    }
    EdificiosInstalacionesListComponent._this = null;
  }

  init() {
    this.getEdificiosInstalaciones();
  }

  async getEdificiosInstalaciones() {
    this.showLoader = true;
    try {
      /* Obtengo las edificios que son tipo edificio instalacion */
      this.zonesService.getEdificiosInstalaciones().then(
        async (response: ZonaModel[]) => {
          this.edificios = response;
          this.initGridEdificios();
        }
      );
    } catch (error) {
      this.showLoader = false;
    }
  }

  initGridEdificios() {
    this.columnsGridEdificios = [
      { text: '', columntype: 'textbox', filtertype: 'textbox', datafield: 'selec', hidden: true },
      {
        text: '',
        width: 100,
        columntype: 'text',
        sortable: false,
        editable: false,
        groupable: false,
        menu: false,
        clickable: false,
        filterable: false,
        resizable: false,
        rendered: (columnHeaderElement) => {
          const buttonContainer = document.createElement('div');
          buttonContainer.style.width = '100%';
          buttonContainer.style.display = 'flex';
          buttonContainer.style.justifyContent = 'center';

          buttonContainer.id = `buttonContainerColumn_jqxButton`;
          columnHeaderElement[0].appendChild(buttonContainer);
          const btnCrear = document.createElement('div');
          btnCrear.innerHTML =
            `<button class="button" style="height: 20px; width: 30%; display: flex; justify-content: center;"
              title="${AppComponent.translate('Crear')}">
              <i class="fa-solid fa-plus"></i>
            </button>`;
          btnCrear.id = `buttonCrear_jqxButton`;
          btnCrear.style.width = '100%';
          btnCrear.style.height = '100%';
          btnCrear.style.display = 'flex';
          btnCrear.style.justifyContent = 'center';
          btnCrear.addEventListener('click', async (event: any) => {
            this.onCrearZona();
          });
          buttonContainer.appendChild(btnCrear);
          return columnHeaderElement[0];
        },
        createwidget: (
          row: any,
          column: any,
          value: string,
          htmlElement: HTMLElement
        ): void => {
          this.initBtnColumn(row, column, value, htmlElement);
        },
        initwidget: (
          row: any,
          column: any,
          value: string,
          htmlElement: HTMLElement
        ) => {
          this.initBtnColumn(row, column, value, htmlElement);
        },
      },
      {
        text: AppComponent.translate('Texto'),
        columntype: 'checkbox',
        filtertype: 'list',
        datafield: 'textoVisible',
        width: 52,
        filteritems: ['Si', 'No'],
        cellvaluechanging: (row, column, columntype, oldvalue, newvalue) => {
          let fila;
          if (row.boundindex) {
            fila = row.boundindex;
          } else {
            fila = row;
          }
          //busco en el array de columnas el que coincida con el id
          let data = this.gridEdificios.getrowdata(fila);
          let found = this.edificios.find((edificio) => edificio.Id == data.id);

          switch (found.TipoGeo) {
            case 0:
              // modifico el circulo en el mapa
              this.map['circleList'].forEach(circle => {
                if (circle.dataModel.Id == found.Id) {
                  circle.setContent(newvalue ? found.Nombre : '');
                }
              });
              break;
            case 1:
              this.map['polygonList'].forEach(polygon => {
                if (polygon['dataModel']) {
                  const objeto = polygon['dataModel'];
                  Object.keys(objeto).forEach(key => {
                    let id = objeto[key].Id;
                    if (id === found.Id) {
                      polygon.setContent(newvalue ? found.Nombre : '');
                    }
                  });
                }
              })
              break;
          }
          this.saveFilter();
          return newvalue;
        }
      },
      { text: AppComponent.translate('CODIGO'), columntype: 'textbox', datafield: 'id', editable: false, hidden: true },
      { text: AppComponent.translate('Nombre'), columntype: 'textbox', datafield: 'nombre', editable: false, width: 150 },
      { text: AppComponent.translate('Tipo'), columntype: 'textbox', datafield: 'tipo', editable: false, width: 130 },
      { text: AppComponent.translate('Domicilio'), columntype: 'textbox', datafield: 'domicilio', editable: false, width: 230 },
      { text: AppComponent.translate('Poblacion'), columntype: 'textbox', datafield: 'poblacion', editable: false, width: 80 },
      { text: AppComponent.translate('Provincia'), columntype: 'textbox', datafield: 'provincia', editable: false, width: 130 },
      { text: AppComponent.translate('Geometria'), columntype: 'textbox', datafield: 'geometria', editable: false, cellsrenderer: this.renderGeometria, width: 90,
        filtertype: 'checkedlist',
        filteritems: [
          AppComponent.translate('Circulo'),
          AppComponent.translate('Poligono')
        ],
      },
      { text: AppComponent.translate('Radio'), columntype: 'textbox', datafield: 'radio', editable: false, cellsrenderer: this.renderNumero, width: 50 },
      { text: AppComponent.translate('Superficie'), columntype: 'textbox', datafield: 'superficie', editable: false, cellsrenderer: this.renderNumero, width: 70 },
      { text: AppComponent.translate('Observaciones'), columntype: 'textbox', datafield: 'observaciones', editable: false, width: 180 },
    ];

    this.sourceGridEdificios = {
      datatype: 'json',
      datafields: [
        { name: 'selec', type: 'string', map: 'selec' },
        { name: 'textoVisible', type: 'bool', map: 'TextoVisible' },
        { name: 'id', type: 'number', map: 'Id' },
        { name: 'nombre', type: 'string', map: 'Nombre' },
        { name: 'tipo', type: 'string', map: 'TipoBDTString' },
        { name: 'domicilio', type: 'string', map: 'Domicilio' },
        { name: 'poblacion', type: 'string', map: 'Poblacion' },
        { name: 'provincia', type: 'string', map: 'Provincia' },
        { name: 'geometria', type: 'number', map: 'TipoGeo' },
        { name: 'radio', type: 'number', map: 'Radio' },
        { name: 'superficie', type: 'number', map: 'Area' },
        { name: 'observaciones', type: 'string', map: 'Observaciones' },
      ],
      localdata: this.edificios
    };

    this.sourceGridEdificiosAdapter = new jqx.dataAdapter(this.sourceGridEdificios);
    this.showLoader = false;
  }

  onFilter(event) {
    // Itero a través de todos los filtros aplicados
    for (let filterObj of event.args.filters) {
      let column = filterObj.datafield;
      let filter = filterObj.filter.getfilters();

      if (column === 'geometria') {
        let filter1;
        filter.forEach(filt => {
          let filtergroup = new jqx.filter();
          let filter_operator = filt.operator;
          let filtercondition = filt.condition;
          let filtervalue;

          if (filt.value === this.translate('Circulo') && filtercondition !== 'NOT_EQUAL') {
            filtervalue = 0;
          } else if (filt.value === this.translate('Poligono') && filtercondition !== 'NOT_EQUAL') {
            filtervalue = 1;
          }

          if (filtervalue != undefined) {
            filter1 = filtergroup.createfilter('booleanfilter', filtervalue, filtercondition);
            filtergroup.addfilter(filter_operator, filter1);
            this.gridEdificios.addfilter(column, filtergroup);
            this.gridEdificios.applyfilters();

            filter1 = filtergroup.createfilter('stringfilter', filt.value, filtercondition);
            filtergroup.addfilter(filter_operator, filter1);
            this.gridEdificios.addfilter(column, filtergroup);
          }
        });
      }
    }
  }

  updatefilterconditions = (type: string, defaultconditions: any): string[] => {
    return Utils.updatefilterconditions(type, defaultconditions);
  };

  public updateGridEdificios(edificio: ZonaModel) {
    for (let i = 0; i < this.edificios.length; i++) {
      if (this.edificios[i].Id === edificio.Id) {
        this.edificios[i] = Utils.clone(edificio);
        break;
      }
    }

    this.sourceGridEdificios.localdata = this.edificios;
    this.gridEdificios.updatebounddata('data');
  }

  onBindingComplete() {
    // solo entra una vez
    if (this.gridEdificios) {
      // Verificar si la zona está fija o seleccionada
      this.zonasFijas.forEach(zonaFija => {
        let row = this.gridEdificios.getrows().find(row => row.id === zonaFija.id);

        if (row) {
          this.gridEdificios.selectrow(row.boundindex);
          if(zonaFija.textoVisible) {
            this.gridEdificios.setcellvalue(row.boundindex, 'textoVisible', true);
          }
        }
      })
    }
  }

  public async saveFilter() {
    let edificios: any[] = [];

    let rowsSelec: any = this.gridEdificios.getselectedrowindexes();
    // obtengo las filas del grid que tienen la propiedad textoVisible a true
    setTimeout(async () => {
      let rowsConTexto = await this.getCheckConTexto();
      if (rowsSelec.length !== 0) {
        rowsSelec.forEach(i => {
          edificios.push({ id: this.edificios[i].Id });
        });

        rowsConTexto.forEach(row => {
          if (edificios.find(edificio => edificio.id == row.Id) !== undefined) {
            return edificios.find(edificio => edificio.id == row.Id).TextoVisible = row.TextoVisible;
          }
        });
      }
      // Guardo el filtro de edificios visibles
      await this.configService.setUsuEmpApp('zones-fixed', JSON.stringify(edificios));
      // Notifico que se ha cambiado el filtro de edificios visibles
      this.zonesService.loadZones();
      this.zonesService.setFilterVisible();
    }, 200);
  }

  encuadrarEdificioSelec(edificios: ZonaModel[]) {
    let globalSWPoint = new MapLatLng(180, 90);
    let globalNEPoint = new MapLatLng(-180, -90);
    // Procesa cada edificio
    edificios.forEach(edif => {
      let swPoint, nePoint;
      if (edif.TipoGeo === 0) { // Si es un círculo
        let center = new MapLatLng(edif.Lat, edif.Lng);
        swPoint = GeoUtils.getNewLatLng(center, -edif.Radio, -edif.Radio);
        nePoint = GeoUtils.getNewLatLng(center, edif.Radio, edif.Radio);
      } else { // Si es un polígono
        let bounds = this.calculatePolygonBounds(edif.Geometria); // Devuelve un objeto con swPoint y nePoint
        swPoint = bounds.swPoint;
        nePoint = bounds.nePoint;
      }
      globalSWPoint.lat = Math.min(globalSWPoint.lat, swPoint.lat);
      globalSWPoint.lng = Math.min(globalSWPoint.lng, swPoint.lng);
      globalNEPoint.lat = Math.max(globalNEPoint.lat, nePoint.lat);
      globalNEPoint.lng = Math.max(globalNEPoint.lng, nePoint.lng);
    });
    this.map.fitTo(new MapBounds(globalSWPoint, globalNEPoint));
  }

  centeredPolygons(geoJson) {
    let arrLat = [];
    let arrLng = [];
    let arrayCoordenadas = geoJson.geometry.coordinates;
    arrayCoordenadas.forEach(polygon => {
      polygon[0].forEach(coord => {
        arrLat.push(coord[1]);
        arrLng.push(coord[0]);
      });
    });

    //calcular máximos y minimos para centar ppolígono en el mapa
    let arrLatMax = Math.max(...arrLat);
    let arrLatMin = Math.min(...arrLat);
    let arrLngmax = Math.max(...arrLng);
    let arrLngMin = Math.min(...arrLng);
    let coordMax = new MapLatLng(arrLatMax, arrLngmax);
    let coordMin = new MapLatLng(arrLatMin, arrLngMin);
    let mapBounds = new MapBounds(coordMin, coordMax);

    this.map.fitTo(mapBounds);
  }

  calculatePolygonBounds(geoJson) {
    let arrLat = [];
    let arrLng = [];
    let arrayCoordenadas = geoJson.geometry.coordinates;
    arrayCoordenadas.forEach(polygon => {
      polygon[0].forEach(coord => {
        arrLat.push(coord[1]);
        arrLng.push(coord[0]);
      });
    });
    let swPoint = new MapLatLng(Math.min(...arrLat), Math.min(...arrLng));
    let nePoint = new MapLatLng(Math.max(...arrLat), Math.max(...arrLng));
    return { swPoint, nePoint };
  }

  async getCheckConTexto() {
    if (this.gridEdificios) {
      let rows: any = this.gridEdificios.getrows();
      let rowsConTexto: any = [];
      if (rows) {
        rows.forEach(row => {
          if (row.textoVisible) {
            rowsConTexto.push({ Id: row.id, TextoVisible: row.textoVisible });
          }
        });
      }
      return rowsConTexto;
    }
  }

  /* RENDERIZADOS COLUMNA ACCIONES Y TEXTOS GRID */
  async initBtnColumn(
    row: any,
    column: any,
    value: string,
    htmlElement: HTMLElement
  ) {
    htmlElement.innerHTML = '';

    let rowdata;
    if (isNaN(row)) {
      rowdata = row.bounddata;
    } else {
      rowdata = this.gridEdificios.getrowdata(row);
    }
    let edificio = this.edificios.find(edificio => edificio.Id == rowdata.id);

    const btnContainer = document.createElement('div');
    btnContainer.style.display = 'flex';
    btnContainer.style.justifyContent = 'start';
    btnContainer.style.gap = '2px';
    btnContainer.style.padding = '2px';

    const btnEditar = document.createElement('div');
    btnEditar.innerHTML = `
      <button class="button" style="height: 23px; width: 25px; padding: 0; margin: 0; cursor: pointer !important;" title="`+ AppComponent.translate('Editar') + `">
        <i class="fa-solid fa-pen-to-square"></i>
      </button>
    `;
    btnEditar.id = `buttonEdite_jqxButton`;
    btnContainer.appendChild(btnEditar);

    btnEditar.addEventListener('click', async (event: any) => {
      this.onEditarEdificio(edificio);
    });

    const btnCentrar = document.createElement('div');
    btnCentrar.innerHTML = `
      <button class="button" style="height: 23px; width: 25px; padding: 0; margin: 0; cursor: pointer !important;" title="`+ AppComponent.translate('Centrar_mapa') + `">
        <i class="fa-solid fa-location-dot"></i>
      </button>
    `;
    btnCentrar.id = `buttonCenter_jqxButton`;
    btnContainer.appendChild(btnCentrar);

    btnCentrar.addEventListener('click', async (event: any) => {
      this.onVer(true, edificio);
    });

    const btnDelete = document.createElement('div');
    btnDelete.innerHTML = `
      <button class="button" style="height: 23px; width: 25px; padding: 0; margin: 0; cursor: pointer !important;" title="`+ AppComponent.translate('Borrar') + `">
        <i class="fa-solid fa-trash"></i>
      </button>
    `;
    btnDelete.id = `buttonDelete_jqxButton`;
    btnContainer.appendChild(btnDelete);

    btnDelete.addEventListener('click', async (event: any) => {
      this.onBorrarZona(edificio);
    });

    htmlElement.appendChild(btnContainer);
  }

  renderGeometria(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any
  ): string {
    switch (value) {
      case 0:
        return (
          `<div class="jqx-grid-cell-left-align" style="margin-top: 4px">
            ${AppComponent.translate('Circulo')}
          </div>`
        );
        break;
      case 1:
        return (
          `<div class="jqx-grid-cell-left-align" style="margin-top: 4px">
            ${AppComponent.translate('Poligono')}
          </div>`
        );
        break;
      default:
        return (
          `<div class="jqx-grid-cell-left-align" style="margin-top: 4px">
          </div>`
        );
    }
  }

  renderNumero(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any
  ): string {
    if ((value !== null || value !== undefined) && value !== 0) {
      return (
        `<div class="jqx-grid-cell-right-align" style="margin-top: 4px">
          ${NumberUtils.format(value, 0)}
        </div>`
      );
    } else {
      return (
        `<div class="jqx-grid-cell-right-align" style="margin-top: 4px">
        </div>`
      );
    }
  }

  /* FUNCIONALIDAD BOTONES INFERIORES */
  closeWindow() {
    this.windowEdificios.close();
  }

  async onVer(isColumn: boolean, edificio = null) {
    if (isColumn) {
      let edificios = [];

      if (edificio) {
        edificios[0] = edificio;
        this.drawZone(edificio);
        this.encuadrarEdificioSelec(edificios);
      }

      this.windowEdificios.collapse();
    } else {
      this.encuadrarEdificioSelec(this.checkSelect);

      this.checkSelect.forEach(edif => {
        this.drawZone(edif);
      });

      this.saveFilter();
    }
  }

  async drawZone(edificio: ZonaModel) {
    // Pinto sobre el mapa la zona
    this.deleteZonesMap();

    let showTexto = false;
    let rowsConTexto = await this.getCheckConTexto();

    rowsConTexto.forEach(row => {
      if(row.Id === edificio.Id) {
        showTexto = true;
      }
    });

    if (edificio.TipoGeo === 0) { // Círculo
      if (edificio.Lat > 0) {
        edificio.Geometria = {};
        this.circle = this.map.addCircle({
          dataModel: edificio,
          content: showTexto ? edificio.Nombre : '',
          strokeColor: '#ff0000',
          strokeOpacity: 0.3,
          strokeWeight: 1,
          fillColor: '#0000ff',
          fillOpacity: 0.1,
          position: new MapLatLng(edificio.Lat, edificio.Lng),
          radius: Number(edificio.Radio),
          draggable: false,
          editable: false
        });
        this.circlesMap.push(this.circle);
      }
    } else { // Polígono
      this.superficie = NumberUtils.format(edificio.Area, 0);
      //cargar polígono en el mapa a partir de un geojson
      this.polygon = this.map.addPolygonsFromGeoJson(edificio.Geometria, false, {
        dataModel: edificio,
        content: showTexto ? edificio.Nombre : '',
        strokeColor: '#ff0000',
        strokeOpacity: 0.3,
        strokeWeight: 1,
        fillColor: '#0000ff',
        fillOpacity: 0.1,
        zIndex: 100,
      })[0];
      this.polygonsMap.push(this.polygon);
    }
  }

  onBorrarZona(edificio: ZonaModel) {
    this.modal.confirm({
      nzTitle: '<i>' + AppComponent.translate('ATENCION') + '</i>',
      nzContent: AppComponent.translate('Quieres_borrar_zonas') + ':' + edificio.Nombre + ' ?',
      nzCentered: true,
      nzCancelText: AppComponent.translate('CANCELAR'),
      nzOkText: AppComponent.translate('SI'),
      nzZIndex: 999,
      nzOnOk: async () => {
        MainComponent.getInstance().showSuccess('ATENCION', 'Registro_borrado', 2000);
        await this.zonesService.deleteZona(edificio.id);

        this.deleteZonesMap();
        this.checkSelect = [];
        this.sourceGridEdificios.localdata = this.edificios;
        this.gridEdificios.updatebounddata();
        this.gridEdificios.unselectrow(this.gridEdificios.getselectedrowindex());
      }
    });
  }

  onCrearZona() {
    this.windowEdificios.collapse();
    this.formEdificiosInstalaciones = this.editEdificiosComponent.createComponent(EdificiosInstalacionesEditComponent);
    this.formEdificiosInstalaciones.instance.init(this.formEdificiosInstalaciones);
  }

  /* FUNCIONALIDAD GRID EDIFICIOS */
  onEditarEdificio(edificio: ZonaModel) {
    this.windowEdificios.collapse();
    this.formEdificiosInstalaciones = this.editEdificiosComponent.createComponent(EdificiosInstalacionesEditComponent);
    this.formEdificiosInstalaciones.instance.init(this.formEdificiosInstalaciones, edificio);
  }

  onChecked() {
    let idRow: number[] = this.gridEdificios.getselectedrowindexes();
    let rows: any[] = this.gridEdificios.getrows();
    this.checkSelect = [];
    rows.forEach((element, index) => {
      if (idRow.includes(element.boundindex)) {
        let edificio = this.edificios.find((edif) => edif.Id == element.id);
        this.checkSelect.push(edificio);
      }
    });
    this.onVer(false);
  }

  onDoubleClick(event: any){
    if(event){
      let edificio = this.edificios.find(edificio => edificio.Id == event.args.row.bounddata.id);
      this.onEditarEdificio(edificio)
    }
  }

  onUnselect(event: any) {
    if (event.args.row) {
      this.onVer(false);
    }
  }

  /* FUNCIONALIDAD CABECERA */
  async eventFilter() {
    this.header.searchInput['nativeElement'].value = '';
    this.getEdificiosInstalaciones();
  }

  async eventResetFilter() {
    this.header.searchInput['nativeElement'].value = '';
    this.onBuscar();
  }

  onBuscar() {
    let filtervalue = '';

    if (this.header.searchInput['nativeElement'].value.length >= 3) {
      filtervalue = this.header.searchInput['nativeElement'].value.toUpperCase();
    } else {
      filtervalue = '';
    }

    this.edificios.forEach(zon => {
      if (
        zon?.Nombre?.compareWith(filtervalue) ||
        zon?.TipoBDTString?.compareWith(filtervalue) ||
        zon?.Domicilio?.compareWith(filtervalue) ||
        zon?.Poblacion?.compareWith(filtervalue) ||
        zon?.Provincia?.compareWith(filtervalue) ||
        (AppComponent.translate('Circulo').compareWith(filtervalue) && zon?.TipoGeo === 0) ||
        (AppComponent.translate('Poligono').compareWith(filtervalue) && zon?.TipoGeo === 1) ||
        (zon?.Radio + '').toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (zon?.Area + '').toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        zon?.Observaciones?.toUpperCase().indexOf(filtervalue.toUpperCase()) > -1
      ) {
        zon['selec'] = 'selec';
      } else {
        zon['selec'] = '';
      }
    });

    // Compruebo si ya he creado el filtro "selec" anteriormente
    const filters = this.gridEdificios.getfilterinformation();
    if (filters.find(s => s.datafield === 'selec') === undefined) {
      const filtergroup = new jqx.filter();
      filtergroup.operator = 'and';
      filtergroup.addfilter(0, filtergroup.createfilter('stringfilter', 'selec', 'equal'));
      this.gridEdificios.addfilter('selec', filtergroup);
    }
    this.gridEdificios.applyfilters();
    this.gridEdificios.updatebounddata('data');
  }

  onExportar() {
    if (this.gridEdificios.getrows().length === 0) {
      return MainComponent.getInstance().showWarning('ATENCION', this.translate('No_existen_datos'), 2000);
    } else {
      const json = this.gridEdificios.exportdata('json');
      let datos = JSON.parse(json);
      const ws: xlsx.WorkSheet = xlsx.utils.json_to_sheet(datos);

      ws['!autofilter'] = { ref: ws['!ref'] };
      const wb: xlsx.WorkBook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(wb, ws, 'Hoja1');
      xlsx.writeFile(wb, DateUtils.formatDateAMDhms(new Date()) + '_' + this.translate('edificios') + '.xlsx');
    }
  }

  onPrint(event: any) {
    if (this.gridEdificios.getrows().length === 0) {
      return MainComponent.getInstance().showWarning('ATENCION', AppComponent.translate('No_existen_datos'), 2000);
    } else {
      this.gridEdificios.hidecolumn('Botones');
      let gridContent = this.gridEdificios.exportdata('html');
      let newWindow = window.open('', '', 'width=800, height=500'),
        document = newWindow.document.open(),
        pageContent =
          '<!DOCTYPE html>\n' +
          '<html>\n' +
          '<head>\n' +
          '<meta charset="utf-8" />\n' +
          '<title>jQWidgets Grid</title>\n' +
          '</head>\n' +
          '<body>\n' +
          gridContent +
          '\n</body>\n</html>';
      this.gridEdificios.showcolumn('Botones');
      document.write(pageContent);
      document.close();
      newWindow.onafterprint = function () {
        newWindow.close();
      };
      newWindow.print();
    }
  }

  deleteZonesMap() {
    if(this.circle) {
      this.map.removeCircle(this.circle);
    }

    if(this.polygon) {
      this.map.removePolygon(this.polygon);
    }

    const removeMapElement = (element, removeFunction) => {
      if (element) {
        removeFunction(element);
      }
    };

    // Crear una copia de circlesMap y iterar sobre la copia
    const circlesCopy = this.circlesMap.slice();
    circlesCopy.forEach(circle => {
      removeMapElement(circle, elem => this.map.removeCircle(elem));
    });
    this.circlesMap = [];  // Limpia el arreglo original después de remover los elementos

    // Similar para polygonsMap
    const polygonsCopy = this.polygonsMap.slice();
    polygonsCopy.forEach(polygon => {
      removeMapElement(polygon, elem => this.map.removePolygon(elem));
    });
    this.polygonsMap = [];  // Limpia el arreglo original después de remover los elementos

    removeMapElement(this.polygonGeoJson, elem => this.map.removeGeoJson(elem));
  }
}
