<div class="loader-container" *ngIf="showLoader">
  <div class="loader"></div>
</div>

<jqxWindow #formAsociarTarjeta [width]="600" [height]="450" [zIndex]="900" [isModal]="true" [position]="'center'"
  [showCloseButton]="true" [resizable]="false" [autoOpen]="true" [theme]="environment.tema"
  [title]="translate('Asociar') + ' ' + translate('Tarjeta') + ' ' + tarjetaToAsociar.descripcion"
  (onClose)="onClose()">
  <div class="formHeader" style="overflow-x: hidden;">
    {{ translate('Asociar') }} {{ translate('Tarjeta') }}
  </div>
  <div class="form" style="float: left; overflow-x: hidden;">
    <div>
      <app-header #header [buscadorAPI]="true" [buscador]="true" [filtro]="true" [resetFiltro]="true"
        [filterApiError]="errorAlBuscar" (eventFilter)="eventFilter()" (eventResetFilter)="eventResetFilter()"
        (eventBuscador)="onBuscar()">
      </app-header>
    </div>
    <div class="row" style="overflow: hidden; background-color: transparent; width: 100%; height: calc(100% - 80px);">
      <div class="inputsRow">
        <div class="row" style="width: 45%;">
          <span style="display: block;">
            <div style="margin-top: 5px">
              {{ translate('Ciudadano') }}
            </div>
            <jqxInput [width]="'100%'" [theme]="environment.tema" [disabled]="true" [value]="ciudadanoSelec !== null ? ciudadanoSelec.nombre + ' ' + ciudadanoSelec.apellidos : ''">
            </jqxInput>
          </span>
        </div>

        <div class="row" style="width: 45%">
          <span style="display: block;">
            <div style="margin-top: 5px">
              {{ translate('Direccion') }}
            </div>
            <jqxInput [width]="'100%'" [theme]="environment.tema" [disabled]="true"
              [value]="direccionSelec !== null ? direccionSelec.direccion + ', ' + direccionSelec.numero + ', ' + direccionSelec.poblacion + '(' + direccionSelec.provincia + ')' : ''">
            </jqxInput>
          </span>
        </div>
      </div>

      <div style="width: 90%; display: flex; justify-content: space-around;">
        <jqxGrid #gridDropDownCiudadanos [appCellClick] [theme]="environment.tema" [filterable]="true"
          [sortable]="true" [rowsheight]="20" [columnsheight]="20" [source]="adapterCiudadanos" [columns]="columnsCiudadanos"
          [rowdetails]="true" [rowdetailstemplate]="rowdetailstemplate" [initrowdetails]="initRowDetails"
          [localization]="langGrid" [enablebrowserselection]="true" (onRowexpand)="onRowExpand($event)"
          (onRowcollapse)="onRowCollapse($event)">
        </jqxGrid>
      </div>
    </div>
    <jqxButton style="float: left; margin-left: 5px; margin-top: 10px;" [width]="100" [height]="26"
      [textPosition]="'center'" [value]="translate('Guardar')" (onClick)="onGuardar()">
    </jqxButton>

    <jqxButton style="float: left; margin-left: 5px; margin-top: 10px;" [width]="100" [height]="26"
      [textPosition]="'center'" [value]="translate('CANCELAR')" (onClick)="closeWindow()">
    </jqxButton>
  </div>
</jqxWindow>
