import { MapCircle, MapComponent, MapPolygon } from "movisat-maps";
import { EntradaModel } from "./zona-entrada.model";
import { ZonaPuntosModel } from "./zona-puntos.model";
import { PoligonoModel } from "./zona-poligono.model";


// TODO: Ver como estructurar esto para que cuadre con lo que devuelve la api
export class MultiPolygon {

}


export class Geom {
    type: string;
    coordinates: any[];
}

export class Geometry {
    geometry: Geom;
}

export class ZonaModel {
    Id: number = 0;
    id: number = 0;
    EmpresaId: number = 0;
    Nombre: string  = '';
    nombre: string  = '';
    Tipo: number = 0;
    TipoNombre: string = '';
    TipoGeo: number = 0;        // 0=Círculo, 1=Polígono
    TipoGeoNombre: string = '';
    Lat: number = 0;
    lat: number = 0;
    Lng: number = 0;
    lng: number = 0;
    Radio: number = 50;
    Area: number = 0;
    Observaciones: string = '';
    Puntos: ZonaPuntosModel[] = [];
    Geometria: any;
    ElementoId: number = 0;
    TipoBDT: number = 0;
    TipoBDTString: string = '';
    Entradas: EntradaModel[] = [];
    EntradaPrincipal: EntradaModel;
    Domicilio: string;
    Poblacion: string;
    Provincia: string;
    // Agreagados
    map: MapComponent;
    circle: MapCircle;
    polygons: MapPolygon[] = [];
    Poligonos: PoligonoModel[];
    EntradasCount: number = 0;
    SalidasCount: number = 0;
    EntradasSalidasCount: number = 0;
    TextoVisible: boolean = false;

    constructor() { }
}
