import { NgModule } from '@angular/core';
import { jqxGridModule } from 'jqwidgets-ng/jqxgrid';
import { jqxTabsModule } from 'jqwidgets-ng/jqxtabs';
import { jqxInputModule } from 'jqwidgets-ng/jqxinput';
import { jqxChartModule } from 'jqwidgets-ng/jqxchart';
import { jqxLoaderModule } from 'jqwidgets-ng/jqxloader';
import { jqxWindowModule } from 'jqwidgets-ng/jqxwindow';
import { jqxButtonModule } from 'jqwidgets-ng/jqxbuttons';
import { jqxRibbonModule } from 'jqwidgets-ng/jqxribbon';
import { jqxSliderModule } from 'jqwidgets-ng/jqxslider';
import { jqxTooltipModule } from 'jqwidgets-ng/jqxtooltip';
import { jqxCheckBoxModule } from 'jqwidgets-ng/jqxcheckbox';
import { jqxComboBoxModule } from 'jqwidgets-ng/jqxcombobox';
import { jqxTextAreaModule } from 'jqwidgets-ng/jqxtextarea';
import { jqxCalendarModule } from 'jqwidgets-ng/jqxcalendar';
import { jqxSplitterModule } from 'jqwidgets-ng/jqxsplitter';
import { jqxSchedulerModule } from 'jqwidgets-ng/jqxscheduler';
import { jqxFileUploadModule } from 'jqwidgets-ng/jqxfileupload';
import { HeaderModule } from '../components/header/header.module';
import { jqxNumberInputModule } from 'jqwidgets-ng/jqxnumberinput';
import { jqxRadioButtonModule } from 'jqwidgets-ng/jqxradiobutton';
import { jqxColorPickerModule } from 'jqwidgets-ng/jqxcolorpicker';
import { jqxDropDownListModule } from 'jqwidgets-ng/jqxdropdownlist';
import { jqxDateTimeInputModule } from 'jqwidgets-ng/jqxdatetimeinput';
import { CellClickDirective } from '../directivas/cell-click.directive';
import { jqxDropDownButtonModule } from 'jqwidgets-ng/jqxdropdownbutton';
import { InputNumberModule } from '../components/input-number/input-number.module';
import { PeriodoSelectModule } from '../components/periodo-select/periodo-select.module';
import { JqxWindowAutoResizeDirective } from '../directivas/jqx-window-auto-resize.directive';

@NgModule({
  declarations: [
    CellClickDirective,
    JqxWindowAutoResizeDirective,
  ],
  imports: [
    jqxGridModule,
    jqxTabsModule,
    jqxInputModule,
    jqxChartModule,
    jqxWindowModule,
    jqxButtonModule,
    jqxLoaderModule,
    jqxSliderModule,
    jqxTooltipModule,
    jqxComboBoxModule,
    jqxCheckBoxModule,
    jqxTextAreaModule,
    jqxCalendarModule,
    jqxSplitterModule,
    InputNumberModule,
    jqxSchedulerModule,
    PeriodoSelectModule,
    jqxFileUploadModule,
    jqxRadioButtonModule,
    jqxNumberInputModule,
    jqxColorPickerModule,
    jqxDropDownListModule,
    jqxDateTimeInputModule,
    jqxDropDownButtonModule,
    jqxDropDownButtonModule,
  ],
  exports: [
    HeaderModule,
    jqxGridModule,
    jqxTabsModule,
    jqxChartModule,
    jqxInputModule,
    jqxSliderModule,
    jqxWindowModule,
    jqxButtonModule,
    jqxLoaderModule,
    jqxRibbonModule,
    jqxTooltipModule,
    jqxComboBoxModule,
    jqxCheckBoxModule,
    jqxTextAreaModule,
    jqxCalendarModule,
    jqxSplitterModule,
    InputNumberModule,
    jqxSchedulerModule,
    CellClickDirective,
    PeriodoSelectModule,
    jqxFileUploadModule,
    jqxNumberInputModule,
    jqxRadioButtonModule,
    jqxColorPickerModule,
    jqxDropDownListModule,
    jqxDateTimeInputModule,
    jqxDropDownButtonModule,
    jqxDropDownButtonModule,
    JqxWindowAutoResizeDirective,
  ]
})
export class SharedModule { }
