import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';

import { MapModule } from 'movisat-maps';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ZonesComponent } from './zones.component';
import { HeaderModule } from '../header/header.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { EntradaSalidaModule } from './entrada-salida/entrada-salida.module';

@NgModule({
  declarations: [
    ZonesComponent,
  ],
  imports: [
    MapModule,
    FormsModule,
    HeaderModule,
    CommonModule,
    SharedModule,
    NzModalModule,
    ReactiveFormsModule,
    EntradaSalidaModule,
    MatAutocompleteModule,
  ]
})
export class ZonesModule { }
