
<jqxLoader #loader [imagePosition]="'top'" [width]="100" [height]="60" [text]="translate('Procesando')">
</jqxLoader>

<div #elementsContainer id="elemGrid" style="overflow: hidden; height: 100%;">
  <jqxGrid #gridElements [theme]="environment.tema" [height]="'100%'" [width]="'100%'" [source]="dataAdapter"
    [columnsresize]="true" [sortable]="true" [selectionmode]="'checkbox'" [showrowlines]="false" [groupable]="true"
    [rendertoolbar]="createToolBar" [showcolumnlines]="true" [showtoolbar]="true" [columns]="columns" [altrows]="true"
    [toolbarheight]="30" [filterable]="true" [showaggregates]="true" [groupsheaderheight]="20" [showstatusbar]="true"
    [statusbarheight]="20" [appCellClick] [enablebrowserselection]="true" [autoshowfiltericon]="true"
    [filterrowheight]="20" [rowsheight]="25" [columnsheight]="20" [localization]="langGrid"
    [groupsrenderer]="groupsrenderer" [filter]="filter" [updatefilterconditions]="updatefilterconditions"
    [columnmenuopening]="columnmenuopening" (onCellclick)="onCellClick($event)"
    (onBindingcomplete)="onBindingComplete()" (onFilter)="onChangeFilter($event)"
    (onRowdoubleclick)="onRowdoubleclick($event)">
  </jqxGrid>
</div>

<div #elementsEdit></div>