<jqxRibbon #ciudRibbon [theme]="theme" [position]="'top'" [height]="'calc(100% - 10px)'">
    <ul style="width: 100%; text-align: left; z-index: 99; background-color: rgb(225, 229, 231);">
        <li>
            {{ translate('Identificaciones') }}
        </li>
        <li>
            {{ translate('Alarmas') }}
        </li>
        <li>
            {{ translate('Envios') }}
        </li>
    </ul>
    <div>

        <div style="overflow: hidden; background-color: transparent; display: flex; margin-top: 27px;">


            <div style="display: flex; gap: 2px; flex-direction: column; margin-top: 8px; height: 100%;">
                <div>
                    <app-header #header [exportar]="true" [imprimir]="true" [buscador]="true" [filtro]="true"
                        [resetFiltro]="true" (eventExportar)="onExportar('identificacion', $event)" [periodo]="true"
                        (eventImprimir)="onPrint('identificacion', $event)"
                        (eventBuscador)="onBuscar('identificacion', $event)"
                        (eventFilter)="eventFilter('identificacion', $event)"
                        (eventResetFilter)="eventResetFilter('identificacion', $event)">
                    </app-header>
                </div>


                <div style="height: 80%;">
                    <jqxGrid #grid [theme]="theme" [width]="'100%'" [height]="'100%'" [source]="dataAdapter"
                        [columnsresize]="true" [appCellClick] [sortable]="true" [showrowlines]="false"
                        [showcolumnlines]="true" [columns]="columns" [altrows]="true" [enablebrowserselection]="true"
                        [filterable]="true" [showstatusbar]="true" [statusbarheight]="20" [showaggregates]="true"
                        [rowsheight]="20" [columnsheight]="20" [localization]="langGrid" [groupable]="true"
                        [groupsrenderer]="groupsrenderer" [filter]="filter"
                        [updatefilterconditions]="updatefilterconditions" (onFilter)="onfilter($event)">
                    </jqxGrid>
                </div>

            </div>



        </div>

        <div style="overflow: hidden; background-color: transparent;margin-top: 27px">


            <div style="display: flex; gap: 2px; flex-direction: column; margin-top: 8px; height: 100%;">
                <div>
                    <app-header #header [exportar]="true" [imprimir]="true" [buscador]="true" [filtro]="true"
                        [resetFiltro]="true" (eventExportar)="onExportar('alarmas', $event)" [periodo]="true"
                        (eventImprimir)="onPrint('alarmas', $event)" (eventBuscador)="onBuscar('alarmas', $event)"
                        (eventFilter)="eventFilter('alarmas', $event)"
                        (eventResetFilter)="eventResetFilter('alarmas', $event)">
                    </app-header>
                </div>

                <div style="height: 80%;">

                    <jqxGrid #gridAlarmas [theme]="theme" [width]="'100%'" [height]="'100%'"
                        [source]="dataAdapteAlarmas" [columnsresize]="true" [appCellClick] [sortable]="true"
                        [showrowlines]="false" [showcolumnlines]="true" [columns]="columnsAlarmas"
                        [columnmenuopening]='columnmenuopeningAlarmas' [altrows]="true" [filterable]="true"
                        [showstatusbar]="true" [showaggregates]="true" [columnsautoresize]="true"
                        [enablebrowserselection]="true" [localization]="langGrid" [groupable]="true"
                        [showemptyrow]="false" [statusbarheight]="20" (onGroupschanged)="resizeColumnsAlarmas()"
                        [columnsheight]="20" [rowsheight]="20" [filter]="filterAlarmas"
                        [updatefilterconditions]="updatefilterconditions">
                    </jqxGrid>

                </div>

            </div>
        </div>

        <div style="overflow: hidden; background-color: transparent; margin-top: 27px">


            <div style="display: flex; gap: 2px; flex-direction: column; margin-top: 8px; height: 100%;">
                <div>
                    <app-header #header [exportar]="true" [imprimir]="true" [buscador]="true" [filtro]="true"
                        [resetFiltro]="true" (eventExportar)="onExportar('envios', $event)" [periodo]="true"
                        (eventImprimir)="onPrint('envios', $event)" (eventBuscador)="onBuscar('envios', $event)"
                        (eventFilter)="eventFilter('envios', $event)"
                        (eventResetFilter)="eventResetFilter('envios', $event)">
                    </app-header>
                </div>
                <div style="height: 80%;">
                    <jqxGrid #myGridEnvios [theme]="theme" [width]="'100%'" [height]="'100%'"
                        [source]="dataAdapterEnvios" [appCellClick] [enablebrowserselection]="true"
                        [columnsresize]="true" [sortable]="true" [showrowlines]="false" [showcolumnlines]="true"
                        [columns]="columnsEnvios" [altrows]="true" [filterable]="true" [showstatusbar]="true"
                        [statusbarheight]="20" [rowdetails]="true" [showaggregates]="true" [rowsheight]="20"
                        [columnsheight]="20" [localization]="langGrid" [groupable]="true"
                        [rowdetailstemplate]="rowdetailstemplate" [filter]="filter" [initrowdetails]="initRowDetails"
                        [updatefilterconditions]="updatefilterconditions" (onRowexpand)="rowExpand($event)"
                        (onRowcollapse)="rowCollapse($event)" (onSort)="onSort($event)">
                    </jqxGrid>
                </div>
            </div>
        </div>
    </div>

</jqxRibbon>