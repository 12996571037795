import { Component, OnInit, ViewChild } from '@angular/core';

import { AppComponent } from 'src/app/app.component';
import { CustomForms } from '../../forms/custom-forms';
import { MainComponent } from '../../main/main.component';
import { environment } from 'src/environments/environment';

import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';
import { jqxDropDownButtonComponent } from 'jqwidgets-ng/jqxdropdownbutton';

import { JqxDomService } from 'src/app/services/jqwidgets/jqx-dom.service';
import { CiudadanosService } from 'src/app/services/ciudadanos/ciudadanos.service';
import { TarjetasService } from 'src/app/services/ciudadanos/tarjetas/tarjetas.service';

import { TarjetaModel } from 'src/app/services/ciudadanos/models/tarjeta.model';
import { CiudadanoModel } from 'src/app/services/ciudadanos/models/ciudadano.model';
import { DireccionModel } from 'src/app/services/ciudadanos/models/direccion.model';

import { HeaderComponent } from '../../header/header.component';
import { TarjetasCiudadanosComponent } from '../tarjetas-ciudadanos.component';
import { DetallesAsociarTarjetasComponent } from './detalles-asociar-tarjetas/detalles-asociar-tarjetas.component';

@Component({
  selector: 'app-asociar-tarjetas',
  templateUrl: './asociar-tarjetas.component.html',
  styleUrls: ['./asociar-tarjetas.component.css']
})
export class AsociarTarjetasComponent extends CustomForms implements OnInit {
  @ViewChild('formAsociarTarjeta') formAsociarTarjeta: jqxWindowComponent;
  @ViewChild('header') header: HeaderComponent;

  showLoader: boolean = false;
  errorAlBuscar: boolean = false;

  public tarjetaToAsociar: TarjetaModel = new TarjetaModel();

  private componentRef = null;
  public environment = environment;
  public static _this: AsociarTarjetasComponent;
  public langGrid = JqWidgets.getLocalization('es');

  private showRowDetails: number[] = [];

  public translate(text: string): string {
    return AppComponent.translate(text);
  }

  constructor(
    private tarjetaServicio: TarjetasService,
    private ciudadanoService: CiudadanosService,
    private jqxDomService: JqxDomService,
  ) {
    super();
    AsociarTarjetasComponent._this = this;
  }

  ngOnInit(): void {
  }

  init(componentRef: any, tarjetaToAsociar: TarjetaModel) {
    this.componentRef = componentRef;
    this.tarjetaToAsociar = tarjetaToAsociar;
  }

  ngAfterViewInit() {
    this.addCustomForm(this.formAsociarTarjeta);

    this.resizeGrid(this.gridDropDownCiudadanos);
  }

  async onGuardar(){
    if(this.ciudadanoSelec && this.direccionSelec) {
      await this.tarjetaServicio.associateTarjetasToCiudadano([{ "id": this.tarjetaToAsociar.id, "master": false, "idDireccion": this.direccionSelec.id }], this.ciudadanoSelec.id).then(
        (result) => {
          MainComponent.getInstance().showInfo(
            'ATENCION',
            'Registro_almacenado',
            2000
          );
        }
      )
      this.closeWindow();
    }else {
      MainComponent.getInstance().showError(
        'ATENCION',
        'Selecciona_direccion_tarjeta',
        2000
      );
    }
  }

  closeWindow() {
    this.formAsociarTarjeta.close();
  }

  onClose() {
    if(this.componentRef) {
      this.componentRef.destroy();
      TarjetasCiudadanosComponent._this.form.expand();
    }
  }

  //GRID CIUDADANOS
  @ViewChild('gridDropDownCiudadanos') gridDropDownCiudadanos: jqxGridComponent;
  @ViewChild('cbCiudadanos') cbCiudadanos: jqxDropDownButtonComponent;

  public ciudadanos: CiudadanoModel[] = [];

  public ciudadanoSelec: CiudadanoModel = null;
  public direccionSelec: DireccionModel = null;

  public columnsCiudadanos: any = [
    { text: 'Id', columntype: 'textbox', datafield: 'id', width: 80, hidden: true },
    { text: this.translate('Nombre'), columntype: 'textbox', datafield: 'nombre' },
    { text: this.translate('Apellidos'), columntype: 'textbox', datafield: 'apellidos' },
    { text: this.translate('Dni'), columntype: 'textbox', datafield: 'dni' },
    { text: this.translate('Numero') + ' ' + this.translate('Direcciones').toLowerCase(), columntype: 'textbox', datafield: 'numDirecciones' },
    { datafield: 'selec', hidden: true },
  ];

  public sourceCiudadanos: any = {
    datatype: 'json',
    datafields: [
      { name: 'id', type: 'number', map: 'id' },
      { name: 'nombre', type: 'string', map: 'nombre' },
      { name: 'apellidos', type: 'string', map: 'apellidos' },
      { name: 'dni', type: 'string', map: 'nif' },
      { name: 'numDirecciones', type: 'number', map: 'direcciones>length' },
      { name: 'selec', map: 'selec' }
    ],
    localdata: [],
  };

  public adapterCiudadanos: any = new jqx.dataAdapter(this.sourceCiudadanos);

  async initGridCiudadanos(){
    if(this.header.searchInputApi['nativeElement'].value.length >= 3){
      try {
        this.showLoader = true;
        this.errorAlBuscar = false;

        await this.ciudadanoService.getCiudadanosFilter(this.header.searchInputApi['nativeElement'].value).then(
          (result) => {
            this.ciudadanos = result;
            this.showLoader = false;
          }
        )
        this.sourceCiudadanos.localdata = this.ciudadanos;

        this.gridDropDownCiudadanos.updatebounddata('data');

        if(this.tarjetaToAsociar.ciudadano !== null) {
          this.ciudadanoSelec = this.tarjetaToAsociar.ciudadano;
          this.gridDropDownCiudadanos.selectrow(this.gridDropDownCiudadanos.getrows().find(ciud => ciud.id === this.ciudadanoSelec.id));
        }
      } catch (error) {
        this.showLoader = false;
        MainComponent.getInstance().showError('ERROR', 'Error_interno', 2000);
      }
    }else {
      this.errorAlBuscar = true;
      MainComponent.getInstance().showWarning('ATENCION', 'Campo_busqueda_minimos_caractere', 2000);
    }
  }

  onRowExpand(event: any) {
    let id = this.gridDropDownCiudadanos.getrowdata(event.args.rowindex).id;
    let ciudadano = this.ciudadanos.find(
      ciud => ciud.id === id
    );

    if(ciudadano.direcciones){
      if(!(this.showRowDetails.includes(id))){
        this.showRowDetails.push(id);
      }
    }else {
      this.gridDropDownCiudadanos.hiderowdetails(event.args.rowindex);
    }
  }

  onRowCollapse(event: any) {
    let id = this.gridDropDownCiudadanos.getrowdata(event.args.rowindex).id;
    this.showRowDetails.splice(this.showRowDetails.indexOf(id), 1);
  }

  rowdetailstemplate = (index: any): any => {
    let details = {
      rowdetails: '<div id="nestedGrid" style="margin: 10px;"></div>',
    };

    return details;
  };

  initRowDetails = async (
    index: any,
    parentElement: any,
    gridElement: any,
    datarecord: any
  ): Promise<void> => {
    if (parentElement && datarecord) {
      let direccionesAsig = this.ciudadanos.find(ciud => ciud.id === datarecord.id).direcciones;

      if (!direccionesAsig) {
        direccionesAsig = [];
      }

      const container = document.createElement('div');
      parentElement.appendChild(container);
      // Añado el componente de detalle y lo inicializo con el id del equipamiento
      const component: any =
      AsociarTarjetasComponent._this.jqxDomService.loadComponent(
        DetallesAsociarTarjetasComponent,
        container
      );

      component.componentRef.instance.init(
        direccionesAsig
      );
    }
  }

  resizeGrid(grid: jqxGridComponent) {
    if (grid) {
      grid.setOptions({
        columnsheight: 20,
        statusbarheight: 20,
        rowsheight: 22,
        columnsresize: true,
      });
    }
  }

  eventFilter() {
    this.header.searchInput['nativeElement'].value = '';
    this.initGridCiudadanos();
  }

  eventResetFilter() {
    this.header.searchInput['nativeElement'].value = '';
    this.header.searchInputApi['nativeElement'].value = '';

    this.direccionSelec = null;

    this.ciudadanoSelec = null;

    this.sourceCiudadanos.localdata = [];
    this.gridDropDownCiudadanos.updatebounddata('data');
  }

  onBuscar() {
    let filtervalue = '';

    if (this.header.searchInput['nativeElement'].value.length >= 3) {
      filtervalue = this.header.searchInput['nativeElement'].value;
    } else {
      filtervalue = '';
    }

    this.ciudadanos.forEach(ciud => {
      if (
        ciud?.nombre?.compareWith(filtervalue) ||
        ciud?.apellidos?.compareWith(filtervalue) ||
        ciud?.nif?.compareWith(filtervalue)
      ) {
        ciud['selec'] = 'selec';
      } else {
        ciud['selec'] = '';
      }
    });

    // Compruebo si ya he creado el filtro "selec" anteriormente
    const filters = this.gridDropDownCiudadanos.getfilterinformation();
    if (filters.find(s => s.datafield === 'selec') === undefined) {
      const filtergroup = new jqx.filter();
      filtergroup.operator = 'and';
      filtergroup.addfilter(0, filtergroup.createfilter('stringfilter', 'selec', 'equal'));
      this.gridDropDownCiudadanos.addfilter('selec', filtergroup);
    }
    this.gridDropDownCiudadanos.applyfilters();
    this.gridDropDownCiudadanos.updatebounddata('data');
  }

  onSelecDireccion(ciudadano: CiudadanoModel, direccion: DireccionModel) {
    this.ciudadanoSelec = ciudadano;
    this.direccionSelec = direccion;
  }
}
