import { Component, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';

import { Utils } from 'src/app/utils/utils';
import { AppComponent } from 'src/app/app.component';
import { environment } from 'src/environments/environment';
import { MapComponent, MapMarker, MapLatLng } from 'movisat-maps';
import { CustomForms } from 'src/app/components/forms/custom-forms';
import { MainComponent } from 'src/app/components/main/main.component';

import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxButtonComponent } from 'jqwidgets-ng/jqxbuttons';

import { SsoService } from 'src/app/services/sso/sso.service';
import { ZonesService } from 'src/app/services/zones/zones.service';

import { ZonaModel } from 'src/app/services/zones/models/zona.model';
import { EntradaModel } from 'src/app/services/zones/models/zona-entrada.model';

import { EdificiosInstalacionesEditComponent } from '../../edificios-instalaciones-edit/edificios-instalaciones-edit.component';
import { EdificiosInstalacionesEntradasSalidasEditComponent } from '../edificios-instalaciones-entradas-salidas-edit/edificios-instalaciones-entradas-salidas-edit.component';

@Component({
  selector: 'app-edificios-instalaciones-entradas-salidas-list',
  templateUrl: './edificios-instalaciones-entradas-salidas-list.component.html',
  styleUrls: ['./edificios-instalaciones-entradas-salidas-list.component.css']
})

export class EdificiosInstalacionesEntradasSalidasListComponent extends CustomForms implements OnInit {
  // variables recuperadas del html
  @ViewChild('grid') grid: jqxGridComponent;

  @ViewChild('edit') edit: jqxButtonComponent;
  @ViewChild('create') create: jqxButtonComponent;
  @ViewChild('delete') delete: jqxButtonComponent;

  @ViewChild('formEdificiosEntradasSalidas', { read: ViewContainerRef }) editEdificiosEntradasSalidasComponent;
  formEdificiosEntradasSalidas: any;

  // inputs
  @Input() editable: boolean;
  @Input() edificio: ZonaModel;

  // carga el tema necesario para los compontes del jqwidgets
  public theme = environment.tema;

  // variables para el mapa
  private map: MapComponent;
  // variables para el grid
  public langGrid = JqWidgets.getLocalization('es');
  row: number;
  public static _this: EdificiosInstalacionesEntradasSalidasListComponent;
  // variables para el crud
  public editEntrada: boolean = false;
  public entrada: EntradaModel = new EntradaModel();
  public entradas: EntradaModel[] = [];
  entradasLocal: EntradaModel[] = [];
  entradaLocal: EntradaModel = new EntradaModel();
  public tipoEntrada: boolean = false;
  public tipoSalida: boolean = false;
  viewFormEntrada: boolean = false;
  // marcadores
  markers: MapMarker[] = [];
  auxMarker: MapMarker[] = [];
  marker: MapMarker;
  // suscripción a los eventos del mapa
  private subscriptionOnMapClick = null;
  private subscriptionOnMarkerMove = null;
  private subscriptionOnCircleClick = null;
  private subscriptionOnPolygonClick = null;
  isVisible = false;

  constructor(private zonesService: ZonesService, private ssoService: SsoService) {
    super();
    EdificiosInstalacionesEntradasSalidasListComponent._this = this;
  }

  ngOnInit(): void {
    this.langGrid = JqWidgets.getLocalization(this.ssoService.getTicket().Usuario.Idioma.Codigo.substring(0, 2));
    this.map = MainComponent.getInstance().getActiveMap();
  }

  ngAfterViewInit() {
    this.setGridLocal(this.grid);
    if (this.edificio.Id !== 0) {
      this.cargarMarkers();
    }
  }

  columns = [
    { text: 'Id', columntype: 'textbox', filtertype: 'textbox', datafield: 'id', hidden: true },
    {
      text: '',
      width: 100,
      columntype: 'text',
      sortable: false,
      editable: false,
      groupable: false,
      menu: false,
      clickable: false,
      filterable: false,
      resizable: false,
      rendered: (columnHeaderElement) => {
        const buttonContainer = document.createElement('div');
        buttonContainer.style.width = '100%';
        buttonContainer.style.display = 'flex';
        buttonContainer.style.justifyContent = 'center';

        buttonContainer.id = `buttonContainerColumn_jqxButton`;
        columnHeaderElement[0].appendChild(buttonContainer);
        const btnCrear = document.createElement('div');
        btnCrear.innerHTML =
        `
          <button class="button" style="height: 20px; width: 100%; display: flex; justify-content: center;"
            title="${AppComponent.translate('Crear')}">
            <i class="fa-solid fa-plus"></i>
          </button>
        `;
        btnCrear.id = `buttonCrear_jqxButton`;
        buttonContainer.appendChild(btnCrear);
        btnCrear.style.width = '100%';
        btnCrear.style.height = '100%';
        btnCrear.addEventListener('click', async (event: any) => {
          this.onCrearES();
        });
        return columnHeaderElement[0];
      },
      createwidget: (
        row: any,
        column: any,
        value: string,
        htmlElement: HTMLElement
      ): void => {
        this.initBtnColumn(row, column, value, htmlElement);
      },
      initwidget: (
        row: any,
        column: any,
        value: string,
        htmlElement: HTMLElement
      ) => {
        this.initBtnColumn(row, column, value, htmlElement);
      },
    },
    {
      text: AppComponent.translate('Nombre'), columntype: 'textbox', filtertype: 'input', datafield: 'nombre', width: 140,
      aggregates: [{
        'Total': function (aggregatedValue, currentValue: number) {
          return aggregatedValue + 1;
        }
      }], //agrego el total al grid
      aggregatesrenderer: function (aggregates) {
        let renderstring = '';
        if (aggregates['Total'] !== undefined) {
          renderstring = '<div style="margin-left: 4px;">Total: ' +
            aggregates['Total'] + '</div>';
        }
        return renderstring;
      }
    },
    {
      text: AppComponent.translate('Direccion'),
      columntype: 'textbox',
      filtertype: 'input',
      datafield: 'direccion',
      align: 'left',
      width: 140
    },
    { text: AppComponent.translate('Poblacion'), columntype: 'textbox', filtertype: 'input', datafield: 'poblacion', width: 140 },
    { text: AppComponent.translate('Municipio'), columntype: 'textbox', filtertype: 'input', datafield: 'municipio', width: 140 },
    {
      text: AppComponent.translate('Tipo'),
      columntype: 'textbox',
      filtertype: 'checkedlist',
      filteritems: [this.translate('Entrada'), this.translate('Salida'), this.translate('Entrada') + '/' + this.translate('Salida')],
      datafield: 'tipo',
      align: 'center',
      cellsrenderer: this.renderRow,
      width: 100
    },
    { text: 'Selec', columntype: 'textbox', filtertype: 'textbox', datafield: 'selec', hidden: true, width: 1 }
  ];

  dataSource = {
    datatype: 'json',
    datafields: [
      { name: 'id', type: 'int', map: 'Id' },
      { name: 'nombre', type: 'string', map: 'Nombre' },
      { name: 'direccion', type: 'string', map: 'Direccion' },
      { name: 'poblacion', type: 'string', map: 'Poblacion' },
      { name: 'municipio', type: 'string', map: 'Municipio' },
      { name: 'tipo', type: 'string', map: 'Tipo' },
      { name: 'selec', type: 'string' },
    ],
    localdata: this.entradas,
  };
  dataAdapter = new jqx.dataAdapter(this.dataSource);

  async initBtnColumn(
    row: any,
    column: any,
    value: string,
    htmlElement: HTMLElement
  ) {
    htmlElement.innerHTML = '';

    let rowdata;
    if (isNaN(row)) {
      rowdata = row.bounddata;
    } else {
      rowdata = this.grid.getrowdata(row);
    }
    let edificioES = this.entradas.find(edificio => edificio.Id == rowdata.id);

    const btnContainer = document.createElement('div');
    btnContainer.style.display = 'flex';
    btnContainer.style.justifyContent = 'center';
    btnContainer.style.gap = '2px';
    btnContainer.style.padding = '2px';

    const btnEditar = document.createElement('div');
    btnEditar.innerHTML = `
      <button class="button" style="height: 23px; width: 25px; padding: 0; margin: 0; cursor: pointer !important;" title="`+AppComponent.translate('Editar')+`">
        <i class="fa-solid fa-pen-to-square"></i>
      </button>
    `;
    btnEditar.id = `buttonEdite_jqxButton`;
    btnContainer.appendChild(btnEditar);

    btnEditar.addEventListener('click', async (event: any) => {
      this.onEditarES(edificioES);
    });

    const btnDelete = document.createElement('div');
    btnDelete.innerHTML = `
      <button class="button" style="height: 23px; width: 25px; padding: 0; margin: 0; cursor: pointer !important;" title="`+AppComponent.translate('Borrar')+`">
        <i class="fa-solid fa-trash"></i>
      </button>
    `;
    btnDelete.id = `buttonDelete_jqxButton`;
    btnContainer.appendChild(btnDelete);

    btnDelete.addEventListener('click', async (event: any) => {
      this.onBorrarES(edificioES);
    });

    htmlElement.appendChild(btnContainer);
  }

  async getDireccion(element: any, lat: number, lng: number): Promise<void> {
    try {
      const data = await this.zonesService.getDireccion(lat, lng);
      if (data?.District !== null && data?.District !== undefined) {
        element.Municipio = data.Locality;
      } else if (data?.District == null && data?.Locality == undefined) {
        element.Poblacion = data?.Locality;
      }
      element.Poblacion = data?.District;
    } catch (error) {
      console.error(error);
    }
  }

  //obtengo todas las entradas
  async getEntradas(edit: boolean) {
    // Limpieza inicial de entradas
    this.entradas = [];
    this.entradasLocal = [];
    if (this.dataSource.localdata.length > 0) {
      this.dataSource.localdata = [];
      this.grid.updatebounddata('data');
    }

    if (edit) {
      try {
        // Obtener entradas de la zona seleccionada
        this.entradas = await this.zonesService.getEntradas(this.edificio.Id);
        // Obtener direcciones de todas las entradas
        for (const element of this.entradas) {
          await this.getDireccion(element, element.Lat, element.Lng);
        }
        // Actualizar la tabla de entradas
        if (this.entradas.length > 0) {
          this.dataSource.localdata = this.entradas;
          this.grid.updatebounddata();
        }
      } catch (error) {
      }
    } else {
      this.dataSource.localdata = this.entradas;
      this.grid.updatebounddata('data');
    }
  }

  updatefilterconditions = (type: string, defaultconditions: any): string[] => {
    return Utils.updatefilterconditions(type, defaultconditions);
  };

  public filter(cellValue, rowData, dataField, filterGroup, defaultFilterResult): boolean {
    if (dataField == 'tipo') {
      let filterValue = filterGroup.getfilters()[0].value;

      switch (filterValue) {
        case this.translate('Entrada'):
          if (cellValue == 1) {
            return true;
          }
          break;
        case this.translate('Salida'):
          if (cellValue == 2) {
            return true;
          }
          break;
        case this.translate('Entrada') + '/' + this.translate('Salida'):
          if (cellValue == 3) {
            return true;
          }
        default:
          return false;
      }
    } else {
      let filterColumns = [
        'nombre',
        'direccion',
        'poblacion',
        'municipio'
      ];

      return Utils.filterRow(cellValue, dataField, filterGroup, defaultFilterResult, filterColumns);
    }
  }

  async cargarMarkers() {
    if (this.edificio.Id === 0) {
      return
    } else {
      this.entradas = await this.zonesService.getEntradas(this.edificio.Id);
      this.entradas = Utils.onChangeArray(this.entradas);
      this.entradas.forEach(entrada => {
        this.paintMarkers(entrada, new MapLatLng(entrada.Lat, entrada.Lng));
      });
    }
  }

  setGridLocal(grid: jqxGridComponent) {
    grid.setOptions({
      statusbarheight: 15,
      columnsresize: true,
      rowsheight: 20,
      columnsheight: 20
    });
  }

  paintMarkers(entrada: EntradaModel, position: MapLatLng, map?: MapComponent) {
    const addMarkerToMap = (mapInstance: MapComponent, addList: boolean) => {
      let marker = mapInstance.addMarker({
        dataModel: entrada,
        title: entrada.Nombre,
        content: entrada.Nombre,
        position: position,
        icon: this.iconMarker(entrada),
        zIndex: 999,
        drag: false,
        visible: true
      });

      if (addList) {
        this.markers.push(marker);
      } else {
        //ZonesComponent._this.markersZone.push(marker);
      }
    };

    // Añadir el marcador al mapa proporcionado, si existe
    if (map) {
      addMarkerToMap(map, false);
    }

    // Añadir el marcador al mapa por defecto
    let markerMap = this.map.getMarkerList();
    // busco si existe el marcador en el mapa
    let marker = Array.from(markerMap.values()).find(marker => marker.dataModel.Id === entrada.Id);
    if (!marker) {
      addMarkerToMap(this.map, true);
    }
  }

  async createEntrada(lat: number, lng: number, marker: MapMarker) {
    this.entrada.Lat = lat;
    this.entrada.Lng = lng;
    //this.entrada.ZonaId = ZonesComponent._this.creandoZonaMasEntradas ? ZonesComponent._this.idZonaEntrada : this.zonaSelect.Id;

    let nuevaEntrada = await this.zonesService.addEntrada(this.entrada);

    if (nuevaEntrada) {
      this.entrada = nuevaEntrada;
      marker.setData(this.entrada);

      // Eliminar entrada existente si ya está en la lista
      const entradaIndex = this.entradas.findIndex(e => e.Id === nuevaEntrada.Id);
      if (entradaIndex !== -1) {
        this.entradas.splice(entradaIndex, 1);
      }

      this.entradas.push(nuevaEntrada);
    }
  }

  //pinto el icono del marcador segun el tipo de entrada y si existe el marcador lo pinto de una manera u otra
  iconMarker(entrada: any): string {
    const icons = {
      1: '/assets/images/entrada.png',
      2: '/assets/images/salida.png',
      3: '/assets/images/entrada_salida.png'
    };
    const iconUrl = icons[entrada.Tipo] || '';

    if (this.marker && this.editEntrada) {
      this.marker.setIcon(iconUrl);
    }
    return iconUrl;
  }

  //select grid
  onRowclick(event) {
    this.row = event.args.row.bounddata.id;
    const rowIndex = event.args.rowindex;
    const rowData = this.grid.getrowdata(rowIndex);

    if (rowData) {
      this.grid.setcellvalue(rowIndex, 'selec', this.markers[rowIndex]?.id);

      // Encuentra y anima el marcador correspondiente
      const markerToAnimate = this.markers.find(marker => marker.id === rowData.selec);
      if (markerToAnimate) {
        markerToAnimate.animate(2000);
      }
    }
  }

  //cambio el formato de celda a string, segun el tip de entrada
  renderRow(row: number, columnfield: string, value: any,
    defaulthtml: string, columnproperties: any, rowdata: any): string {
    switch (columnfield) {
      case 'tipo':
        switch (value) {
          case 1:
            return '<div style="margin-left: 4px; margin-top: 2px;"' + columnproperties.cellsalign + ';">' + AppComponent.translate('Entrada') + '</div>';
          case 2:
            return '<div style="margin-left: 4px; margin-top: 2px;" ' + columnproperties.cellsalign + ';">' + AppComponent.translate('Salida') + '</div>';
          case 3:
            return '<div style="margin-left: 4px; margin-top: 2px;"' + columnproperties.cellsalign + ';">' + AppComponent.translate('Entrada') + '/' + AppComponent.translate('Salida') + '</div>';
        }
    }
  }

  //FUNCIONALIDADES DE ENTRADAS Y SALIDAS
  // crear entrada
  async onCrearES() {
    EdificiosInstalacionesEditComponent._this.winFormEdificios.collapse();

    this.formEdificiosEntradasSalidas = this.editEdificiosEntradasSalidasComponent.createComponent(EdificiosInstalacionesEntradasSalidasEditComponent);
    this.formEdificiosEntradasSalidas.instance.init(this.formEdificiosEntradasSalidas, this.edificio);

    /* if (EdificiosInstalacionesEditComponent._this.circle != null || EdificiosInstalacionesEditComponent._this.polygon != undefined
      || EdificiosInstalacionesEditComponent._this.polygonGeoJson != null) {
      //this.formEntradaSalida.open();
      this.setGridLocal(this.gridEntrLocal);
      this.auxMarker = this.markers;
      this.markers = [];
      this.marker = null;
      if (EdificiosInstalacionesEditComponent._this.subscriptionOnMapClick) {
        EdificiosInstalacionesEditComponent._this.subscriptionOnMapClick.unsubscribe();
        EdificiosInstalacionesEditComponent._this.subscriptionOnMapClick = null;
      }
    } else {
      MainComponent.getInstance().showError(
        'ATENCION', 'Seleccione_punto_cartografia', 2000
      );
    } */
  }

  // editar entrada
  async onEditarES(edificioES: EntradaModel) {
    EdificiosInstalacionesEditComponent._this.winFormEdificios.collapse();

    this.formEdificiosEntradasSalidas = this.editEdificiosEntradasSalidasComponent.createComponent(EdificiosInstalacionesEntradasSalidasEditComponent);
    this.formEdificiosEntradasSalidas.instance.init(this.formEdificiosEntradasSalidas, this.edificio, edificioES);
    // pinto el marcador en el mapa y lo centro
    /* let elem = this.entradas.find(elem => elem.Id == this.row);
    if (elem) {
      //this.formEntradaSalida.open();
      this.editEntrada = true;
      this.entrada = elem;
      this.marker = this.markers.find(marker => marker.dataModel.Id === this.row);
      this.marker.setDraggable(true);
      this.marker.animate(2850);
      this.map.setCenter(this.marker.position);
      this.map.setZoom(18);
      // no dejo mover los demas marcadores
      this.markers.forEach(marker => {
        if (marker.dataModel.Id !== this.row) {
          marker.setDraggable(false);
        }
      });
      this.auxMarker = this.markers.filter(marker => marker.dataModel.Id !== this.row);
      this.markers = [];
      this.markers.push(this.marker);
      setTimeout(() => {
        if (this.subscriptionOnMapClick) {
          this.subscriptionOnMapClick.unsubscribe();
          this.subscriptionOnMapClick = null;
        }
      }, 200);
    } else {
      MainComponent.getInstance().showWarning('ATENCION', 'Seleccione_registro', 2000);
    } */
  }

  // borrar entrada
  onBorrarES(entrada: EntradaModel) {
    let elem = this.entradas.find(elem => elem.Id == this.row);
    if (elem) {
      /* ZonesComponent._this.isVisible = true;
      ZonesComponent._this.rowSelectEntradaSalida = elem; */
    } else {
      MainComponent.getInstance().showWarning('ATENCION', 'Seleccione_registro', 2000);
    }
  }

  // Desuscripción a los eventos del mapa
  unsubscribeMapEvents() {
    if (this.subscriptionOnMapClick) {
      this.subscriptionOnMapClick.unsubscribe();
      this.subscriptionOnMapClick = null;
    }
    if (this.subscriptionOnMarkerMove) {
      this.subscriptionOnMarkerMove.unsubscribe();
      this.subscriptionOnMarkerMove = null;
    }

    if (this.subscriptionOnCircleClick) {
      this.subscriptionOnCircleClick.unsubscribe();
      this.subscriptionOnCircleClick = null;
    }

    if (this.subscriptionOnPolygonClick) {
      this.subscriptionOnPolygonClick.unsubscribe();
      this.subscriptionOnPolygonClick = null;
    }
  }

  // elimino los marcadores
  removeMarker() {
    if (this.markers.length > 0) {
      this.markers.forEach(marker => {
        this.map.removeMarker(marker);
      });
      this.marker = null;
      this.markers = [];
    }

    if (this.markers.length > 0) {
      this.markers.forEach(marker => {
        EdificiosInstalacionesEditComponent._this.mapZonaEdit.removeMarker(marker);
      });
      this.marker = null;
      this.markers = [];
    }

    if (this.auxMarker.length > 0) {
      this.auxMarker.forEach(marker => {
        this.map.removeMarker(marker);
      });
      this.marker = null;
      this.auxMarker = [];
    }

    if (EdificiosInstalacionesEditComponent._this.markersZone.length > 0) {
      EdificiosInstalacionesEditComponent._this.markersZone.forEach(marker => {
        this.map.removeMarker(marker);
      });
      EdificiosInstalacionesEditComponent._this.markersZone = [];
    }
  }

  // Para traducir los textos
  public translate(text: string): string {
    return AppComponent.translate(text);
  }
}
