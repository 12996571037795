import { Directive, HostListener, Input } from '@angular/core';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';

@Directive({
  selector: '[jqxWindowAutoResize]'
})
export class JqxWindowAutoResizeDirective {
  @Input() jqxWindowAutoResize: any;

  constructor(private window: jqxWindowComponent){
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    setTimeout(() => {
      let headerContainer = document.getElementById('header-container').getClientRects()[0];
      let mapHeight = document.getElementById('map-container').offsetHeight;
      let mapWidth = document.getElementById('map-container').offsetWidth;

      if (!this.window.collapsed()) {
        this.window.attrHeight = mapHeight - 24;
        this.window.attrWidth = mapWidth;

        this.window['y'] = headerContainer.height + 24;
        this.window['x'] = 0;

        this.window.collapse();
        this.window.expand();
      }
    }, 10)
  }

  @HostListener('window:click', ['$event.target'])
  onClick(btn) {
    if(btn.className.compareWith('jqx-splitter-collapse-button')) {
      this.onResize();
    }
  }
}
