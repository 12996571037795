<div style="width: 100%; height: 100%;">
  <jqxGrid #grid [theme]="theme" [width]="'99%'" [height]="'90%'" [source]="dataAdapter"
    [localization]="langGrid" [columns]="columns" [showrowlines]="false" [filterable]="true"
    [filter]="filter" [appCellClick] [showcolumnlines]="true" [pageable]="false" [sortable]="true"
    [altrows]="true"[groupable]="true" [enablebrowserselection]="true" [showstatusbar]="true"
    [statusbarheight]="20" [showaggregates]="true"[rowsheight]="20" [columnsheight]="20"
    [editable]="false" [updatefilterconditions]="updatefilterconditions"
    (onRowclick)="onRowclick($event)">
  </jqxGrid>
</div>

<div #formEdificiosEntradasSalidas></div>
