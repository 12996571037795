<div class="loader-container" *ngIf="showLoader">
    <div class="loader"></div>
</div>
<jqxWindow #form style="--actions: L;" [jqxWindowAutoResize] [theme]="environment.tema"
    [position]="getFormPos(form, 24)" [zIndex]="900" [width]="mapWidth" [height]="mapHeight - 24" [autoOpen]="true"
    [showCloseButton]="true" [showCollapseButton]="true" [resizable]="true" (onClose)="onClose()">
    <div class="formHeader" style="overflow-x: hidden;">
        {{translate('Alarmas_geograficas')}}
    </div>
    <div class="form" style="float:left;">
        <div>
            <app-header #header [exportar]="true" [imprimir]="true" [buscador]="true" [filtro]="true"
                [resetFiltro]="true" (eventExportar)="onExportar()" (eventImprimir)="onPrint()"
                (eventBuscador)="onBuscar()" (eventFilter)="eventFilter()" (eventResetFilter)="eventResetFilter()">
            </app-header>
        </div>
        <div style="float:left; margin-top: 6px; width: 100%; height: 100%;">
            <jqxGrid #gridAlarmas [theme]="environment.tema" [width]="'100%'" [height]="'calc(100% - 90px)'"
                [source]="dataAdapter" [columnsautoresize]="true" [columnsresize]="true" [localization]="langGrid"
                [filterable]="true" [columns]="columns" [showrowlines]="false" [showcolumnlines]="true"
                [pageable]="false" [sortable]="true" [altrows]="true" [showstatusbar]="true" [statusbarheight]="20"
                [columnmenuopening]='columnmenuopening' [showaggregates]="true" [rowsheight]="25" [columnsheight]="20"
                [editable]="false" [rowdetails]="true" [rowdetailstemplate]="rowdetailstemplate" [filter]="filter"
                [updatefilterconditions]="updatefilterconditions" [enablebrowserselection]="true"
                [initrowdetails]="initRowDetails" (onRowclick)="onRowSelect($event)" (onSort)="onSort($event)"
                (onRowdoubleclick)="onRowdoubleclick($event)">
            </jqxGrid>

            <jqxButton style="float: left; margin-left: 5px; margin-top: 10px;" [width]="100" [height]="26"
                [textImageRelation]="'imageBeforeText'" [textPosition]="'left'" (onClick)="closeWindow()"
                [value]="translate('Salir')">
            </jqxButton>
        </div>
    </div>
</jqxWindow>
<div *ngIf="mostrarEditForm">

    <nz-modal [(nzVisible)]="isVisible" [nzWidth]="380" [nzAutofocus]="'ok'" [nzZIndex]="1000" nzTitle="Info"
        (nzOnCancel)="handleCancel()" (nzOnOk)="handleOk()">
        <ng-container *nzModalContent>
            <p>example&#64;example.com;example2&#64;example.com</p>
        </ng-container>
    </nz-modal>

    <jqxWindow #formEdit [position]="getFormPos(formEdit)" [width]="453" [height]="450" [autoOpen]="true"
        [isModal]="false" [showCloseButton]="true" [showCollapseButton]="true" [resizable]="true"
        (onClose)="onCloseEdit()" (onOpen)="onOpenEdit()" (onExpand)="onExpandEdit($event)">
        <div class="formHeader" style="overflow-x: hidden;">
            {{translate('Edicion_alarmas')}}
        </div>
        <div class="form" style="float:left;overflow: auto">
            <div style="display: flex;flex-direction: column;justify-content: space-between;height: 100%;">
                <div>
                    {{translate('Zona_cartografica')}}
                    <div style="display: flex; align-items: flex-end; justify-content: space-between; gap: 2px;">
                        <div style="width: calc(95% - 50px);">
                            <jqxDropDownList #cbZonas [width]="'100%'" [height]="25" [selectedIndex]="0"
                                (onSelect)="onChangeZona($event)" [searchMode]="'containsignorecase'"
                                [filterable]="true" [filterPlaceHolder]="'Buscar...'">
                            </jqxDropDownList>
                        </div>
                        <div style="display: flex; gap: 1px;">
                            <jqxButton class="button" style="float:left;  cursor: pointer;" [width]='25' [height]='25'
                                [title]="translate('Ver_zona')" (onClick)="onVerZona($event)" [disabled]="sinZona">
                                <i class="fa-solid fa-eye"></i>
                            </jqxButton>

                            <jqxButton class="button" style="float:left; cursor: pointer;" [width]='25' [height]='25'
                                [title]="translate('Añadir_zona')" (onClick)="onChangeAreasZonas($event)">
                                <i class="fa-solid fa-plus"></i>
                            </jqxButton>
                        </div>
                    </div>
                    <div style="display: flex;flex-direction: column;margin-bottom: 5px;margin-top: 5px ">
                        <div>
                            {{translate('Nombre')}}
                        </div>
                        <div style="width: calc(95% - 50px);">
                            <jqxInput #ebNombre [(ngModel)]="alarmaEdit.Nombre" [width]="'100%'" [height]="25"
                                [disabled]="!canEdit">
                            </jqxInput>
                        </div>
                    </div>
                    <div>
                        <div
                            style="display: flex; align-items: flex-end; justify-content: space-between;flex-wrap: wrap;">
                            <div>
                                <input width="" [(ngModel)]="alarmaEdit.Entrada" type="checkbox"
                                    (change)="onChangeCtrlEntrada($event)" [disabled]="!canEdit" />
                                {{translate('Controlar_entrada')}}
                            </div>
                            <div style="display: flex; gap: 2px; align-items: center;">
                                {{translate('Hora_maxima')}}
                                <jqxDateTimeInput #horaEntrada [(ngModel)]="alarmaEdit.HoraEntrada" [width]="95"
                                    [height]="22" [dropDownVerticalAlignment]="'top'" [formatString]="'HH:mm:ss'"
                                    [showTimeButton]="true" [showCalendarButton]="false"
                                    [disabled]="!alarmaEdit.Entrada || !canEdit">
                                </jqxDateTimeInput>
                            </div>
                        </div>
                        <hr style="clear:both">
                        <div
                            style="display: flex; align-items: flex-end; justify-content: space-between;flex-wrap: wrap;">
                            <div>
                                <input [(ngModel)]="alarmaEdit.Salida" type="checkbox"
                                    (change)="onChangeCtrlSalida($event)" [disabled]="!canEdit" />
                                {{translate('Controlar_salida')}}
                            </div>
                            <div style="display: flex; gap: 2px; align-items: center;">
                                <div>
                                    {{translate('Hora_maxima')}}
                                </div>
                                <jqxDateTimeInput #horaSalida [(ngModel)]="alarmaEdit.HoraSalida" [width]="95"
                                    [height]="22" [dropDownVerticalAlignment]="'top'" [formatString]="'HH:mm:ss'"
                                    [showTimeButton]="true" [showCalendarButton]="false"
                                    [disabled]="!alarmaEdit.Salida || !canEdit">
                                </jqxDateTimeInput>
                            </div>
                        </div>
                    </div>
                    <div style="display: flex;gap: 5px;margin-top: 5px;">
                        <div style="margin-top: 14px;">
                            <input [(ngModel)]="alarmaEdit.Repetir" type="checkbox" (change)="onChangeRepetir($event)"
                                [disabled]="!canEdit" /> {{translate('Repetir')}}
                        </div>
                        <div style="display: flex;flex-direction: column">
                            <div style="float: left;">
                                <div style="float:left; clear: both;">
                                    <div style="float:left; margin-left: 1px;">L</div>
                                    <div style="float:left; margin-left: 7px;">M</div>
                                    <div style="float:left; margin-left: 5px;">X</div>
                                    <div style="float:left; margin-left: 8px;">J</div>
                                    <div style="float:left; margin-left: 7px;">V</div>
                                    <div style="float:left; margin-left: 7px;">S</div>
                                    <div style="float:left; margin-left: 5px;">D</div>
                                </div>
                            </div>
                            <div style="display: flex;flex-direction: row">
                                <input [(ngModel)]="dias.lunes" type="checkbox" style="float:left;"
                                    [disabled]="!alarmaEdit.Repetir || !canEdit" />
                                <input [(ngModel)]="dias.martes" type="checkbox" style="float:left; margin-left: 2px;"
                                    [disabled]="!alarmaEdit.Repetir || !canEdit" />
                                <input [(ngModel)]="dias.miercoles" type="checkbox"
                                    style="float:left; margin-left: 2px;"
                                    [disabled]="!alarmaEdit.Repetir || !canEdit" />
                                <input [(ngModel)]="dias.jueves" type="checkbox" style="float:left; margin-left: 2px;"
                                    [disabled]="!alarmaEdit.Repetir || !canEdit" />
                                <input [(ngModel)]="dias.viernes" type="checkbox" style="float:left; margin-left: 2px;"
                                    [disabled]="!alarmaEdit.Repetir || !canEdit" />
                                <input [(ngModel)]="dias.sabado" type="checkbox" style="float:left; margin-left: 2px;"
                                    [disabled]="!alarmaEdit.Repetir || !canEdit" />
                                <input [(ngModel)]="dias.domingo " type="checkbox" style="float:left; margin-left: 2px;"
                                    [disabled]="!alarmaEdit.Repetir || !canEdit" />
                            </div>
                        </div>
                    </div>
                    <div
                        style="display: flex; justify-content: space-between; margin-top: 8px; align-items: center;flex-wrap: wrap">
                        <!-- Contenedor para el primer div y el botón -->
                        <div style="display: flex; align-items: center; gap: 5px;flex-wrap: wrap">
                            <div>
                                {{translate('Lista_emails')}}
                            </div>

                            <jqxButton [value]="translate('Ver_ejemplo')" [template]="'info'" (onClick)="verEjemplo()">
                            </jqxButton>
                        </div>

                        <!-- jqxDropDownList separado -->
                        <jqxDropDownList #sUsuarios style="float: left;" (onCheckChange)="onChangeUser($event)"
                            [width]="'220px'" [height]="'24px'" [displayMember]="'Nombre'" [valueMember]="'Id'"
                            [selectionRenderer]="selectionUserRenderer" [source]="usuarioAdapter" [filterable]="true"
                            [searchMode]="'containsignorecase'" [filterPlaceHolder]="'Buscar...'" [checkboxes]="true">
                        </jqxDropDownList>
                    </div>
                    <div style="width: calc(100% - 5px);">
                        <jqxTextArea #emails [(ngModel)]="alarmaEdit.Emails" [width]="'100%'" [height]="65"
                            [disabled]="!canEdit">
                        </jqxTextArea>
                    </div>
                    <div style="margin-top: 5px; float:left; clear:both; ">
                        {{translate('Observaciones')}}
                    </div>
                    <div style="width: calc(100% - 5px);">
                        <jqxInput [(ngModel)]="alarmaEdit.Observaciones" [width]="'100%'" [height]="50"
                            [disabled]="!canEdit">
                        </jqxInput>
                    </div>
                </div>

                <div style="margin-top: 5px; clear: both; display: flex; gap: 5px;">
                    <jqxButton style="float: left;" [width]='100' [height]='26' [textImageRelation]='"imageBeforeText"'
                        [textPosition]='"left"' [imgSrc]='"/assets/images/save.png"' (onClick)="onGuardarAlarma($event)"
                        [value]="translate('Guardar')" [disabled]="!canEdit">
                    </jqxButton>
                    <jqxButton style="float: left; margin-left: 5px;" [width]="100" [height]="26"
                        [textImageRelation]="'imageBeforeText'" [textPosition]="'left'" (onClick)="formEdit.close()"
                        [value]="translate('CANCELAR')">
                    </jqxButton>
                </div>

            </div>



        </div>
    </jqxWindow>
    <div #menuContainer></div>
</div>

<div *ngIf="mostrarAsigForm">
    <jqxWindow #formAsig style="overflow: hidden;" [theme]="environment.tema" [position]="getFormPos(formAsig, 24)"
        [width]="mapWidth" [height]="mapHeight" [zIndex]="900" [autoOpen]="true" [isModal]="false"
        [showCloseButton]="true" [showCollapseButton]="true" [resizable]="true" (onClose)="onCloseAsig()"
        (onOpen)="onOpenAsig()">
        <div class="formHeader" style="overflow-x: hidden;">
            {{translate('Asignacion_alarmas')}}
        </div>
        <div class="form" style="float:left; overflow: auto;">
            <div style="width: 100%; height: 94%; display: flex;align-items: center; gap: 5px;">
                <div style="float: left; width: 50%; height: 100%;">
                    <div>{{translate('Moviles_disponibles')}}</div>
                    <jqxGrid #gridMoviles1 [theme]="environment.tema" [height]="'calc(100% - 20px)'" [width]="'100%'"
                        [appCellClick] [source]="dataAdapterMoviles1" [columnsresize]="true" [sortable]="true"
                        [showfilterrow]="true" [selectionmode]="'checkbox'" [showrowlines]="false"
                        [showcolumnlines]="true" [enablebrowserselection]="true" [showrowlines]="false"
                        [columns]="columnsMoviles1" [altrows]="true" [filterable]="true" [filtermode]="'excel'"
                        [autoshowfiltericon]="true" [filterrowheight]="30" [rowsheight]="22" [columnsheight]="20"
                        [showaggregates]="true" [showstatusbar]="true" [statusbarheight]="20" [localization]="langGrid"
                        [filter]="filter" [updatefilterconditions]="updatefilterconditions" [disabled]="!canEdit">
                    </jqxGrid>
                </div>
                <div>
                    <jqxButton style="float:left; clear: both;" [width]='25' [height]='26'
                        [textImageRelation]='"imageAfterText"' [textPosition]='"left"' [imgPosition]='"right"'
                        [imgSrc]='"/assets/images/derecha.png"' (onClick)="onMasClick()" [disabled]="!canEdit">
                    </jqxButton>
                    <jqxButton style="float:left; margin-top: 5px; clear: both;" [width]='25' [height]='26'
                        [textImageRelation]='"imageBeforeText"' [textPosition]='"right"' [imgPosition]='"left"'
                        [imgSrc]='"/assets/images/izquierda.png"' (onClick)="onMenosClick()" [disabled]="!canEdit">
                    </jqxButton>
                </div>
                <div style="float: right; width: 50%; height: 100%">
                    <div>{{translate('Moviles_asignados')}}</div>
                    <jqxGrid #gridMoviles2 [theme]="environment.tema" [height]="'calc(100% - 20px)'" [width]="'100%'"
                        [source]="dataAdapterMoviles2" [columnsresize]="true" [sortable]="true" [showfilterrow]="true"
                        [appCellClick] [selectionmode]="'checkbox'" [showrowlines]="false" [showcolumnlines]="true"
                        [enablebrowserselection]="true" [showrowlines]="false" [columns]="columnsMoviles2"
                        [altrows]="true" [filterable]="true" [filtermode]="'excel'" [autoshowfiltericon]="true"
                        [filterrowheight]="30" [rowsheight]="22" [columnsheight]="20" [showaggregates]="true"
                        [showstatusbar]="true" [statusbarheight]="20" [localization]="langGrid" [filter]="filter"
                        [updatefilterconditions]="updatefilterconditions" [disabled]="!canEdit">
                    </jqxGrid>
                </div>
            </div>
            <div style="margin-top: 2px">
                <jqxButton style="float:left;" [width]='100' [height]='26' [textImageRelation]="'imageBeforeText'"
                    [textPosition]="'center'" [imgSrc]='"/assets/images/save.png"' (onClick)="onGuardarAsig()"
                    [value]="translate('Guardar')" [disabled]="!canEdit">
                </jqxButton>

                <jqxButton style="float: left; margin-left: 5px" [width]="100" [height]="26"
                    [textImageRelation]="'imageBeforeText'" [textPosition]="'left'" (onClick)="formAsig.close()"
                    [value]="translate('CANCELAR')">
                </jqxButton>

            </div>
        </div>
    </jqxWindow>
</div>
