<!-- formulario de entrada-salida -->
<jqxWindow #formEntradaSalida [theme]="theme" [zIndex]="900" [width]="680" [height]="240" [showCloseButton]="true"
  [showCollapseButton]="true" [isModal]="false" [resizable]="true" [position]="getFormPos(formEntradaSalida, 0)"
  [title]="translate('Titulo_entr_salidas') + ' ' + edificio.Nombre" (onOpen)="onOpen()" (onClose)="onClose()">
  <div class="formHeader" style="overflow-x: hidden;">
    {{ translate('Entradas_salidas') }}
  </div>
  <div class="form" style="float:left; overflow-x: hidden; ">
    <div class="columnGrid">
      <div>
        <div style="margin-top: 5px; float:left; clear:both;">
          {{ translate('Nombre') }}
        </div>
        <div style="float:left; clear:both;">
          <jqxInput [width]="310" [height]="25" [(ngModel)]="edificioES.Nombre">
          </jqxInput>
        </div>
        <div style="margin-top: 5px; float:left; clear:both;">
          {{ translate('Observaciones') }}
        </div>
        <div style="float:left; clear:both;">
          <jqxInput [width]="310" [height]="25" [(ngModel)]="edificioES.Observaciones">
          </jqxInput>
        </div>
        <div style="margin-top: 5px; float:left; clear:both;">
          {{ translate('Direccion') }}
        </div>
        <div style="float:left; clear: both;">
          <jqxInput [width]="310" [height]="25" [(ngModel)]="edificioES.Direccion" disabled="true">
          </jqxInput>
        </div>
        <div id="checkbox" style="margin-top: 5px; float: left; margin-right: 20%; margin-bottom: 5px;">
          <div style="float:left;">
            {{ translate('Entrada') }}&nbsp;&nbsp;
            <input #CheckBoxEntrada id="checkEntrada" [(ngModel)]="tipoEntrada" type="checkbox"
              style="float: left;" (change)="check()"/>
          </div>
          <div style="float:left;">
            {{ translate('Salida') }}&nbsp;&nbsp;
            <input #CheckBoxSalida [(ngModel)]="tipoSalida" id="checkSalida" type="checkbox"
              style="float:left; margin-left:5px;" (change)="check()"/>
          </div>
        </div>
        <div style="float: left;">
          <jqxButton style="float:left; margin-left: 0px;" [width]='100' [height]='26'
            [textImageRelation]='"imageBeforeText"' [textPosition]='"left"'
            [imgSrc]='"/assets/images/save.png"' [imgPosition]="'left'" [value]="translate('Guardar')"
            (onClick)="onGuardarES($event)">
          </jqxButton>
        </div>
      </div>
      <div>
        <jqxGrid #gridEntrLocal [theme]="theme" [width]="360" [height]="200" [source]="dataAdapter"
          [appCellClick] [columnsresize]="true" [localization]="langGrid" [columns]="columns"
          [filtermode]="'excel'" [enablebrowserselection]="true" [showrowlines]="false"
          [showcolumnlines]="true" [pageable]="false" [sortable]="true" [altrows]="true"
          [showstatusbar]="true" [showaggregates]="true" [editable]="false" [filter]="filter"
          [updatefilterconditions]="updatefilterconditions" (onRowclick)="onRowClickEdit($event)">
        </jqxGrid>
      </div>
    </div>
  </div>
</jqxWindow>
