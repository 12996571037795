import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';

import { JqWidgets } from 'src/app/utils/jqWidgets';
import { AppComponent } from 'src/app/app.component';
import { CustomForms } from '../../../forms/custom-forms';
import { environment } from 'src/environments/environment';
import { MainComponent } from 'src/app/components/main/main.component';

import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';
import { jqxSliderComponent } from 'jqwidgets-ng/jqxslider';

import { SsoService } from 'src/app/services/sso/sso.service';
import { ZonesService } from 'src/app/services/zones/zones.service';
import { RangosService } from 'src/app/services/ciudadanos/tarjetas/rangos.service';
import { TarjetasService } from 'src/app/services/ciudadanos/tarjetas/tarjetas.service';

import { ZonaModel } from 'src/app/services/zones/models/zona.model';
import { RangoModel } from 'src/app/services/ciudadanos/models/rango.model';
import { TarjetaModel } from 'src/app/services/ciudadanos/models/tarjeta.model';

import { TabRangosComponent } from '../tab-rangos.component';
import { TarjetasCiudadanosComponent } from '../../tarjetas-ciudadanos.component';

@Component({
  selector: 'app-form-rango',
  templateUrl: './form-rango.component.html',
  styleUrls: ['./form-rango.component.css']
})
export class FormRangoComponent extends CustomForms implements OnInit, AfterViewInit {
  @ViewChild('formWindow') formWindow: jqxWindowComponent;
  @ViewChild('sliderRango') sliderRango: jqxSliderComponent;

  mapHeight;
  mapWidth;

  showLoader: boolean = false;

  private componentRef = null;
  public environment = environment;
  public static _this: FormRangoComponent;
  public langGrid = JqWidgets.getLocalization('es');

  public rango: RangoModel = new RangoModel();
  public tarjetasSinRango: TarjetaModel[] = [];
  public tarjetas: TarjetaModel[] = [];

  private cargaInicial = true;

  public translate(text: string): string {
    return AppComponent.translate(text);
  }

  constructor(
    private ssoService: SsoService,
    private zonesService: ZonesService,
    private rangosService: RangosService,
    private tarjetasService: TarjetasService
  ) {
    super();
    FormRangoComponent._this = this;
  }

  init(componentRef: any, rango: RangoModel){
    this.componentRef = componentRef;
    this.rango = rango ? rango : new RangoModel();
  }

  ngOnInit(): void {
    this.langGrid = JqWidgets.getLocalization(this.ssoService.getTicket().Usuario.Idioma.Codigo.substring(0, 2));

    this.mapHeight = document.getElementById('map-container').offsetHeight;
    this.mapWidth = document.getElementById('map-container').offsetWidth;

    /*
     En caso de que se este editando el rango
     obtengo todas las tarjetas y
     concateno las que estan sin rango
     con las asignadas al rango que se esta editando
    */
    if(this.rango.id > 0) {
      this.getTarjetas();
      this.getTarjetasSinRango();
    }else {
      this.getTarjetasSinRango();
    }

    this.getZones();
  }

  ngAfterViewInit() {
    this.addCustomForm(this.formWindow);
  }

  onClose() {
    if(this.componentRef) {
      this.componentRef.destroy();
    }
    TarjetasCiudadanosComponent._this.form.expand();
  }

  closeWindow(){
    this.formWindow.close();
  }

  async getTarjetas() {
    this.tarjetasService.getTarjetas().then(
      (result) => {
        this.tarjetas = result.sort((a, b) => (a.idTarjeta < b.idTarjeta ? -1 : 1));

        let startId = this.tarjetas.findIndex(tarj => tarj.idTarjeta === this.rango.idTarjetaInicial);
        let endId = this.tarjetas.findIndex(tarj => tarj.idTarjeta === this.rango.idTarjetaFinal);

        this.tarjetas = this.tarjetas.slice(startId, (endId+1));
      }
    )
  }

  async getTarjetasSinRango() {
    this.tarjetasService.getTarjetasSinRango().then(
      (result) => {
        if(this.tarjetas.length > 0) {
          result = result.concat(this.tarjetas);
        }
        this.tarjetasSinRango = result.sort((a, b) => (a.idTarjeta < b.idTarjeta ? -1 : 1));
        if(this.rango.id === 0) {
          this.rango.idTarjetaInicial = this.tarjetasSinRango[0]?.idTarjeta;
          this.rango.idTarjetaFinal = this.tarjetasSinRango[0]?.idTarjeta;
        }
        this.sliderRango.min(1);
        this.sliderRango.max(this.tarjetasSinRango.length)
        this.cargaInicial = false;
        this.sliderRango.value([1,1]);
      }
    )
  }

  tickLabelFormatFunction: any = (value) => {
    let min = this.sliderRango?.min();
    let max = this.sliderRango?.max();
    if (value == min) {
      return value;
    }
    if (value == max) {
      return value;
    }
    return '';
  };

  async onGuardar(){
    this.showLoader = true;

    this.rangosService.createRango(this.rango).then(
      (result) => {
        if(result) {
          this.rango = result;
          MainComponent.getInstance().showInfo(
            'ATENCION',
            'Registro_almacenado',
            2000
          );

          let selectedZonas = this.zonasToAsign;

          if(selectedZonas.length !== 0) {
            let zonasToAsociate: any[] = [];
            selectedZonas.forEach(zona => {
              zonasToAsociate.push({
                "idZona": zona.Id,
                "enviar": false
              });
            });
            FormRangoComponent._this.rangosService.asociateRangoZona(FormRangoComponent._this.rango.id, zonasToAsociate).then(
              (result) => {
                FormRangoComponent._this.showLoader = false;
                FormRangoComponent._this.formWindow.close();
                TabRangosComponent._this.getRangos();
                FormRangoComponent._this.showLoader = false;
              }, (error) => {
                FormRangoComponent._this.showLoader = false;
              }
            )
          }else{
            FormRangoComponent._this.showLoader = false;
          }
        }else {
          FormRangoComponent._this.showLoader = false;
        }
      }, (error) => {
      }
    )
  }

  changeSliderRango(event: any) {
    if(!this.cargaInicial) {
      this.rango.idTarjetaInicial = this.tarjetasSinRango[(event.args.value.rangeStart - 1)].idTarjeta;
      this.rango.idTarjetaFinal = this.tarjetasSinRango[(event.args.value.rangeEnd - 1)].idTarjeta;
    }
  }

  setLista(event: any, lista: string) {
    if(lista === 'blanca') {
      this.rango.listaBlanca = event.args.checked;
    }

    if(lista === 'master') {
      this.rango.listaMaster = event.args.checked;
    }

    if(lista === 'negra') {
      this.rango.listaNegra = event.args.checked;
    }
  }

  /* LISTADO ZONAS */
  zonas: ZonaModel[] = [];
  zonasToAsign: ZonaModel[] = [];

  @ViewChild('gridZonas') gridZonas: jqxGridComponent;
  @ViewChild('gridZonasToAsign') gridZonasToAsign: jqxGridComponent;

  public columnsZonas = [
    { text: 'Id', columntype: 'textbox', datafield: 'id', hidden: true },
    { text: AppComponent.translate('Nombre'), columntype: 'textbox', filtertype: 'textbox', datafield: 'nombre', width: 140, },
    { text: AppComponent.translate('Tipo'), columntype: 'textbox', datafield: 'tipo', width: 60, },
  ];

  public sourceZonas: any = {
    datatype: 'json',
    datafields: [
      { name: 'id', type: 'number', map: 'Id' },
      { name: 'nombre', type: 'string', map: 'Nombre' },
      { name: 'tipo', type: 'string', map: 'TipoNombre' },
    ],
    localdata: [],
  };
  public adapterZona = new jqx.dataAdapter(this.sourceZonas);

  public sourceZonasToAsign: any = {
    datatype: 'json',
    datafields: [
      { name: 'id', type: 'number', map: 'Id' },
      { name: 'nombre', type: 'string', map: 'Nombre' },
      { name: 'tipo', type: 'string', map: 'TipoNombre' },
    ],
    localdata: [],
  };
  public adapterZonaToAsign = new jqx.dataAdapter(this.sourceZonasToAsign);

  async getZones() {
    await this.zonesService.getZonas().then(
      res => {
        this.zonas = res;
        this.zonas.sort((a, b) => a.Nombre < b.Nombre ? -1 : 1);

        if(this.rango && this.rango.zonas && this.rango.zonas.length !== 0) {
          this.rango.zonas.forEach(zona => {
              zona.Id = zona.id;
              zona.Nombre = zona.nombre;

              this.zonas.splice(this.zonas.findIndex(z => z.Id === zona.Id), 1);
              this.zonasToAsign.push(zona);
            }
          )
        }

        this.sourceZonas.localdata = this.zonas;
        this.gridZonas.updatebounddata('data');

        this.sourceZonasToAsign.localdata = this.zonasToAsign;
        this.gridZonasToAsign.updatebounddata('data');
      }
    );
  }

  associateZonaTarjeta() {
    let id = this.gridZonas.getrowdata(this.gridZonas.getselectedrowindex()).id;
    let zona = this.zonas.find(zona => zona.Id == id);

    this.zonasToAsign.push(zona);
    this.zonas.splice(this.zonas.findIndex(z => z.Id === id), 1);

    this.zonas.sort((a, b) => a.Nombre < b.Nombre ? -1 : 1);

    this.sourceZonas.localdata = this.zonas;
    this.gridZonas.updatebounddata();

    this.zonasToAsign.sort((a, b) => a.Nombre < b.Nombre ? -1 : 1);

    this.sourceZonasToAsign.localdata = this.zonasToAsign;
    this.gridZonasToAsign.updatebounddata();
  }

  disassociateZonaTarjeta() {
    let id = this.gridZonasToAsign.getrowdata(this.gridZonasToAsign.getselectedrowindex()).id;
    let zona = this.zonasToAsign.find(zona => zona.Id == id);

    this.zonas.push(zona);
    this.zonasToAsign.splice(this.zonasToAsign.findIndex(z => z.Id === id), 1);

    this.zonas.sort((a, b) => a.Nombre < b.Nombre ? -1 : 1);

    this.sourceZonas.localdata = this.zonas;
    this.gridZonas.updatebounddata();

    this.zonasToAsign.sort((a, b) => a.Nombre < b.Nombre ? -1 : 1);

    this.sourceZonasToAsign.localdata = this.zonasToAsign;
    this.gridZonasToAsign.updatebounddata();
  }
}
