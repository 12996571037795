<jqxWindow #form [theme]="environment.tema" [width]="210" [zIndex]="0" [showCloseButton]="true" [zIndex]="90"
    [position]="getFormPos(form)" [showCollapseButton]="true" [height]="82" [resizable]="false" (onClose)="onClose()">
    <div class="formHeader" style="overflow-x: hidden; height: 18px; border: 0px; margin: 0px; padding: 4px;">
        {{translate('Medir')}}
    </div>
    <div class="form" style="float: left; overflow: hidden;">
        <div style="float: left;">
            <div style="padding-left: 2px;">{{translate('Total')}}:
            </div>
            <div style="padding-left: 2px;">{{translate('Parcial')}}:
            </div>
        </div>
        <div style="float: right;">
            <div style="float: right;">
                <div style="float: right; padding-right: 8px;">m</div>
                <div style="float: right; padding-right: 4px;">{{format(distanciaTotal,2)}}</div>
            </div><br>
            <div style="float: right;">
                <div style="float: right; padding-right: 8px;">m</div>
                <div style="float: right; padding-right: 4px;">{{format(distanciaParcial,2)}}</div>
            </div>
        </div>
    </div>
</jqxWindow>
