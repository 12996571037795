import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { HeaderModule } from "../header/header.module";

import { InputNumberModule } from "../input-number/input-number.module";
import { LabelOverLenghtModule } from "../label-over-lenght/label-over-lenght.module";

import { ConfiguracionCerradurasComponent } from "./configuracion-cerraduras.component";
import { ConfiguracionCerradurasEditComponent } from "./configuracion-cerraduras-edit/configuracion-cerraduras-edit.component";
import { ConfiguracionCerraduraAsignarComponent } from './configuracion-cerradura-asignar/configuracion-cerradura-asignar.component';
import { SharedModule } from "src/app/shared/shared.module";

@NgModule({
  declarations: [
    ConfiguracionCerradurasComponent,
    ConfiguracionCerradurasEditComponent,
    ConfiguracionCerraduraAsignarComponent,

  ],
  imports: [
    FormsModule,
    HeaderModule,
    CommonModule,
    InputNumberModule,
    LabelOverLenghtModule,
    SharedModule
  ],
  exports: [
    ConfiguracionCerradurasComponent
  ]
})
export class ConfiguracionCerraduraModule { }
