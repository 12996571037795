import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuditoriaModel } from './models/auditoria.model';
import { DateAuditoriaModel } from './models/date.auditoria.model';
import { AccionModel } from './models/accion.model';
import { SsoService } from '../sso/sso.service';
import { environment } from 'src/environments/environment';
import { AplicacionModel } from '../sso/models/aplicacion.model';
import { url } from 'inspector';
import { CustomForms } from 'src/app/components/forms/custom-forms';

@Injectable({
  providedIn: 'root'
})
export class AuditoriaService {

  constructor(private http: HttpClient, private ssoService: SsoService) { }
  // Acciones
  //Añadir Accion
  async addAccion(accion: AccionModel): Promise<any> {
    let response: AccionModel = null;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    try {
      response = await this.http.post<AccionModel>(this.ssoService.getTicket().UrlApi + '/api/auditoria/accion/crear/',
        JSON.stringify(accion), httpOptions).toPromise();
    } catch (e) {
      console.log(e);
    }
    return response;
  }

  //Obtener Acciones
  async getAcciones(): Promise<AccionModel[]> {
    let result: AccionModel[] = null;
    let urlApiSSo = environment.apiSSO;
  
    try {
      // Intenta obtener los datos de la primera API
      result = await this.http.get<any[]>(urlApiSSo + '/sso/auditoria/acciones/np/aplicacion/' + this.ssoService.getTicket().Aplicacion.Id).toPromise();
  
      // Si no hay resultados, intenta con la segunda API
      if (!result || result.length === 0) {
        result = await this.http.get<any[]>(urlApiSSo + '/sso/auditoria/acciones/np/').toPromise();
      }
    } catch (e) {
      console.log(e);
    }
  
    // Procesa el resultado para agregar la propiedad Especifico
    this.procesarAcciones(result);
  
    return result;
  }
  
  private procesarAcciones(result: AccionModel[]): void {
    result.forEach((accion) => {
      if (accion && accion.Aplicaciones && Array.isArray(accion.Aplicaciones)) {
        accion.Especifico = accion.Aplicaciones.length > 0;
      } else {
        accion.Especifico = false;
      }
    });
  }

  //Borrar Accion
  async delAccion(id: number) {
    let result = null;
    try {
      result = await this.http.delete(this.ssoService.getTicket().UrlApi + '/api/auditoria/accion/borrar/' + id).toPromise();
    } catch (e) {
      console.log(e);
    }
    return result;
  }

  // Auditoria
  // Añadir Auditoria
  async addAuditoria(auditoria: AuditoriaModel): Promise<any> {
    let response: AuditoriaModel = null;

    // Relleno el apartado, si lo hay
    auditoria.ApartadoId = CustomForms?.lastOptionMenu?.menuOpcionId;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    try {
      response = await this.http.post<AuditoriaModel>(this.ssoService.getTicket().UrlApi + '/api/auditoria/crear/',
        JSON.stringify(auditoria), httpOptions).toPromise();

      response;
    } catch (e) {
      console.log(e);
    }
    return response;
  }

  //Get Auditoria
  async getAuditoria(date: DateAuditoriaModel): Promise<any> {
    let response: any;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    try {
      response = await this.http.post<any>(this.ssoService.getTicket().UrlApi + '/api/auditoria/from/',
        JSON.stringify(date), httpOptions).toPromise();
    }
    catch (e) {
      console.log(e);
    }
    return response;
  }

  //Get Aplicaciones
  async getApps(): Promise<AplicacionModel[]> {
    let result: AplicacionModel[] = null;
    let urlApiSSo = environment.apiSSO;
    try {
      result = await this.http.get<any[]>(urlApiSSo + '/sso/aplicaciones')
        .toPromise();
    } catch (e) {
      console.log(e);
      window.alert(e.error);
    }
    return result;
  }
}
